
#vacancies-1a {
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
    background-repeat: no-repeat;

    background-size: cover;
    background-position-y: top;
    background-position-x: 0px;
    background-repeat: no-repeat;

    background-image: url('../images/vacancies banner.jpg');

    .webp & {
        //  background-image: url("../images/contact/contact-us-banner.webp");
    }

    @include media-breakpoint-down(bfl) {
        //background-size: 1960px;
        //background-position-y: top;
        //background-position-x: -10px;
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
    }
    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
        height: 56vw;
    }
}


@include media-breakpoint-down(sm) {
    .page-vacancies {
        #vacancies-1a {
            height: 56vw;
        }

        .image-row {
            height: 56vw;

        }
    }
}


.job-list {
    //background-color: lightgoldenrodyellow;
    width: 100%;
    max-width: 1400px;
    max-width: 1200px;

    margin: 2em auto;
    margin-bottom: 0;
    padding: 1em;
    padding-top: 0;

    //column-count: 2;
    gap: 70px;
    column-count: 2;
    //column-width: 400px;
    //columns : 2 400px;
    //column-rule-width: 5px;
    //column-rule-style: dotted;

    font-size: 28pt;

    @include media-breakpoint-down(xl) {
        font-size: 25pt;
    }
    @include media-breakpoint-down(lg) {
        font-size: 25pt;
        column-count: 1;
    }

    @include media-breakpoint-down(sm) {
        font-size: 16pt;
        column-count: 1;
    }


}

.job-entry {
    @extend .fo-questal;
    line-height: 1.2;
    color: $green;
    cursor: pointer;

    break-inside: avoid;
    page-break-inside: avoid;

    .job-inner {
        padding: 16px;
        //margin-bottom:10px;
    }

}

.apply-info {

}

.apply-title {
    @extend .fo-questal;
    @extend .title;
    color: $green;
}

.apply-desc {
    margin-top: 20px;
    @extend .fo-sans;

    .list-intro {
        display: inline-block;
        margin-bottom: 0.25em;
    }

    ul {
        @extend .green-bulleted;

        li {
            line-height: 1.1;
            margin-top: 9px;
        }
    }
}

#apply-submit {
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    @include media-breakpoint-down(md) {
        margin-right: 10px;
    }
}
