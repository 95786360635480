.go-form-container {
    @extend .col;
    @extend .col-md-15;
    @extend .col-lg-13;
    @extend .col-xxl-11;
}

.go-form {

    .mail-link {
        margin-bottom: 7px;
    }

    .form-panel {
        .text {
            width: 100%;
        }

        form {
            width: 100%;
        }

        button[type="submit"] {
            .circled-text {
                height: 200px;
                width: 200px;


                svg {
                    height: 200px;
                    width: 200px;
                }

                .circled-text-content {
                    width: 200px !important;
                    height: 200px !important;
                }
            }

            .circled-text svg circle {
                stroke: $lime-green;
                stroke-width: 2px;
            }
        }

    }

    label {
        font-size: fs(22pt);
        margin-bottom: 2px;
        margin-left: 10px;
        margin-top: 5px;
    }

    .goform-field-group {
        margin-top: 15px;
        margin-bottom: 25px;
    }

    .goform-field-group + .goform-field-group {
        margin-top: 18px;
    }

    input,
    textarea {
        font-family: "Source Sans Pro", sans-serif;
        width: 100%;
        border-radius: 0;
        font-size: 22pt;
        padding: 10px;
        color: $navy;
    }
}

.form-field-hint {
    @extend .visually-hidden;
}

