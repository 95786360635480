
.page-privacy {

    .privacy-row {
        max-width: 1024px;
        margin: 0 auto;
        margin-top: 60px;
    }

    circle.std-circle {
        stroke: #94d70a !important;
    }

    ul.blue-bulleted li:before {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='22' height='22'><circle fill='transparent' stroke='green' stroke-width='3'  cx='11' cy='11' r='8' /></svg>");
    }

    #privacy-text {
        flex: 0 0 900px;
        @include media-breakpoint-down(bfl) {
            flex-basis: 800px;
        }
        @include media-breakpoint-down(xxl) {
            flex-basis: 660px;
        }
        @include media-breakpoint-down(xl) {
            flex-basis: 570px;
        }
        @include media-breakpoint-down(lg) {
            flex: 1 1 0;

        }
    }


}
