
.circled-text {
    svg {
        circle {
            stroke: $green;
            fill: transparent;
        }
    }

    .circled-text-content {
        @extend .flex-cc;
    }
}

circle.std-circ {
    stroke: #0e9a46;
    fill: transparent;
    stroke-width: 4px;

}

.circ-block-panel {
    //    flex: 0 0 665px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    //background-color: $navy;
    padding: 40px 10px;
}


.circ-block-wrapper {

    // padding:40px;


    .circ-block {
        position: relative;
        top: 0;
        left: 0;
        width: 524.1px;
        height: 524px;

        // @include media-breakpoint-down(xl) {
        ////     font-size: 24pt;
        //     width: 344px;
        //     height: 344px;
        // }
        // @include media-breakpoint-down(md) {
        //  //   font-size: 24pt;
        //     width: 344px;
        //     height: 344px;
        // }
        // @include media-breakpoint-down(sm) {
        //   // font-size: 24pt;
        //     width: 344px;
        //     height: 344px;
        // }

        display: flex;
        justify-content: center;
        align-items: center;


        div.circ-svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            svg {
                height: 100%;
                width: 100%;
            }
        }


    }


}

.circ-text {
    font-family: 'Questrial', sans-serif;
    font-weight: normal;
    font-size: fs(50pt);
    line-height: lh(55pt);
    line-height: 1.2em;
    letter-spacing: ls(15);
    color: $lime-green;
    //padding-left: 0.65em;
}


//@include media-breakpoint-down(xl) {
//    .circ-block-panel {
//
//
//    }
//    .circ-block-wrapper {
//        .circ-block {
//        }
//    }
//}
//@include media-breakpoint-down(md) {
//    .circ-block-panel {
//
//    }
//    .circ-block-wrapper {
//        .circ-block {
//        }
//    }
//}
//@include media-breakpoint-down(sm) {
//    .circ-block-panel {
//        padding:15px 8px;
//
//    }
//    .circ-block-wrapper {
//        .circ-block {
//            //width:80%;
//            //width:80%;
//        }
//    }
//}

@include media-breakpoint-down(md) {

    .circ-block-panel {
        padding: 25px 8px;
    }
    .circ-block-wrapper {
    }
    .circ-block {
        width: 380px;
        height: 380px;

    }
    .circ-text {
        font-size: 26pt;
        //@include media-breakpoint-down(md) {
        //
        //    font-size: 24pt;
        //
        //}
    }
}


svg.animated {
    circle {

        stroke-dasharray: 3.141 * 258 * 2;
        stroke-dashoffset: 3.141 * 258 * 2;
        //stroke-dashoffset: 0; //3.141 * 258 * 2;
        transform: rotateZ(270deg);
        transform-origin: center center;

        transition-property: stroke-dashoffset;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: ease-in-out;

    }

    .has-duration & {
        circle {
            transition-duration: 2s;
        }
    }

    .open & {
        circle {
            transition-duration: 2s;
            stroke-dashoffset: 0;
        }
    }
}


//.col-6,
//.col-md-6,
//.col-17 {

@include media-breakpoint-down(bfl) {

    .circ-block-wrapper {
        .circ-block {
            width: 400px;
            height: 400px;
        }

        .circ-text {
            font-size: 28pt;
        }
    }
}

@include media-breakpoint-down(xxl) {

    .circ-block-wrapper {
        .circ-block {
            width: 340px;
            height: 340px;
        }

        .circ-text {
            font-size: 23pt;
        }
    }
}

@include media-breakpoint-down(xl) {
    .circ-block-wrapper {
        .circ-block {
            width: 290px;
            height: 290px;
        }

        .circ-text {
            font-size: 21pt;
        }
    }
}

@include media-breakpoint-down(lg) {
    .circ-block-wrapper {
        .circ-block {
            width: 220px;
            height: 220px;
        }

        .circ-text {
            font-size: 16pt;
        }
    }

}

@include media-breakpoint-only(md) {
    .circ-md-wide .circ-block-wrapper {
        .circ-block {
            width: 460px;
            height: 460px;
        }

        .circ-text {
            font-size: 34pt;
        }
    }
}


//@include media-breakpoint-down(md) {
//    .circ-block-wrapper {
//        .circ-block {
//            width: 220px;
//            height: 220px;
//            width: 310px;
//            height: 310px;
//        }
//        .circ-text {
//            font-size: 21pt;
//        }
//    }
//}
@include media-breakpoint-down(md) {
    .circ-block-wrapper {
        .circ-block {
            width: 460px;
            height: 460px;
        }

        .circ-text {
            font-size: 34pt;
        }

    }
}

@include media-breakpoint-down(sm) {
    .circ-block-wrapper {
        .circ-block {
            width: 85vw;
            height: 85vw;
        }

        .circ-text {
            font-size: 8vw;
        }
    }
}

//}

@include media-breakpoint-down(sm) {

    //.circ-block-panel {
    //    padding: 15px 8px;
    //}
    //.circ-block-wrapper {
    //}
    //.circ-block {
    //    width: 290px;
    //    height: 290px;
    //}
    //.circ-text {
    //    font-size: 21pt;
    //}

}


.col-17s {
    @include media-breakpoint-down(sm) {
        .circ-block-wrapper {
            .circ-block {
                width: 524px;
                height: 524px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .circ-block-wrapper {
            .circ-block {
                width: 220px;
                height: 220px;
                width: 310px;
                height: 310px;
            }

            .circ-text {
                font-size: 21pt;
            }
        }
    }
}

