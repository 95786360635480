$page-margin-bottom: 100px;

$foot-collapse-bp: sm;

$footer-top-padding: 67px;
$footer-top-padding-col1: $footer-top-padding+0;
// $footer-top-padding-col2: $footer-top-padding+36px;
$footer-top-padding-col2: $footer-top-padding + 13px;
$footer-top-padding-col3: 67px;

footer {
    //    height: 625px + $page-margin-bottom;
    display: flex;

    padding-top: 0;

    flex-direction: row;

    @include media-breakpoint-down($foot-collapse-bp) {
        flex-wrap: wrap;
    }
    //  align-items: stretch;
    //  justify-content: flex-start;
    //opacity: 0.4;
    //  background-color: white;


}

.footer-1-indented {
    margin-left: 15px;
}


.social-links {
    //    margin-top:1.2em;
    //margin-top:30px;
    height: 76px;
    //    height: 112px;
    height: 36px;

    a {
        display: block;
        color: $black;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 300;
        font-size: fs(20pt);
        line-height: lh(30pt);
    }

    margin-bottom: 1.2em;
    margin-top: 1.6rem;

}

.social-link-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        //width: 36px;
        //flex: 0 0 36px;
        width: 40px;
        flex: 0 0 40px;
        margin-right: 5px;
    }

    span {
        //margin-left:5px;
    }

    margin-left: 1em;
    //    margin-bottom:1em;

    &:first-child {
        margin-left: 0em;
        //        margin-bottom: 0em;
    }

    @include media-breakpoint-down(md) {
        margin-left: 2em;
    }

}


#footer-1 {
    flex: 0 1 666px;
    flex: 0 1 636px;
    display: flex;
    flex-direction: row;

    padding-top: $footer-top-padding-col1;


}

$footer-xs-padding: 15px;

.footer-col-1 {

    flex: 0 0 319px;
    flex: 0 1 636px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .footer-col-1-start {
        flex: 0 0 67px;
        display: none;
    }

    .footer-col-1-end {
        flex: 0 0 1em;
    }

    .footer-col-1-center {
        flex: 0 0 55px;
        flex: 0 0 0px;
        //@include media-breakpoint-down($foot-collapse-bp) {
        //    flex: 0 0 55px;
        //}
    }

    .go-logo {
        //update
        //margin-left: 8px;
    }

    .social-links {
        //        margin-left: 12px;
    }


}

#accreditation-logos {
    margin-bottom: 1.2em;

}


#footer-group {
    flex: 1 1 auto;

    display: flex;
    flex-direction: row;

}

#footer-2 {

    padding-top: $footer-top-padding-col2;
    //@include media-breakpoint-down(bfl) {
    //    padding-top: $footer-top-padding-col;
    //}


    padding-bottom: $footer-top-padding-col2;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 50px;

    flex: 0 0 585px;
    flex: 1 1 549px;
    //flex: 1 1 680px;

    //background-color: lightgrey;
    display: flex;
    flex-direction: column;


    font-family: 'Source Sans Pro', sans-serif;

    .footer-2-start {
        flex: 0 0 103px;
    }

    .footer-2-intro {
        max-width: 420px;
        font-weight: 600;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: fs(20pt);
        line-height: lh(25pt);
        margin-bottom: 16px;
        color: $black;
    }

    p {
        //        font-weight: 600;
        font-size: fs(20pt);
        //        line-height: lh(25pt);
    }

    .office {
        margin-top: 1.95em;

        font-weight: 400;
        font-size: fs(20pt);
        line-height: lh(22pt);

        div {
            color: $greenOnWhite;
        }

        .office-name {
            color: $navy;
            margin-bottom: 0.3em;

        }

    }


}


#footer-3 {
    flex: 1 0 auto;

    flex: 1 1 570px;

    position: relative;

    background-color: $green;
    padding-left: 87px;
    padding-top: 102px;

    overflow: hidden;

    .footer-menu {

        position: relative;
        z-index: 2;

        .footer-menu-item {

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    color: $lime-green;
                }
            }

            font-weight: 600;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: fs(20pt);
            line-height: lh(25pt);
            color: white;
            cursor: pointer;

            margin-bottom: 2.1em;

            &:hover {
                color: $lime-green;
            }


        }
    }

    #footer-svg-wrapper {
        position: relative;
        height: 0;
        width: 0;

        svg {
            width: 600px;
            height: 600px;

            position: relative;;
            //            left: 189px;
            top: 18px;

            circle {
                r: 230px;
                stroke-width: 3px;
            }

        }
    }
}

.footer-fill-start {
    flex: 1 1 250px;
    flex: 1 1 220px;
}

.footer-fill-end {
    flex: 1 1 96px;
}

.footer-fill-a {
    flex: 0 0 0px;
    display: none;
}

.footer-fill-b {
    flex: 0 0 0px;
    display: none;
}

.footer-fill-c {
    flex: 0 0 0px;
    display: none;
}

#we-acknowledge-panel {


    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5em; // 0px;
    max-width: 340px;

    @include media-breakpoint-down(md) {
        max-width: 100%
    }

    img {
        //width: 140px;
        width: 100%;
    }

    div {
        //padding-left: 10px;
        padding-bottom: 10px;
        font-size: 13pt;
        line-height: 1.15;
        font-weight: 400;
        @extend .fo-sans;

        @include media-breakpoint-down(sm) {
            //            padding:10px;
        }
    }
}

@include media-breakpoint-down(bfl) {
}

@include media-breakpoint-up(xxl) {
    #footer-3 #footer-svg-wrapper svg {
        left: 189px;
    }
    #footer-3 {
        //margin-top: 1.5em;
    }

}


@include media-breakpoint-down(xxl) {
    footer {
        flex-wrap: wrap;
    }
    #footer-1 {
        flex: 0 1 50%;
    }
    #footer-2 {
        flex: 0 1 50%;
        padding-top: $footer-top-padding;
    }
    .footer-col-1 {
        flex: 0 0 50%;
    }
    .footer-menu {
        display: flex;
        flex-wrap: wrap;
        width: 700px;
        max-width: 100%;
    }
    .footer-menu-item {
        flex: 0 0 33%;
    }
    #footer-3 {

    }
    #footer-3 {
        padding-top: 50px;

        .footer-menu {
            .footer-menu-item {
                margin-bottom: 30px;
            }

            margin-bottom: 1em;
        }

        #footer-svg-wrapper {

            z-index: 0;

            width: 700px;

            svg {
                position: absolute;
                right: -220px;
                top: -30px;
                width: 300px;
                height: 300px;
            }
        }
    }


}


@include media-breakpoint-down(xl) {
    #footer-2 {
        padding-top: $footer-top-padding;
    }
}

@include media-breakpoint-down(lg) {
    #footer-2 {
        padding-top: $footer-top-padding;
        padding-right: 30px;
    }
}


@include media-breakpoint-down(lg) {

    #footer-3 {
        margin-top: 1em;
    }
    .footer-fill-start {
        flex: 0 0 30px;
    }
}



@include media-breakpoint-down(md) {
    footer {
        flex-wrap: wrap;
    }
    #footer-1 {
        flex: 0 1 100%;
    }
    .footer-fill-start {
        flex: 0 0 30px;
    }
    .footer-col-1 {
        .footer-col-1-center {
            // update
            flex: 0 0 30px;
        }
    }
    #footer-2 {
        flex: 0 1 100%;
        padding-top: 2em;
        padding-left: 0;
        margin-top: 1.5em;
    }
    #footer-3 {
        padding-left: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 1.5em;

        .footer-menu-item {
            flex: 0 0 100%;
        }
    }
    .social-links {
        //margin-right: 30px;
        //        margin-bottom: 20px;
        margin-top: 2em;
        height: auto;
    }
}


@include media-breakpoint-down(md) {
    #footer-1 {
        .footer-fill-start {
            flex: 0 1 20px;
            flex: 0 1 0px;
            display: none;
        }

        .footer-col-1 {
            flex: 1 1 auto
        }

        .footer-fill-end {
            display: none;
        }
    }
    #footer-2 {
        padding-top: 0;
    }
    #accreditation-logos {
        //margin-bottom:1em;
    }


}

@include media-breakpoint-down($foot-collapse-bp) {
    #footer-1 {
        .footer-fill-start {
            flex: 0 1 20px;
        }

        .footer-col-1 {
            flex: 1 1 auto;
            padding: 0 $footer-xs-padding;
        }

        #footer-2 {
            //padding :0 $footer-xs-padding;
        }

        .footer-fill-end {
            display: none;
        }
    }

    #accreditation-logos {
        width: 400px;
        max-width: 80%;
        height: auto;
    }

    #footer-2 {

        padding: 24px;
        padding-top: 0;

        .footer-2-start {
            flex: 0 0 20px;
        }

        .footer-2-intro {
        }

        p {
        }

        .office {
            margin-top: 1em;

            div {
            }

            .office-name {
            }

        }


    }

}


#we-acknowledge-panel2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 140px;
    }

    div {
        padding-left: 10px;
        font-size: 11pt;
        line-height: 1.1;
        @extend .fo-sans;
    }
}

#alt-social-etc {

}


.by-the-set-up {
    color: rgba(0, 0, 0, 0.65);
    @extend .fo-sans;
    font-size: 14.9pt;

}

.the-setup {
    &:hover {
        color: whitesmoke;
    }

}

#by-the-set-up-2 {
    display: none;
}

#by-the-set-up-1 {
    position: absolute;
    bottom: 96.5px;
    bottom: 93.5px;
}

.privacy-policy,
.style-guide {
    display: block;
    margin-bottom: 1em;
}


@include media-breakpoint-down(xxl) {

    #by-the-set-up-2 {
        display: inline-block;
        font-size: 13pt;
    }
    #by-the-set-up-1 {
        display: none;
    }

    #by-the-set-up {
        bottom: 10px;
        left: 551px;
        font-size: 12pt;

    }
    #footer-3 .footer-menu {
        margin-bottom: 1.5em;
    }
}

@include media-breakpoint-down(xxl) {
    #by-the-set-up {
        bottom: 10px;
        left: 501px;
        font-size: 12pt;

    }
    #footer-3 .footer-menu {

    }
}

@include media-breakpoint-down(lg) {
    #footer-3 .footer-menu {
        margin-bottom: 0.5em;
    }
}


#certifications-section,
#accreditations-section {
    p, ul {
        color: $navy;
        font-weight: 400;
        font-size: 13pt;

    }

    p {
        margin-bottom: 0.8em;
    }

    h2 {
        font-family: "Questrial", sans-serif;
        color: $green;
        margin-bottom: 0.01em;
        margin-bottom: 0.25em;
        font-weight: 600;
        font-size: 1.5rem;
        font-size: 1.8rem;
    }
}

#accreditations-section {
    margin-top: 2em;
    //h2 {
    //    font-size:1.6rem;
    //}
    //p,ul {
    //    font-size: 16pt;
    //}
    //p {
    //    margin-bottom:1em;
    //}
}


#we-acknowledge-panel {
    //justify-content: space-between;
    img {
        margin-top: 1.5em;
    }

    @include media-breakpoint-up(2 xl) {
        //height:100%;
        justify-content: space-between;
        img {
            align-self: flex-end;
        }
    }

}


.country-name {
    color: #c6692d !important;
}

#footer-2 .country-name {
    font-size: 1.01em;
}

#footer-2 .office {
    margin-top: 1.35em;
    margin-bottom: 1.35em;
}


@media (max-width: 1849.98px) {
    /*#footer-2 .office {*/
    /*    margin-top: 0.8em;*/
    /*    margin-bottom: 0.8em;*/
    /*}*/

    /*.social-links {*/
    /*    margin-top: 11px;*/
    /*}*/

    .staff-login-wrapper {
        margin-top: 0.8em !important;
    }
}

/*@media (max-width: 1849.98px) and (min-width: 1400px) {*/
/*    #footer-2 {*/
/*        padding-top: 82px;*/
/*    }*/
/*}*/

@media (max-width: 767.98px) {
    .social-links {
        //margin-top: 18px;
    }
}
