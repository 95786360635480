// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'mystrap';

button:focus {
    outline: 0;
    box-shadow: none;
}

.col-50 {
    flex: 0 0 auto;
    width: 50%;
}

.col-lg-50 {
    @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        width: 50%;
    }
}

@import 'layout';

@import 'debug';
@import 'include/fonts';
@import 'include/header';
@import 'include/footer';

@import 'form';
@import 'video';
@import 'stats';
@import 'projects';
// @import 'environment';
@import 'employment';



html,
body {
    height: auto;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

a.click-here:hover {
    color: inherit;

    &:hover {
        text-decoration: underline;
    }
}

a.mail-link {
    text-decoration: none;
    color: white;
    font-weight: normal !important;

    &:hover {
        text-decoration: underline !important
    }
}


.btn-primary {
    color: #fff;
    background-color: $green;
    border-color: $green;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}




.flex-cc {
    display: flex;
    justify-content: center;
    align-items: center;
}


body {
    @extend .fo-sans;
    @extend .fw-300;

    font-size: 18.625pt;
    line-height: 1.22;

    @include media-breakpoint-down(bfl) {
        font-size: 16pt;
    }
    @include media-breakpoint-only(lg) {
        font-size: 14pt;
    }
    @include media-breakpoint-only(md) {
        font-size: 12pt;
        font-size: 13pt;
    }
    @include media-breakpoint-down(md) {
        font-size: 14pt;
    }

}

p {
    //    background-color:lightgoldenrodyellow;
    //    color:black
}

.a-wrapper {
    position: relative;
    height: 0;
    width: 0;
    top: 0;
    left: 0;

    //.a-shifter {
    //    position:relative;
    //    top:-270px;
    //    left:-20px;
    //    width:10px;
    //    height:10px;
    //    background-color: green;
    //}

    a {
        //        position: relative;
        position: absolute;
        top: -100px;
        left: 5px;
        //      top: 0;

        width: 0px;
        height: 0px;
        //        left:0px;
        //        background-color: red;
    }

    //div {
    //    position: relative;
    //    position: absolute;
    //    top: -15px;
    //    top: -25px;
    //    left: 0;
    //    top: 0;
    //
    //    width:5px;
    //    height:5px;
    //    left:-20px;
    //    background-color: red;
    //}

    &.a-0 {
        a {
            top: 0px;
        }
    }

    &.a-2 {
        a {
            top: -60px;
        }
    }
}

.navy {
    color: $navy !important;
}

.lime-green {
    color: $lime-green !important;
}

.green {
    color: $green !important;
}

.bg-green {
    background-color: $green !important;
}

.bg-navy {
    background-color: $navy !important;
}

.white {
    color: white !important;
}


.title {
    @extend .fo-questal;
    font-weight: normal;
    color: white;
    margin-bottom: 0.56em;
}

.size-1 {
    //    font-size: fs(50pt);
    font-size: fs(50pt);
    line-height: 1.08em;
    line-height: 1.08;

    @include media-breakpoint-down(xl) {
        font-size: 35pt;
    }
    @include media-breakpoint-down(sm) {
        font-size: 25pt !important;
    }

}


.size-2 {
    font-size: fs(47pt);
    line-height: lh(48pt);
    line-height: 1.02134; //lh(48pt);
}

.size-3 {
    font-size: fs(40pt);
    line-height: lh(44pt);
    line-height: 1.0998;
}

.size-4 {
    font-size: 26pt;
    line-height: 1.08em;
    line-height: 1.14em;
    line-height: 1.14;

}

.big-panel-text {
    $bptfs: 29.8pt;

    font-size: 29.8pt;
    line-height: 1.25;

    @include media-breakpoint-down(bfl) {
        font-size: $bptfs * $scale-2xl;
        font-size: 28pt;
    }
    @include media-breakpoint-down(xxl) {
        font-size: $bptfs * $scale-xl;
        font-size: 25pt;
    }
    @include media-breakpoint-down(xl) {
        font-size: 22pt; // $bptfs * $scale-lg;
    }
    @include media-breakpoint-down(lg) {
        font-size: 20pt;
    }
    @include media-breakpoint-down(md) {
        font-size: 20pt;
    }
    @include media-breakpoint-down(sm) {
        font-size: 18pt;
    }
}

@include media-breakpoint-down(xl) {

    .size-1 {
        font-size: 35pt;
        //        line-height: 1.08;
    }
    .size-2 {
        font-size: fs(47pt);
        //        line-height: lh(48pt);
        //        line-height: lh(48pt);
    }

    .size-3 {
        font-size: fs(40pt);
        //        line-height: lh(44pt);
        //        line-height: 1.02127;
    }

    .size-4 {
        font-size: 18.8pt;
    }

}


.heading-3 {
    @extend .fo-questal;
    @extend .size-2;
    font-weight: normal;
    color: white;
}


.gow {
    color: $white;
}

a.read-more {
    display: inline-block;
    color: $lime-green;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    //font-size: fs(25pt);
    //line-height: lh(30pt);
    margin-top: 1em;
}


@import "green_ul";



.img-tba {
    color: darkgray;
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
}

.section-row {


    &.row-heading {
        height: 143px;

        .title {
            margin: 1em 0;
            padding: 0 30px;
            text-align: center

        }
    }

    .section-text {
        // the whole block
        display: flex;
        justify-content: center;
        justify-content: flex-start;
        align-items: center;

        &.align-start {
            align-items: flex-start;
        }

        &.justify-start {
            justify-content: flex-start;
        }

        .text {
            //            padding: 60px 80px;

            p {
                font-size: fs(25pt);
                line-height: lh(30pt);
                line-height: 1.2em;
            }

            a {
                text-decoration: none;
                //                margin-top: 1em;
                display: inline-block;
                //            color: $greenOnWhite;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 600;
                font-size: fs(25pt);
                line-height: 1.2em;
                //                line-height: lh(30pt);
            }
        }
    }
}


.img-blk {
    background-repeat: no-repeat;
    background-size: 100%;
}

.circled-text {
    svg {
        circle {
            stroke: $green;
            fill: transparent;
        }
    }

    .circled-text-content {
        @extend .flex-cc;
    }
}

circle.std-circle {
    stroke: #0e9a46;
    fill: transparent;
    stroke-width: 4px;

}

.s-text {
    font-family: 'Questrial', sans-serif;
    font-weight: normal;
    font-size: calc(50pt / 1.5);
    line-height: calc(55pt / 1.5);
    color: $lime-green;
    margin-bottom: 0.6em
}

.flex-filler {
    flex: 1 0 auto;
}


.circle-block-panel {
    //    flex: 0 0 665px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    //background-color: $navy;
    padding: 50px 10px;
}

.circle-block {
    position: relative;
    top: 0;
    left: 0;
    width: 524px;
    height: 524px;

    display: flex;
    justify-content: center;
    align-items: center;

    div.circle-svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    .circle-text {
        font-family: 'Questrial', sans-serif;
        font-weight: normal;
        font-size: fs(50pt);
        line-height: lh(55pt);
        letter-spacing: ls(15);
        color: $lime-green;
        //padding-left: 0.65em;


    }

}

svg.animated {
    circle {

        stroke-dasharray: 3.141 * 258 * 2;
        stroke-dashoffset: 3.141 * 258 * 2;
        transform: rotateZ(270deg);
        transform-origin: center center;

        transition-property: stroke-dashoffset;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: ease-in-out;

    }

    .has-duration & {
        circle {
            transition-duration: 2s;
        }
    }

    .open & {
        circle {
            transition-duration: 2s;
            stroke-dashoffset: 0;
        }
    }
}


@import "circle";

.section-row.row-heading {
    min-height: 100px;
    background-color: $green;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


@import 'about';
@import 'community';


.project-circle-text {
    //font-size: 59.6pt;
    //line-height: 58.1pt;
    //@extend .fo-questal;
    //font-weight: normal;
    //text-align: center;
}


@mixin make-circle(  $size, $radius, $strokeWidth: 2px) {

    position: relative;
    width: $size;
    height: $size;

    svg {
        width: $size;
        height: $size;

        circle {
            stroke-width: 4px;
            r: $radius - $strokeWidth;
            cx: $radius;
            cy: $radius;
        }
    }
}

//.poopy {
//    @include make-circle( 180px, 90px, 2px);
//}


@import '_welcome.scss';
@import '_design.scss';
@import '_joinus.scss';
@import '_contact.scss';
@import '_vacancies';
@import "privacy";


.circled-text {
    position: relative;
    width: 180px;
    height: 180px;


    svg {
        width: 180px;
        height: 180px;

        width: 100%;
        height: 100%;


        circle {
            stroke-width: 4px;
            r: 88px;
            cx: 90px;
            cy: 90px;
            //vector-effect: non-scaling-stroke;
        }
    }

    &.animated {
        svg {
            circle {
                transform: rotateZ(270deg);
                transform-origin: center center;
                stroke-dashoffset: 180px * 3.14;
                stroke-dasharray: 180px * 3.14;

                transition-property: stroke-dashoffset;
                transition-delay: 0s;
                transition-duration: 0s;
                transition-timing-function: ease-in-out;
            }
        }

        .open &,
        &.open {
            svg {
                circle {
                    stroke-dashoffset: 0;
                    transition-duration: 2s;
                }
            }
        }

    }

    .open & {
        //  background-color: red;

        svg {
            circle {
                stroke-dashoffset: 0;
                transition-duration: 2s;
            }
        }
    }
}


@include media-breakpoint-down(xl) {

    body {
        //        font-size: 14pt;
        //line-height: 1.198;
    }

    .size-1 {
        //  font-size: 23pt;
    }

    .section-row .section-text .text {
        p {
            font-size: 15pt;
        }

        a {
            font-size: 15pt;

        }
    }
    ul.green-bulleted {
        li {
            //    font-size:15pt;
            padding-left: 15px;
        }
    }
}

@include media-breakpoint-down(xl) {

    body {
        //font-size: 14pt;
        //line-height: 1.198em;
    }

    .size-1 {
        //     font-size: 23pt;
    }

    .section-row .section-text .text {
        //       padding: 50px 50px;

        p {
            font-size: 15pt;
        }

        a {
            font-size: 15pt;

        }
    }
    ul.green-bulleted {
        li {
            //   font-size:15pt;
            padding-left: 15px;
        }
    }
}

@include media-breakpoint-down(sm) {


    .size-1 {

    }

    .section-row .section-text .text {
        //     padding: 30px 30px;

        p {
        }

        a {

        }
    }
    ul.green-bulleted {
        li {
        }
    }
}


.circle-button {
    position: relative;
    width: 140px;
}

.circle-button-content {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: $navy;
    z-index: 1;

    @extend .fo-questal;

    div {
        font-height: 18.6667px;
        line-height: 29.8667px;
        line-height: 1.2em;
    }

}

.circle-button-svg {
    position: relative;
    z-index: 0;

    circle {
        stroke: $green;
        fill: transparent;
        vector-effect: non-scaling-stroke;
        stroke-width: 4px;
    }

    text {
        @extend .fo-sans;
    }
}


p.minblue {
    background: lightblue;
}

.min-content {
    width: -moz-min-content; /* Firefox */
    width: -webkit-min-content; /* Chrome */
    width: min-content;

}

.max-content {
    width: intrinsic; /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content; /* Firefox/Gecko */
    width: -webkit-max-content; /* Chrome */
    width: max-content;

}

.justin {
    background-color: green;
}

.foundations-we-sponsor {
    font-size: 26pt;
    height: 143px;
    flex-wrap: wrap;

    .title {
        margin-bottom: 0 !important;
        padding-left: 80px;
    }

    .text-panel {
        padding-right: 0;
    }

    @include media-breakpoint-down(bfl) {
        font-size: 24pt;
        .title {
            padding-left: 57px;
        }
    }
    @include media-breakpoint-down(xxl) {
        font-size: 19pt;
        .title {
            padding-left: 49.83px;
        }
    }
    @include media-breakpoint-down(lg) {
        height: auto;
        .title {
            margin-top: 30px;
            padding-bottom: 30px;
        }
    }
}

.foundation-images-wrapper {
    padding-left: 1em;
    padding-right: 1em;
    @include media-breakpoint-down(sm) {
        justify-content: center;
        display: flex;
        // margin-top: 1em;
    }

}

.foundation-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;

    //padding-bottom: 1.6em;

    @include media-breakpoint-down(lg) {
        flex: 1 1 auto;
        padding-bottom: 1.6em;
    }
    @include media-breakpoint-down(sm) {
        max-width: 60%;
    }

    a {
        flex: 1 0 33%;
        padding: 0 1.61em;
        //padding: 0 0.61em;


        //        padding:0.9em 0.7em;
        @include media-breakpoint-down(bfl) {
            padding: 0 0.615em;
        }
        @include media-breakpoint-down(xxl) {
            padding: 0 0.715em;
        }
        @include media-breakpoint-down(lg) {
            //            padding:0 0.7em 1.2em 0.7em;
        }
        @include media-breakpoint-down(md) {
            //            padding:0 0.35em 1.2em 0.35em;
        }
        @include media-breakpoint-down(sm) {
            flex: 1 0 60%;
            padding: 0.2em 1em;
        }

    }

    img {
        width: 100%;
        height: auto;
    }

    #nsca-logo {
        //padding: 0 0.7em;

    }

    #australian-water-logo {
        //padding:0 0.6em;
    }

}

.md-min-200 {
    @include media-breakpoint-up(md) {
        //min-height:200px;
        flex: 1 0 auto;
    }
}

.md-min-480 {
    @include media-breakpoint-only(md) {
        //        height:300px;
        flex: 0 0 300px;
    }
    @include media-breakpoint-only(lg) {
        //        height:370px;
        flex: 0 0 370px;
        //        flex:0 0 397px;
    }
    @include media-breakpoint-only(xl) {
        //        height:420px;
        flex: 0 0 420px;
        //        flex:0 0 462px;
    }
    @include media-breakpoint-only(xxl) {
        //        height:604px;
        flex: 0 0 480px;
    }
    @include media-breakpoint-up(bfl) {
        //        height:604px;
        flex: 0 0 604px;
    }
}

.xl-520 {
    @include media-breakpoint-only(xl) {
        //        height:420px;
        flex: 0 0 520px;

    }
}

.lg-520 {
    @include media-breakpoint-only(lg) {
        //        height:420px;
        flex: 0 0 430px;

    }
}

//.xxl-min-520 {
//    @include  media-breakpoint-up(xxl) {
//        //        height:420px;
//   //     flex:0 0 840px;
//    }
//
//}
