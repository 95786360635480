$draw-transparentise: 0.2;


@mixin makeGwuText( $mult) {
    @include makeVideoCircles($mult);
    //.video-logo {
    //    width: 524px * ($mult/1830);
    //    height: 524px * ($mult/1830);
    //    left: 70px  * ($mult/1830);
    //    top: 120px  * ($mult/1830);
    //}
    .gwu-text {
        .t1-fade {
            margin-bottom: 12px * ($mult/1830);
            width: 340px * ($mult/1830);

            .t1-word img {
                height: 64px * ($mult/1830);
            }

            .t1-word {
                line-height: 58.11pt * ($mult/1830);
                font-size: 58.11pt * ($mult/1830);
            }
        }

        .t2 {
            line-height: 58.11pt * ($mult/1830);
            font-size: 58.11pt * ($mult/1830);
        }

        .t3 {
            font-size: 29.8pt * ($mult/1830);
            line-height: 1em;
            margin-top: 10px * ($mult/1830);
        }
    }
}

#home-video {
    background-color: $green;
    position: relative;
    //opacity:0.4;

    .gwu-text {
        position: relative;
        left: -3px;
        top: -3px;
        z-index: 101;
        pointer-events: none;
        padding-top: 10px;
        padding-left: 4px;

        spadding-top: 10px;
        padding-left: 11px;

        .gwu-text span.t1 {
            display: block;
        }

        .grown & {


        }

        .t1-fade {
            position: relative;
            text-align: center;
            color: white;
            width: 340px;
            display: flex;
            justify-content: center;
            margin-bottom: 12px;

            //.t1-go-wrapper {
            //  position:relative;;
            //  top:0;left:0;
            //}

            $crossover-delay: 300ms;
            $crossover-duration: 600ms;


            .t1-word {
                @extend .video-h1;
                letter-spacing: 0pt;
                display: inline-block;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                transition-duration: $crossover-duration;
                transition-delay: 0ms;
                transition-property: opacity;
            }

            .t1-go {
                position: relative;
                //                transition-delay: $crossover-delay;

            }

            .t1-grow {
                position: absolute;
                top: 0;
                left: 0;
                @extend .video-h1;
                letter-spacing: 0pt;
                display: inline-block;
                width: 100%;
                text-align: center;
                transition-delay: 0ms;
            }

            .t1-grow {
                opacity: 0;
            }

            .grown & {
                .t1-go {
                    opacity: 0;
                    transition-timing-function: ease-in-out;
                    transition-delay: 0ms;
                }

                .t1-grow {
                    opacity: 1;
                    transition-timing-function: ease-in-out;
                    transition-delay: $crossover-delay;

                    //transition-timing-function: cubic-bezier(0.005, 0.490, 0.020, 1.010);
                    //transition-timing-function: cubic-bezier(0.980, 0.005, 1.000, 0.470);

                }


            }

        }

        .t1 {
            color: white;
            text-align: center;
            display: flex;
            justify-content: center;
            padding-left: 1px;


            div {
                letter-spacing: 0pt;
                margin: 0pt;
            }


            .t1-perm {
                @extend .video-h1;
                letter-spacing: 0pt;
                display: inline-block;
                //  background-color: rgba(255, 224, 235, 0.32);

                &.t1-g {
                }

                &.t1-o {
                    //margin-left:10px;
                }
            }

            .t1-slide {

                @extend .video-h1;
                letter-spacing: 0pt;
                //      display: inline-block;
                display: none;
                //   background-color: rgba(255, 224, 235, 0.32);
                transition: transform $slide-duration $easeInSine;

            }

            .t1-r-div, .t1-w-div {
                display: inline-block;
                width: 0px;
                transform-origin: -20px 50%;
                transition: width $slide-duration $easeInMine;
            }

            .grown & {
                .t1-r-div, .t1-w-div {
                    width: 59.22px;
                    transition: width $slide-duration $easeOutMine;
                }

                .t1-r-div {
                    width: 50.22px;
                    perspective: 200px;
                }

                .t1-w-div {
                    width: 65.22px;
                    perspective: 200px;
                }

                .t1-slide {
                    display: inline-block;
                    transform: rotateY(0deg);
                    transition: transform $slide-duration $easeOutSine;
                    transition-delay: 0.2s;
                }
            }

            .t1-g {
                margin-right: 0.7pt;
            }


            .t1-r-div {
                margin-right: 3.9pt;
                margin-right: 5.0pt;
                text-align: left;
            }

            .t1-r {
                transform-origin: 0px center;
                //transform-origin: -20px 50%;
            }

            .t1-o {
                margin-right: 1.8pt;
                margin-right: 3.0pt;
            }

            .t1-w-div {
                margin-right: 4.5pt;
                text-align: left;
            }

            .t1-w {
                transform-origin: 20% center;
            }

            .t1-slide {
                transform: rotateY(90deg);
                transform-style: preserve-3d;
            }

        }

        .t2 {
            @extend .video-h1;
            letter-spacing: 0.9pt;
            color: $lime-green;
            padding-left: 8.4px;
            padding-left: 0px;
            position: relative;
            // this needs to be made
            top: -3px;
        }

        .t3 {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: fs(40pt);
            line-height: lh(40pt);
            color: white;
            text-align: center;
            margin-top: 10px;
            //padding-left: 12px;
            //padding-left: 8px;
        }


    }

    @include media-breakpoint-down(bfl) {
        //        @include makeGwuText(1320);
        @include makeGwuText(mmToMult(80));
    }
    @include media-breakpoint-down(xxl) {
        //        @include makeGwuText(1140);
        @include makeGwuText(mmToMult(70));
    }
    @include media-breakpoint-down(xl) {
        //        @include makeGwuText(960);
        @include makeGwuText(mmToMult(65));

        .video-logo {
            top: 60px;
        }

    }
    @include media-breakpoint-down(lg) {
        //        @include makeGwuText(720);
        @include makeGwuText(mmToMult(55));
        .video-logo {
            top: 45px;
        }
    }


}


.t1-go {
    opacity: 1;
}

.t1-grow {
    //opacity: 0;
}

.grown {
    .t1-go {
        opacity: 0 !important;
    }

    .t1-grow {
        opacity: 1 !important;
    }
}

#home-video {

    .video-container #video {
        left: 0px;
        top: -80px;
        width: 1920px;
        height: 1080px;
    }


    @include media-breakpoint-down(bfl) {
        .video-container #video {
            left: 0px;
            top: -80px;
            width: 1330px;
            height: (1330px * $vhw);
        }
    }
    @include media-breakpoint-down(xxl) {
        .video-container #video {
            left: 0px;
            top: -61px;
            width: 1140px;
            height: (1140px * $vhw);
        }
    }
    @include media-breakpoint-down(xl) {
        .video-container #video {
            left: 0px;
            top: -240px;
            width: 1400px;

            left: 0px;
            top: -120px;
            width: 1160px;
            height: (1160px * $vhw);
        }
    }
    @include media-breakpoint-down(lg) {
        .video-container #video {
            left: -50px;
            top: -26px;
            width: 840px;
            height: (840px * $vhw);
        }
    }
    @include media-breakpoint-down(md) {
        .video-container #video {
            left: -250px;
            top: -11px;
            width: 840px;
            height: (840px * $vhw);
        }
    }

    @include media-breakpoint-down(sm) {
        .video-container #video {
            left: -189px;
            top: -1px;
            width: 746px;
            height: (746px * $vhw);
        }
    }

}

///
///  Stats
///
#stats-section {

    //min-height: 262px;
    background-color: $navy;
    color: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-down(lg) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 0;
    }


    .stat-panel {
        flex: 0 0 1560px;
        @include media-breakpoint-down(xxl) {
            flex: 1 1 auto;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;

        .stat {
            @include media-breakpoint-down(xl) {
                flex: 1 1 185px;
            }

            display: flex;
            //height: 185px;
            align-items: center;

            .stat-wrapper {
                display: flex;
                flex-direction: row;

                //      height: 185px;
                justify-content: flex-start;
                align-items: center;

                font-size: calc(30pt / 1.5);
                font-size: 20pt;
                font-weight: 300;
                line-height: 1.1667em;


                .stat-figure {
                    flex: 0 1 auto;
                    // height: 185px;
                    //display: block;
                    //position: relative;
                    //left: 0;
                    //    top: -50%;


                }

                .stat-desc {
                    flex: 1 1 auto;

                    position: relative;;
                    top: -5px;
                    color: white;

                    font-family: 'Source Sans Pro', sans-serif;
                    //font-size: calc(30pt / 1.5);
                    //font-weight: 300;
                    //line-height: 1.1667em;
                    padding-left: 0.5em;
                }

                @include media-breakpoint-down(xl) {

                    .stat-figure {
                        //transform-origin: center center;
                        //transform: scale(0.8);
                        br {
                            display: none;
                        }
                    }
                    .stat-desc {
                        //    font-size: 16pt;

                    }

                }


            }

            .stat-figure-svg {
                width: 185px;
                height: 185px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            @include media-breakpoint-between(lg, 2 xl) {
                .stat-wrapper {
                    font-size: calc(20pt * 0.8);
                }
                .stat-figure-svg {
                    width: calc(185px * 0.8);
                    height: calc(185px * 0.8);
                }
            }
            @include media-breakpoint-between(md, lg) {
                .stat-wrapper {
                    font-size: calc(20pt * 0.6);
                }
                .stat-figure-svg {
                    width: calc(185px * 0.6);
                    height: calc(185px * 0.6);
                }
            }


            @include media-breakpoint-up(xl) {
                &.stat-projects {
                    flex: 0 1 380px;
                }
                &.stat-jobs {
                    flex: 0 1 390px;
                }
                &.stat-owned {
                    flex: 0 1 350px;
                }
            }
            @include media-breakpoint-only(xl) {
                &.stat-projects {
                    flex: 0 1 310px;
                }
                &.stat-jobs {
                    flex: 0 1 330px;
                }
                &.stat-owned {
                    flex: 0 1 270px;
                }
            }
            @include media-breakpoint-only(xxl) {
                &.stat-projects {
                    flex: 0 1 310px;
                }
                &.stat-jobs {
                    flex: 0 1 410px;
                }
                &.stat-owned {
                    flex: 0 1 270px;
                }
            }

        }


        .edge-filler {
            flex: 1.9 1 auto;
        }

        .start-filler {
        }

        .end-filler {
        }

        .center-filler {
            flex: 1 1 auto;
        }


        @include media-breakpoint-down(xl) {
            .edge-filler {
                flex: 0.9 1 auto;
            }

            .start-filler {
            }

            .end-filler {
            }

            .center-filler {
                flex: 0.9 1 auto;
            }

        }

        @include media-breakpoint-down(sm) {
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            align-items: stretch;


            .stat {
                flex: 1 1 185px;
                //                width: 600px;
                flex-direction: row;
                justify-content: center;

                .stat-figure-svg {
                    width: calc(185px * 0.9);
                    height: calc(185px * 0.9);
                }


                .stat-wrapper {
                    font-size: calc(20pt * 0.9);

                    flex: 0 1 600px;
                    margin: 0 40px;

                    .stat-figure {
                        flex: 0 0 185px;
                    }

                    .stat-desc {
                        flex: 1 1 auto;
                    }
                }


                &.stat-projects {
                    br {
                        display: none;
                    }

                    // flex: 0 1 185px;
                }

                &.stat-jobs {
                    //    flex: 0 1 185px;
                }

                &.stat-owned {
                    //    flex: 0 1 185px;
                    //                    width: 400px;
                }

            }
            .edge-filler {
                flex: 0 0 40px;
                //                display:none;
            }
            .center-filler {
                flex: 0 0 15px;
                //                display:none;
            }

        }

        @include media-breakpoint-down(xl) {

        }

    }


    .stat-svg-wrapper {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        height: 0;
    }

    .stat-svg-wrapper svg {
        //position: relative;
        //left: 0;
        //top: 0;
    }


    .stat-figure-svg {
        //position: relative;
        //left: 0;
        //top: 0;
        //height: 0;
    }

    circle.stat-circle {
        stroke: #0e9a46;
        fill: transparent;
        stroke-width: 4px;

        stroke-dasharray: 360;
        stroke-dashoffset: 360;

        transition-property: stroke-dashoffset;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-timing-function: ease-in-out;

        transform: rotateZ(270deg);
        transform-origin: center center;

    }

    circle.stat-circle.open {
        stroke-dashoffset: 0;
        transition-duration: 2s;
    }

    .stat-linear .stat-circle {
        transition-timing-function: linear;
    }

    .stat-linear .stat-circle {
        transition-timing-function: ease-in-out;
    }

    .stat-figure-text {
        color: $lime-green;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 58pt;
        text-align: center;
        vertical-align: middle;
        position: relative;
    }

    .stat text {
        font-size: 51pt;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        fill: $lime-green;
    }


}


#grow-alt {


    .grow-alt-text {
        padding: 5px 0;


        .t1-fade {
            display: inline-block;

            //            height: 64px;

            //@include media-breakpoint-down(md) {
            //    height:  42px;
            //    .t1-word {
            //        line-height:  42px;
            //    }
            //}
            //@include media-breakpoint-down(sm) {
            //    height: 35px;
            //    .t1-word {
            //        line-height:  35px;
            //    }
            //}
            //@include media-breakpoint-down(xs) {
            //    height: 25px;
            //    .t1-word {
            //        line-height:  25px;
            //    }
            //}

        }

        $t1-height: 41pt;
        $t2-height: 42pt;

        $sm-h: 38px; // 32px; //28px;
        $md-h: 42px;
        $lg-h: 42px;

        .t2 {
            display: inline-block;

            font-family: 'Questrial', sans-serif;
            font-weight: normal;
            text-align: center;

            letter-spacing: 0.9pt;
            color: $lime-green;
            position: relative;
            //            top: 10px;
            //font-size: 50pt;
            white-space: nowrap;
            line-height: 1em;

            @include media-breakpoint-down(md) {
                font-size: $md-h;
                top: 0.033em;
                margin-left: 67px;

                margin-left: 37px;

                top: 0.02em;
                margin-left: 67px;
                margin-left: 56px;
            }
            @include media-breakpoint-down(sm) {
                font-size: $sm-h;
                //     top: 0.06em;
                top: -1px;
                margin-left: 44px;
                margin-left: 12px;
                top: 0px;
                top: 0.02em;
                margin-left: 37px
            }


        }

        .t3 {
            display: block;
        }

        .t3 {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: fs(40pt);
            line-height: lh(40pt);
            color: white;
            text-align: center;
            //            margin-top: 10px;
            //padding-left: 12px;
            //padding-left: 8px;
            @include media-breakpoint-down(md) {
                font-size: 20pt;
                padding-left: 11px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 17pt;
            }
            @include media-breakpoint-down(xs) {
                font-size: 15pt;
            }

        }


        .t1-fade {
            position: relative;
            width: 11.5em;
            color: white;

            //.t1-go-wrapper {
            //  position:relative;;
            //  top:0;left:0;
            //}

            $crossover-delay: 300ms;
            $crossover-duration: 600ms;

            .t1-word {

                transition-duration: $crossover-duration;
                transition-delay: 0ms;
                transition-property: opacity;
            }


            @include media-breakpoint-down(md) {
                width: 7.5em;
                height: $md-h;
                .t1-word {
                    height: $md-h;
                    line-height: $md-h;

                    img {
                        height: $md-h;
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                width: 7.5em;
                height: $sm-h;
                .t1-word {
                    height: $sm-h;
                    line-height: $sm-h;

                    img {
                        height: $sm-h;
                    }
                }
            }


            .t1-go {
                position: absolute;
                //transition-delay: $crossover-delay;
                margin-left: 71px;
                margin-left: 47px;
                margin-left: 50px;
                //margin-left: 81px;

            }

            .t1-grow {
                position: absolute;
                top: 0;
                left: 0;
                //@extend .video-h1;
                letter-spacing: 0pt;
                display: inline-block;
                width: 100%;
                text-align: center;
                transition-delay: 0ms;
            }

            .t1-grow {
                opacity: 0;
            }

            .grown & {
                .t1-go {
                    opacity: 0;
                    //transition-timing-function: ease-in-out;
                    //transition-delay: 0ms;
                }

                .t1-grow {
                    opacity: 1;
                    //transition-timing-function: ease-in-out;
                    //transition-delay: $crossover-delay;
                }
            }
        }


    }


}


#home-slide-margaret-river {
    background-repeat: no-repeat;

    //background-size: 1730px;
    //background-repeat: no-repeat;
    //background-position-x: -303px;
    //background-position-y: 0px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    @include media-breakpoint-down(md) {
        background-position-x: right;
    }
    background-position-y: bottom;
}

///
/// Design
///

#design {

    //min-height: 608px;
    background-color: $green;

    .section-image {
        //        flex: 2 1 66%;

        @include media-breakpoint-down(md) {

            //            flex: 1 0 400px;
        }
    }


    #design-1a2 {
    }


    .section-image {

    }


}

#design-1a {
    background-image: url('../images/Designers 1.jpg');

    .webp & {
        background-image: url('../images/Designers 1.webp');
    }

    background-repeat: no-repeat;
    background-size: cover;
    //background-position-x: left;
    //background-position-y: bottom;

    @include media-breakpoint-down(bfl) {
        background-size: 1250px;
        background-position-x: -10px;
        background-position-y: -70px;

        background-size: 1010px;
        background-position-x: -86px;
        background-position-y: bottom;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 1110px;
        background-position-x: 0px;
        background-position-y: -40px;
    }
    @include media-breakpoint-down(xl) {
        background-size: 990px;
        background-position-x: 0px;
        background-position-y: -30px
    }
    @include media-breakpoint-down(lg) {
        //height: 42vw;
    }
    @include media-breakpoint-down(md) {
        //height: 42vw;
    }
    @include media-breakpoint-down(sm) {
    }

}


///
/// Environment
///

#environment {

    .env-info-panel {
        //flex: 0 0 665px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;
        background-color: $navy;
    }

    #env-info-panel {
        z-index: 2;
    }


    #env-1 {
        position: relative;
        z-index: 0;
        //flex: 1 0 auto;

        background-size: 1397px;
        background-position-y: -14px;
        background-position-x: right;
        height: 604px;

        @include media-breakpoint-down(bfl) {
            background-size: 1277px;
            background-position-y: top;
            background-position-x: -380px;
            height: 481px;
        }
        @include media-breakpoint-down(xxl) {
            background-size: 1197px;
            background-position-y: top;
            background-position-x: -382px;
            height: 420px;
        }
        @include media-breakpoint-down(xl) {
            background-size: 1147px;
            background-position-y: top;
            background-position-x: -362px;
            height: 385px;
        }
        @include media-breakpoint-down(lg) {
            background-size: 758px;
            background-position-y: -20px;
            background-position-x: -242px;
            height: 400px;

            background-size: 880px;
            background-position-y: 0px;
            background-position-x: -140px;
            height: 340px;
        }
        @include media-breakpoint-down(md) {
            background-size: 819px;
            background-position-y: -17px;
            background-position-x: -250px;
            height: 300px;
        }
        @include media-breakpoint-down(sm) {
            background-size: 166%;
            background-position-y: 10%;
            background-position-x: 80%;
            height: 300px;
            height: 70vw;
        }
        background-image: url("../images/Sustainability.jpg");

        .webp & {
            background-image: url("../images/Sustainability.webp");
        }


    }

    .env-info {


        position: relative;
        top: 0;
        left: 0;
        width: 524px;
        height: 524px;

        display: flex;
        justify-content: center;
        align-items: center;

        .env-circle {
            position: absolute;
            top: 0;
            left: 0;
        }

        .env-text {
            font-family: 'Questrial', sans-serif;
            font-weight: normal;
            font-size: fs(50pt);
            line-height: lh(55pt);
            letter-spacing: ls(15);
            color: $lime-green;
            padding-left: 0.65em;


        }

    }

    .section-text {
        background-color: transparentize($navy, $draw-transparentise);
    }

    .env-panel-color {
        background-color: transparentize($navy, 0.4) !important;
    }

    .env-panel-wrapper {
        //        flex: 0 0 0;
        position: relative;
        left: 0;
        top: 0;
        width: 0;
        z-index: 1;

        .env-panel {
            width: 430px;
            height: 100%;
            left: -430px;


            transition: left 500ms ease-in-out;

            &.open {
                left: 0px;
            }

            position: relative;
            z-index: 1;

            background-color: transparentize($navy, $draw-transparentise);
            background-color: transparentize($navy, 0.4);
            padding-left: 62px;
            padding-right: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: 18.625pt;
                line-height: 22.35pt;
                color: white;
                margin-top: 1em;
                margin-bottom: 1em;
            }

            a {
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 600;
                font-size: fs(25pt);
                line-height: lh(30pt);
                color: $lime-green;
                padding-top: 1.6em
            }


        }
    }


}

@include media-breakpoint-down(xxl) {
    #environment .env-panel-wrapper .env-panel {
        width: 310px;
        left: -310px;

        p, a {
            font-size: 0.9em;
            line-height: 1.2em;

            br {
                display: none;
            }
        }

        padding-left: 40px;
        padding-right: 40px;

    }
    #env-panel-flyout {
    }
    #env-1 {
    }
}

@include media-breakpoint-down(lg) {
    #environment .env-panel-wrapper .env-panel {
        //width: 300px;
        //left: -300px;
    }
    #env-info-panel {
        order: 1;
    }
    #env-panel-flyout {
        order: 2;
        display: none;
    }
    #env-1 {
        order: 2;
    }

}

///
/// Employment
///

#employment {
    background-color: $green;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    @include media-breakpoint-up(xxl) {
        //        min-height: 606px;
    }

    //.img-blk.img-s1 {
    //    flex: 1 0 auto;
    //}

    #emp-1 {
        //flex: 1 1 340px;
        //        flex-grow: 1;
        background-size: 649px;
        background-position-y: -45px;
        background-position-x: -108px;
        background-image: url('../images/emp-opp-1.jpg');

        background-size: cover;
        background-position-y: bottom;
        background-position-x: center;


        .webp & {
            background-image: url('../images/emp-opp-1.jpg');
        }


    }

    #emp-2 {
        //        flex: 0 1 548px;

        //        background-size: 548px;
        background-size: cover;
        background-position-y: bottom;
        background-position-x: right;
        background-position-x: center;

        background-image: url('../images/People 2.png');

        .webp & {
            background-image: url('../images/People 2.webp');
        }

        @include media-breakpoint-only(md) {
            background-position-x: -51px;
        }
        @include media-breakpoint-down(md) {
            height: 60vw;
        }
    }

    #emp-3 {
        //        flex: 0 1 379px;
        background-size: 379px 630px;
        //  background-size: cover;
        background-position-y: (611px - 630px);
        background-position-x: 0;

        background-size: cover;
        background-position-y: bottom;
        background-position-x: center;

        background-image: url('../images/People 3.png');

        .webp & {
            background-image: url('../images/People 3.webp');
        }

        @include media-breakpoint-down(md) {
            height: 60vw;

        }
    }

    @include media-breakpoint-down(xxl) {
        #emp-1 {
            //display: none;
        }
        #emp-2 {
            //display: none;
        }
    }

    #hfy-text {
        //flex: 0 0 588px;

        .size-1 {
            font-size: 48.425pt;
            line-height: 1.01em; // 49.17pt;
            line-height: 1.01;
            letter-spacing: .415pt;

            @include media-breakpoint-down(xl) {
                font-size: 35pt;
                letter-spacing: .415pt;
            }

        }

        @include media-breakpoint-between(md, xl) {
            //    flex: 0 0 461px;
            .size-1 {
                font-size: 35pt;
                letter-spacing: .415pt;
            }
        }
        @include media-breakpoint-down(md) {
            //         flex: 1 1 auto;
            //   flex: 0 1 60%;
        }


        //padding: 86.4px;
    }

    @include media-breakpoint-down(lg) {
        //flex-wrap: wrap;
        #emp-2 {
            //display: none;
            //background-image: none;
            //img {
            //    display:block;
            //}
            //display: none;
        }
        #emp-3 {
            background-position-y: center;
            //      flex-grow:1;

            img {
            }

        }
    }

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;

        #hfy-text {
            //          flex-grow:1;

        }
        .title br {
            display: none;

        }

        #emp-2 {
            display: block;
            //   flex:1 1 100%;
            //     height:68vw;
            background-position-y: top;
        }

        #emp-3 {
            //            background-image: none;
            //         flex:1 1 100%;
            //   height:62vw;

            img {
                //display: block;
            }

        }
    }
    @include media-breakpoint-down(sm) {
        //    flex-wrap: wrap;

    }
}


//.no-webp .img-blk#emp-1 {
//
//}
//
//.webp .img-blk#emp-1 {
//    background-image: url('../images/emp-opp-1.jpg');
//}

//.no-webp .img-blk#emp-2 {
//    background-image: url('../images/People 2.png');
//}
//
//.webp .img-blk#emp-2 {
//    background-image: url('../images/People 2.webp');
//}
//
//.no-webp .img-blk#emp-3 {
//    background-image: url('../images/People 3.png');
//}
//
//.webp .img-blk#emp-3 {
//    background-image: url('../images/People 3.webp');
//}


///
///  Projects
///

#our-projects {


    display: flex;
    justify-content: flex-start;
    align-items: stretch;


    #project-info-panel {
        @include media-breakpoint-up(md) {
            //   height: 788px;
        }
    }


    #projectCarousel {


    }

    .prj-1 {
        background-image: url('../images/Project 1.jpg');

        .webp & {
            background-image: url("../images/Project 1.webp");
        }

        background-size: 1247px 797px;
        background-size: cover;
        background-position-y: -3px;
        background-repeat: no-repeat;
        flex: 1 0 auto;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .left-arrow {
            margin-left: 28px;
            margin-bottom: 56px;
            cursor: pointer;
        }

        .right-arrow {
            margin-right: 28px;
            margin-bottom: 56px;
            cursor: pointer;
        }

        $arrow-drop-shadow: drop-shadow(2px 2px 3px rgba(0, 0, 0, .3));

        .arrow {
            transition: all 0.1s ease-in-out;

            &:hover {
                -webkit-filter: $arrow-drop-shadow;
                filter: $arrow-drop-shadow;
            }
        }
    }

    div#project-title {
        margin-top: 1em;

        //font-family: 'Questrial', sans-serif;
        //font-weight: normal;
        //font-size: fs(50pt);
        //line-height: lh(55pt);
        letter-spacing: ls(15);
        color: $lime-green;
    }

    #project-desc {
        margin-top: 1.2em;
        font-family: 'Source Sans Pro', sans-serif;
        //font-weight: 300;
        //font-size: fs(25pt);
        //line-height: lh(30pt);
        color: white;
        padding-right: 0.5em;
    }

    .circle-svg a {
        //margin-top: 1em;
        display: inline-block;
        color: $lime-green;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-size: fs(25pt);
        line-height: lh(30pt);
    }

    .text-panel {
        //flex: 0 0 666px;

        background-color: $navy;
        //padding-left: 89.6px;
        //padding-top: 84.8px;
        //padding-right: 80px;

        .recent {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: fs(35pt);
            line-height: lh(40pt);
            color: white;
        }

        #project-info-block {
            min-height: 250px;
            min-height: 282px;

            @include media-breakpoint-down(bfl) {
                //                min-height: 250px;
            }
            @include media-breakpoint-down(xl) {
                //                min-height: 270px;
            }
            @include media-breakpoint-down(lg) {
                //                min-height: 160px;
            }
            @include media-breakpoint-down(md) {
                //                min-height: 310px;
            }
            @include media-breakpoint-down(sm) {
                //                min-height: 160px;
            }
        }


    }

    .circle-wrapper {

        margin-top: 60px;
        margin-top: 50px;
        @include media-breakpoint-down(xl) {
            margin-top: 0px;
        }

        position: relative;
        height: 230px;


        .circle {
            position: absolute;
            width: 230px;
            height: 230px;

            right: 0px;
            top: 0; // -8px;

            cursor: pointer;


            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .circle-svg {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                a {
                    display: inline-block;
                    height: 100%;
                    width: 100%;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                circle.std-circle {
                    stroke: #0e9a46;
                    fill: transparent;

                    stroke-dasharray: 360;
                    stroke-dashoffset: 360;
                    transform: rotateZ(270deg);
                    transform-origin: center center;

                    transition-property: stroke-dashoffset;
                    transition-delay: 0s;
                    transition-duration: 0s;
                    transition-timing-function: ease-in-out;

                }

            }

            .circle-text {
                pointer-events: none;
                position: relative;
                z-index: 2;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: fs(40pt);
                line-height: lh(42pt);
                color: white;
                text-align: center;

                top: -25px;
                left: -13px;

                top: -10px;
                left: 2px;

                font-size: fs(35pt);
                line-height: lh(40pt);

                font-size: 20pt;
                line-height: 1.45em;
            }

            transform-origin: center center;
            transition: all 100ms ease-in-out;

            &.open {
                //$more-drop-shadow: drop-shadow(3px 3px 4px rgba(0, 0, 0, .6));
                //-webkit-filter: $more-drop-shadow;
                //filter: $more-drop-shadow;
                //transform:scale(1.02);
            }


        }

    }

    .circle.open .circle-svg circle.std-circle {
        stroke-dashoffset: 0;
        transition-duration: 2s;

    }

    .carousel {
        flex: 1 1 auto;
    }

    .carousel-inner {
        height: 100%;
        //background-color: lightcyan;
        @include media-breakpoint-down(lg) {
            min-height: 450px;
        }
        @include media-breakpoint-down(md) {
            min-height: 420px;
        }
        @include media-breakpoint-down(sm) {
            min-height: 98vw;
        }


        .carousel-item {
            height: 100%;

            @include media-breakpoint-down(lg) {
                min-height: 450px;
            }
            @include media-breakpoint-down(md) {
                min-height: 420px;
            }
            @include media-breakpoint-down(sm) {
                min-height: 98vw;
            }

            .project-carousel-item {
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;

                @include media-breakpoint-down(lg) {
                    min-height: 450px;
                }
                @include media-breakpoint-down(md) {
                    min-height: 420px;
                }
                @include media-breakpoint-down(sm) {
                    min-height: 98vw;
                }
            }
        }
    }

    $carousel-button-max-width: 60px;

    .carousel-control-next {
        align-items: flex-end;
        bottom: 70px;
        width: 10%;
        min-width: $carousel-button-max-width;
        opacity: 1;
        padding-right: 5px;

        img {
            width: 100%;
            max-width: 66px;
        }
    }

    .carousel-control-prev {
        align-items: flex-end;
        bottom: 70px;
        width: 10%;
        min-width: $carousel-button-max-width;
        opacity: 1;
        padding-left: 5px;

        img {
            margin: 0 5px;
            width: 100%;
            max-width: 66px;
        }
    }
}

@include media-breakpoint-down(bfl) {
    #our-projects .circle-wrapper {
        margin-top: 30px;
    }
    #our-projects .text-panel #project-info-block {
        min-height: 250px;
    }
    #our-projects .circle-wrapper .circle {
        width: 200px;
        height: 200px;
    }
    #our-projects .circle-wrapper {
        height: 200px;
        margin-top: 30px;
    }

}

@include media-breakpoint-down(xxl) {
    #our-projects .circle-wrapper .circle {
        width: 190px;
        height: 190px;
    }
    #our-projects .text-panel #project-info-block {
        min-height: 250px;
    }
    #our-projects .circle-wrapper {
        height: 190px;
        margin-top: 30px;
    }
    #our-projects .carousel-control-prev,
    #our-projects .carousel-control-next {
        bottom: 70px * $scale-2xl;
    }

}

@include media-breakpoint-down(xl) {
    #our-projects .text-panel #project-info-block {
        min-height: 240px;
    }
    #our-projects .circle-wrapper {
        height: 180px;
    }
    #our-projects .carousel-control-prev,
    #our-projects .carousel-control-next {
        bottom: 70px *  $scale-xl;
    }

    #our-projects {
        #project-info-panel {
            //height: 580px;
        }

        .prj-1 {
            .left-arrow {
            }

            .right-arrow {
            }

            .arrow {
                &:hover {
                }
            }
        }

        .text {
            padding-left: 60px;
            padding-top: 60px;
            padding-right: 60px;

            .recent {
            }

            #project-info-block {
                min-height: 270px;
            }

            div#project-title {
            }

            #project-desc {
            }

            a {
            }
        }

        .circle-wrapper {

            margin-top: 0px;
            height: 180px;


            .circle {
                width: 160px;
                height: 160px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #our-projects .circle-wrapper .circle {
        width: 140px;
        height: 140px;
    }
    #our-projects .circle-wrapper {
        height: 140px;
        margin-top: 0px;
    }
    #our-projects .text-panel #project-info-block {
        min-height: 120px;
    }
    //#our-projects .carousel-control-prev,
    //#our-projects .carousel-control-next
    //{
    //    bottom: 70px *  $scale-lg;
    //}


    #our-projects {
        flex-direction: column;

        #project-info-panel {
            height: unset;
        }

        #projectCarousel {
            flex: 1 0 401px;

            .carousel-control {
                align-items: flex-start;
                padding-top: 20px;
            }
        }

        //.prj-1 {
        //    .left-arrow {
        //    }
        //
        //    .right-arrow {
        //    }
        //
        //    .arrow {
        //        &:hover {
        //        }
        //    }
        //}

        .text {
            padding-left: 60px;
            padding-top: 60px;
            padding-right: 60px;

            .recent {
            }

            #project-info-block {
                min-height: 100px;
            }

            div#project-title {
            }

            #project-desc {
            }

            a {
            }
        }


    }
}

@include media-breakpoint-down(md) {
    #our-projects {

        //  flex-direction: column;


        #project-info-panel {
            //height: 580px;
            flex: 1 1 auto;
        }

        #projectCarousel {
            flex: 1 0 401px;

            .carousel-control {
                align-items: flex-start;
                padding-top: 20px;
            }
        }

        .prj-1 {
            .left-arrow {
            }

            .right-arrow {
            }

            .arrow {
                &:hover {
                }
            }
        }

        .text {
            padding-left: 60px;
            padding-top: 60px;
            padding-right: 60px;

            .recent {
            }

            #project-info-block {
                min-height: 100px;
            }

            div#project-title {
            }

            #project-desc {
            }

            a {
            }
        }

        .circle-wrapper {

            margin-top: 0px;

            height: 140px;


            .circle {
                width: 140px;
                height: 140px;
            }
        }
    }
}


@include media-breakpoint-down(lg) {

    #environment {

        .circ-block-panel {
            padding: 15px 8px;
        }

        //.circ-block-wrapper {
        //    .circ-block {
        //        width: 290px;
        //        height: 290px;
        //
        //        .circ-text {
        //            font-size: 21pt;
        //        }
        //    }
        //}
    }
}
