// Body
$body-bg: #f8fafc;


$transparentise-val: 0.1;

$navy: #024f5a;
$green: #0e9a46;
$lime-green: #94d70a;
$mint: #71b88a;
$greenish: #7fd5ab;

$vhw: (9/16);

$greenOnWhite: lighten($green, 6);
$navy-trans: transparentize($navy, 0.1);

$navy-trans: transparentize($navy, $transparentise-val);
$green-trans: transparentize($green, $transparentise-val);
$lime-trans: transparentize($lime-green, $transparentise-val);


$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInCirc2: cubic-bezier(0.665, 0.315, 0.645, 0.315);
$easeInCirc3: cubic-bezier(0.490, 0.235, 0.685, 0.490);
$easeInCirc4: cubic-bezier(0.500, 0.285, 0.680, 0.455);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);

$easeOutMine: cubic-bezier(0.090, 1.105, 0.350, 1.005);

$easeOutMine: cubic-bezier(0.400, 1.0, 0.700, 1.00);
$easeOutMine: cubic-bezier(0.290, 1.000, 0.765, 0.995);

$easeInMine: cubic-bezier(0.285, 0.025, 0.565, 0.030);

$easeOutRotate: cubic-bezier(0.000, 0.990, 0.210, 1.005);

$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$slide-duration: 1s;


$jump-duration: 0.3s;
$jump-grow: 101%;
$drop-shadow: drop-shadow(4px 4px 2px rgba(0, 0, 0, .3));


.mint {
    color: $mint;
}

.bg-mint {
    background-color: $mint;
}

$greenOnWhite: lighten($green, 6);

$black: black;


// 14.9pt 20pt

@function fs($pt) {
    @return $pt * (14.9/20);
}

@function lh($pt) {
    @return $pt * (14.9/20);
}

@function ls($pt) {
    @return 1pt / 1000 * $pt * (0.415/0.015);
}


// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$grid-columns: 17 !default;
$grid-gutter-width: 30px !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    bfl: 1850px
) !default;

// 1920px

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    bfl: 1830px
) !default;


$scale-2xl: (1320/1830);
$scale-xl: (1140/1830);
$scale-lg: (960/1830);
$scale-md: (720/1830);

@function mmToMult ( $mm) {
    $p: $mm * 5.125 * 3.539915;
    @return $p;
}


@mixin projectCircle($perc, $left:130px, $top: 190px) {
    .video-logo {
        /*
        top : $top * $perc;
        left: $left * $perc;
        width:530px * $perc;
        height:531px  * $perc;
        */
        .project-circle-text {
            font-size: 59pt * $perc;
        }
    }
}


@mixin projectVideo($width) {
    video#video {
        width: $width;
        height: $width * (1080/1920);
    }
}

@mixin makeVideoCircles( $mult) {
    .video-logo {
        width: 524px * ($mult/1830);
        height: 524px * ($mult/1830);
        left: 70px  * ($mult/1830);
        top: 120px  * ($mult/1830);
    }
}


@mixin projectVideoRow($height,$width) {
    .video-row {
        height: $height !important;
    }
    video#video {
        width: $width;
        height: $width * (1080/1920);
    }
}

$bullet-indent: 30px;
