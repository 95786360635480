
#design-env {
    //   background-color: $navy;
    //    height: 607px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    z-index: 1;

    position: relative;

    .env-info-panel {
        flex: 0 0 665px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;
        background-color: $navy;
    }




}

#design-banner {
    height: 813px;
}

#design-0a {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -80px;
    background-position-y: 17.9%;
    background-position-x: 0px;

    background-image: url("../images/design-1.jpg");

    .webp & {
        background-image: url("../images/design-1.webp");
    }
}

#design-1 {
    //  height: 240px;
    background-color: $navy;


    .stat {
        svg {
            text {
                stroke: white;
                font-size: fs(24pt);
                line-height: lh(27pt);
                font-weight: 400;
            }
        }

        .std-circle {
            stroke-width: 4px;
        }
    }

    @include media-breakpoint-down(bfl) {
        .size-1 {
            font-size: 27pt !important;
        }
        .circled-text {
            width: 140px;
            height: 140px;
            font-size: 0.9em;
        }

    }
    @include media-breakpoint-down(xxl) {

        .size-1 {
            //font-size: 30pt;
            font-size: 28pt !important;
        }
        .circled-text {
            width: 140px;
            height: 140px;
            font-size: 0.9em;
        }

    }

    @include media-breakpoint-down(xl) {
        .size-1 {
            font-size: 21pt !important;
        }
        .circled-text {
            width: 120px;
            height: 120px;
            font-size: 0.9em;
        }

    }
    @include media-breakpoint-down(lg) {
        .size-1 {
            font-size: 21pt !important;
        }
    }
    @include media-breakpoint-down(md) {
        .size-1 {
            font-size: 21pt !important;
        }
    }

    .design-1-edge {
        flex: 1 0 30px;
    }

    .design-1-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 100%;
        padding: 30px 20px;
    }

    .design-1-inner {
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .design-circle-bar {
        flex: 1 1 920px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px
    }

    .design-1-title {
        flex: 1 1 600px;
        margin: 0;
        padding-right: 10px;

    }

}

@include media-breakpoint-down(xxl) {
    #design-1 {
        .size-1 {

        }

        #design-1 {
            gap: 10px;
        }
    }
}

@include media-breakpoint-down(lg) {
    //font-size:32pt;
    #design-1 {
        display: none;
    }
}


@include media-breakpoint-up(xl) {
    #design-2-text {
        .text {

            //margin-right: 100px;
        }
    }
}


#design-2a {
    //    width: 100%;
    background-size: cover;
    background-size: 1240px;
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: right;

    background-image: url("../images/design-2a.jpg");

    .webp & {
        background-image: url("../images/design-2a.webp");
    }


    img {
        display: none;
    }

    @include media-breakpoint-down(bfl) {
        background-size: 1130px;
        background-position-x: right;
    }

    @include media-breakpoint-down(xxl) {
        background-size: 1250px;
        background-position-x: right;
    }
    @include media-breakpoint-down(xl) {
        background-size: 1140px;
        background-position-x: -520px;
    }
    //@include media-breakpoint-down(lg) {
    //    background-size: 1170px;
    //    background-position-x: -520px;
    //}
    //@include media-breakpoint-down(md) {
    //    background-size: 1130px;
    //    background-position-x: right;
    //}

    @include media-breakpoint-down(lg) {
        //img {
        //    display: block
        //}
        height: 420px;
        background-size: cover;
        background-position-x: right;

        //        background-image: none;
    }

}



#design-3 {

    @include media-breakpoint-up(xl) {
        height: 600px;
    }
    @include media-breakpoint-down(xl) {
        //        height: 460px;
    }

}


#design-3a {
    //    flex: 1 0 50%;
    background-size: cover;
    background-position-y: 89%;
    background-position-x: right;
    // background-image: url(images/People 2.webp);
    @include media-breakpoint-down(bfl) {
        background-position-x: -100px;
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
    }
    @include media-breakpoint-down(lg) {
        background-position-x: right;
        background-position-y: top;
        height: 470px;
    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
        height: 120vw;
        background-size: cover;
        background-position-y: 16%;
        background-position-x: 30%;
    }
}

#design-3b {
    //flex: 1 0 50%;

    background-size: 1203px;
    background-position-y: -102px;
    background-position-x: -140px;
    background-position-x: -153px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(bfl) {
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
        background-size: cover;
        background-position-y: 0;
        background-position-x: -46px;
    }
    @include media-breakpoint-down(lg) {
    }
}

#design-6a {
    background-size: 1460px;
    background-position-y: 0px;
    background-position-x: 0;
    @include media-breakpoint-down(bfl) {
        background-size: 1459px;
        background-position-y: 0px;
        background-position-x: -130px
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
        background-size: 1180px;
        background-position-y: top;
        background-position-x: -112px;
    }
    @include media-breakpoint-down(lg) {
        height: 360px;
        background-size: 880px;
        background-position-y: -40px;
        background-position-x: -50px;
    }
    @include media-breakpoint-down(sm) {
        height: 110vw;
        background-size: 270%;
        background-position-y: 16%;
        background-position-x: 16%;
    }

}


#design-info-panel {


    circle {
        stroke: $lime-green;
    }

    .circ-text {
        padding-left: 0.9em;
    }

    @include media-breakpoint-down(xl) {
        .circ-text {
            //            padding-left:25px;
        }
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    z-index: 2;
    //@include media-breakpoint-only(md) {
    //    .circ-block-wrapper {
    //        .circ-block {
    //            width: 400px;
    //            height: 400px;
    //        }
    //
    //        .circ-text {
    //            font-size: 26pt;
    //        }
    //    }
    //}

}

.design-info {


    //position: relative;
    //top: 0;
    //left: 0;
    //width: 524px;
    //height: 524px;

    //display: flex;
    //justify-content: center;
    //align-items: center;

    .design-circle {
        //position: absolute;
        //top: 0;
        //left: 0;

        circle {
            stroke-width: 4px;
            stroke: $lime-green;
        }
    }

    .design-text,
    .circ-text {
        //font-family: 'Questrial', sans-serif;
        //font-weight: normal;
        //font-size: fs(50pt);
        //line-height: lh(55pt);
        //letter-spacing: ls(15);
        //color: white;
        //padding-left: 0.65em;
    }

}


#design-env-a {
    position: relative;
    z-index: 0;
    //flex: 1 0 auto;

    background-size: 1247px 650px;
    //  background-size: cover;
    background-position-y: -37px; //(611px - 650px);
    background-position-x: 0;

    background-size: 1424px;
    background-position-y: -42px; //(611px - 650px);
    background-position-x: -47px;
    background-position-x: right;

    background-image: url("../images/Sustainability.jpg");

    .webp & {
        background-image: url("../images/Sustainability.webp");
    }

    @include media-breakpoint-up(xl) {
        //             height: 607px;
    }

    @include media-breakpoint-down(lg) {
        height: 500px;
        background-size: 1424px;
        background-position-y: -6px; //(611px - 650px);
        background-position-x: right;
    }
    @include media-breakpoint-down(md) {


        height: 376px;
        background-size: 850px;
        background-position-y: 20%;
        background-position-x: 89%;
    }
    @include media-breakpoint-down(sm) {
        height: 40vw;
        background-size: 120%;
        background-position-y: 10%; //(611px - 650px);
        background-position-x: 10%;

        height: 73vw;
        background-size: 170%;
        background-position-y: 10%;
        background-position-x: 80%
    }

}


.design-panel-col {
    flex: 0 0 2px;

}

.design-panel-wrapper {
    //flex: 0 0 auto;
    //flex: 0 0 2px;
    position: relative;
    background-color: red;
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    width: 0px;
    z-index: 1;

    .design-panel {
        transition: left 500ms ease-in-out;

        &.open {
            left: -150px;
            left: 0;
        }

        position: relative;
        z-index: 1;

        background-color: transparentize($green, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            //font-size: 18.625pt;
            //line-height: 22.35pt;
            color: white;
            margin-top: 0;
            margin-bottom: 1em;
        }

        a {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            //font-size: fs(25pt);
            //line-height: lh(30pt);
            color: $lime-green;
            padding-top: 1.6em
        }

        width: 320px;
        height: 100%;
        left: -320px;

        //padding: 40px 40px;
        //padding-right: 38px;
        .design-panel-text {
            padding: 40px 40px;
        }

        @include media-breakpoint-up(xl) {

            width: 400px;
            height: 100%;
            left: -450px;

            width: 360px;
            height: 100%;
            left: -360px;

            .design-panel-text {
                padding: 40px 40px;
            }
        }


    }

}


.location-panel {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    padding: 40px;
}

.location-grid-side {
    flex: 0 0 100px;
}

#location-grid {
    flex: 0 1 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0px;
    //row-gap: 2px;
    column-gap: 60px;
    grid-auto-rows: minmax(auto, auto);
    font-size: 14pt;
}

.grid-medium #location-grid {
    grid-template-columns: repeat(5, 1fr);
}

.grid-big #location-grid {
    grid-template-columns: repeat(6, 1fr);
}

#location-grid div {
    // padding:1px 2px;
    color: white;
    font-weight: 400;
    line-height: 1.1em;

}

