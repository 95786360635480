
#projects-1a {

    //min-height: 40vw;
    @include media-breakpoint-down(xxl) {
        //    height: 40vw;
    }

    background-image: url("../images/projects/Water and Desal Projects.jpg");

    //background-size: 2053px;
    //background-position-y: -140px;
    //background-position-x: -400px;
    background-repeat: no-repeat;

    background-size: 107%;
    background-position-y: 74%;
    background-position-x: 0px;

    @include media-breakpoint-down(bfl) {
        background-size: 107%;
        background-position-y: 92%;
        background-position-x: 0px;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 107%;
        background-position-y: 92%;
        background-position-x: 0px;

    }
    @include media-breakpoint-down(xl) {
        background-size: 115%;
        background-position-y: bottom;
        background-position-x: 0px;
    }
    @include media-breakpoint-down(lg) {

        //        height:42vw;

    }
    @include media-breakpoint-down(md) {
        height: 60vw;
        max-height: 370px;
        background-size: 700px;
        background-position-y: bottom;
        background-position-x: left
    }
    @include media-breakpoint-down(sm) {
        height: 60vw;
        max-height: 370px;
        background-size: 115%;
        background-position-y: bottom;
        background-position-x: left;
    }

}

#projects-2a {
    background-image: url("../images/projects/Denmark WWTP.jpg");
}

.webp #projects-2a {
    background-image: url("../images/projects/Denmark WWTP.jpg");
}

#projects-3a {
    background-image: url("../images/projects/Adelaide Botanic Gardens ASR.jpg");
}

.webp #projects-3a {
    background-image: url("../images/projects/Adelaide Botanic Gardens ASR.jpg");
}

#projects-4a {
    background-image: url("../images/projects/Bolivar WWTP Main Pump Station.jpg");
}

.webp #projects-4a {
    background-image: url("../images/projects/Bolivar WWTP Main Pump Station.jpg");
}

#reuse-schemes-banner {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -80px;
    background-position-x: 0px;

    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -250px;
    background-position-x: center;

    background-image: url("../images/projects/Reuse Schemes Banner.jpg");
}

.webp #reuse-schemes-banner {
    background-image: url("../images/projects/Reuse Schemes Banner.webp");
}


#projects-2a {
    background-size: 1303px;
    background-position-y: -200px;
    background-position-x: -40px;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xxl) {
        //      min-height: 40vw;
    }
    @include media-breakpoint-down(bfl) {
        background-size: 1065px;
        background-position-y: -200px;
        background-position-x: -40px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 975px;
        background-position-y: -200px;
        background-position-x: -40px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(xl) {
        background-size: 873px;
        background-position-y: -187px;
        background-position-x: -62px;
        background-repeat: no-repeat;

        background-size: 939px;
        background-position-y: -187px;
        background-position-x: -34px;
        background-repeat: no-repeat;

    }
    @include media-breakpoint-down(lg) {

        background-size: 773px;
        background-position-y: -175px;
        background-position-x: -43px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(md) {
        height: 60vw;
        max-height: 370px;
        background-size: 883px;
        background-position-y: -185px;
        background-position-x: -110px;
    }
    @include media-breakpoint-down(sm) {
        height: 60vw;
        max-height: 370px;
        background-size: 104%;
        background-position-y: 50%;
        background-position-x: 70%;
    }


}

#projects-3a {
    background-size: 1312px;
    background-position-y: -160px;
    background-position-x: -11px;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xxl) {
        //height: 40vw;
    }
    @include media-breakpoint-down(bfl) {
        background-size: 873px;
        background-position-y: -167px;
        background-position-x: -162px;
        background-repeat: no-repeat;

        background-size: 1023px;
        background-position-y: -176px;
        background-position-x: -92px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 873px;
        background-position-y: -167px;
        background-position-x: -162px;
        background-repeat: no-repeat;

        background-size: 933px;
        background-position-y: -167px;
        background-position-x: -112px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(xl) {
        background-size: 873px;
        background-position-y: -187px;
        background-position-x: -62px;
        background-repeat: no-repeat;

        background-size: 873px;
        background-position-y: -167px;
        background-position-x: -162px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(lg) {
        background-size: 873px;
        background-position-y: -187px;
        background-position-x: -62px;
        background-repeat: no-repeat;

        background-size: 733px;
        background-position-y: -127px;
        background-position-x: -211px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(md) {
        height: 60vw;
        max-height: 370px;
        background-size: 673px;
        background-position-y: -125px;
        background-position-x: -130px;
    }
    @include media-breakpoint-down(sm) {
        height: 60vw;
        max-height: 370px;
        background-size: 140%;
        background-position-y: 50%;
        background-position-x: 70%;
    }


}


#projects-4a {
    background-size: 1587px;
    background-position-y: -314px;
    background-position-x: right;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xxl) {
        //height: 40vw;
    }
    @include media-breakpoint-down(bfl) {
        //background-size: 733px;
        //background-position-y: -127px;
        //background-position-x: -211px;
        //background-repeat: no-repeat;

        background-size: 1003px;
        background-position-y: -147px;
        background-position-x: -141px;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 733px;
        background-position-y: -127px;
        background-position-x: -211px;
        background-repeat: no-repeat;

        background-size: 873px;
        background-position-y: -114px;
        background-position-x: right;
        background-repeat: no-repeat;
    }
    @include media-breakpoint-down(xl) {
        background-size: 721px;
        background-position-y: -127px;
        background-position-x: -164px;
        background-repeat: no-repeat;

        background-size: 911px;
        background-position-y: -127px;
        background-position-x: -214px;
        background-repeat: no-repeat;

    }
    @include media-breakpoint-down(md) {
        height: 60vw;
        max-height: 370px;
        background-size: 732px;
        background-position-y: -135px;
        background-position-x: -150px;

        height: 60vw;
        max-height: 370px;
        background-size: 772px;
        background-position-y: -136px;
        background-position-x: -153px;
    }

    @include media-breakpoint-down(sm) {
        height: 60vw;
        max-height: 370px;
        background-size: 140%;
        background-position-y: 50%;
        background-position-x: 70%;
    }

}


#water-treatment-video.section-row {
    //    height: 814px;
    //    background-color: #343434;
    background-color: $green;
    position: relative;
    //opacity:0.4;
}

#wastewater-treatment-video.section-row {
    //    height: 814px;
    background-color: $green;
    position: relative;
    //opacity:0.4;
}

#reuse-schemes-video.section-row {
    //    height: 814px;
    background-color: $green;
    position: relative;
    //opacity:0.4;
}

#pumping-stations-video.section-row {
    //    height: 814px;
    background-color: $green;
    position: relative;
    //opacity:0.4;
}


#water-treatment-video {
    video,
    #video {
        top: -200px;
        left: -5px;
        width: 1840px;
        height: (1840px * $vhw);
    }

    @include media-breakpoint-down(bfl) {
        .video-container #video {
            left: -5px;
            top: -120px;
            width: 1330px;
            height: (1330px * $vhw);
        }
    }
    @include media-breakpoint-down(xxl) {
        .video-container #video {
            left: -5px;
            top: -120px;
            width: 1150px;
            height: (1150px * $vhw);
        }
    }
    @include media-breakpoint-down(xl) {
        .video-container #video {
            width: 970px;
            left: -5px;
            top: -106px;
            height: (970px * $vhw);

        }
    }
    @include media-breakpoint-down(lg) {
        .video-container #video {
            left: -5px;
            top: 0px;
            width: 730px;
            height: (730px * $vhw);
        }
    }
    @include media-breakpoint-down(md) {
        .video-container #video {
            left: -40px;
            top: -5px;
            width: 730px;
            height: (730px * $vhw);
        }
    }

    @include media-breakpoint-down(sm) {
        .video-container #video {
            left: -129px;
            left: -89px;
            top: 1px;

            width: 680px;
            height: (680px * $vhw);
        }
    }

}

#wastewater-treatment-video {
    video,
    #video {
        top: -200px;
        left: -5px;
        width: 1840px;
        height: (1840px * $vhw);
    }

    @include media-breakpoint-down(bfl) {
        .video-container #video {
            left: -5px;
            top: -120px;
            width: 1330px;
            height: (1330px * $vhw);
        }
    }
    @include media-breakpoint-down(xxl) {
        .video-container #video {
            left: -5px;
            top: -120px;
            width: 1150px;
            height: (1150px * $vhw);
        }
    }
    @include media-breakpoint-down(xl) {
        .video-container #video {
            left: -5px;
            top: -100px;
            width: 970px;
            height: (970px * $vhw);

        }
    }
    @include media-breakpoint-down(lg) {
        .video-container #video {
            left: -5px;
            top: -6px;
            width: 730px;
            height: (730px * $vhw);

        }
    }
    @include media-breakpoint-down(md) {
        .video-container #video {
            left: -40px;
            top: 0px;
            width: 710px;
            height: (710px * $vhw);

            left: -5px;
            top: -16px;
            //            width: 550px;
            height: 410px;
            width: (410px / $vhw);

        }
    }

    @include media-breakpoint-down(sm) {
        .video-container #video {
            left: -129px;
            top: -1px;
            width: 646px;
            height: (646px * $vhw);


        }
    }

}

#reuse-schemes-video {
    video,
    #video {
        top: -70px;
        width: 1830px;
        height: (1830px * $vhw);
    }

    @include media-breakpoint-down(bfl) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -130px;
            width: 1330px;
            height: (1330px * $vhw);
        }
    }
    @include media-breakpoint-down(xxl) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -60px;
            width: 1140px;
            height: (1140px * $vhw);
        }
    }
    @include media-breakpoint-down(xl) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -50px;
            width: 960px;
            height: (960px * $vhw);
        }
    }
    @include media-breakpoint-down(lg) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -0px;
            width: 730px;
            height: (730px * $vhw);
        }
    }
    @include media-breakpoint-down(md) {
        .video-container video,
        .video-container #video {
            left: -80px;
            top: 0px;
            width: 710px;
            height: (710px * $vhw);
        }
    }

    @include media-breakpoint-down(sm) {
        .video-container video,
        .video-container #video {
            left: -80px;
            top: -1px;
            width: 646px;
            height: (646px * $vhw);
        }
    }

}


#pumping-station-video {
    top: -90px;
}

#pumping-stations-video {
    video,
    #video {
        top: -100px;
        width: 1840px;
        height: (1840px * $vhw);
    }

    @include media-breakpoint-down(bfl) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -50px;
            width: 1330px;
            height: (1330px * $vhw);
        }
    }
    @include media-breakpoint-down(xxl) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -40px;
            width: 1140px;
            height: (1140px * $vhw);
        }
    }
    @include media-breakpoint-down(xl) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -40px;
            width: 960px;
            height: (960px * $vhw);
        }
    }
    @include media-breakpoint-down(lg) {
        .video-container video,
        .video-container #video {
            left: 0px;
            top: -0px;
            width: 730px;

            left: 0px;
            top: 0px;
            width: 760px;
            height: (760px * $vhw);
        }
    }
    @include media-breakpoint-down(md) {
        .video-container video,
        .video-container #video {
            left: -80px;
            top: 0px;
            width: 740px;
            height: (740px * $vhw);
        }
    }

    @include media-breakpoint-down(sm) {
        .video-container video,
        .video-container #video {
            left: -80px;
            top: 0px;
            width: 670px;
            height: (670px * $vhw);
        }
    }

}


.project-category {
    .circ-block-wrapper .circ-text {
        text-align: center;
        font-size: 58pt;
        line-height: 1.1em;
        @extend .fo-questal;
        color: $lime-green;
        font-weight: 400;
        z-index: 100;
    }

    @include media-breakpoint-down(bfl) {
        .circ-block-wrapper .circ-block {
            width: 380px;
            height: 380px;

            .circ-text {
                font-size: (59pt * $scale-2xl);
                font-size: 41pt;
                line-height: 1.1em;
            }
        }

    }
    @include media-breakpoint-down(xxl) {
        .circ-block-wrapper .circ-block {
            width: 320px;
            height: 320px;
            width: 295px;
            height: 295px;

            .circ-text {
                font-size: 32pt; //(59pt * $scale-xl);
                line-height: 1.1em;
            }

        }
    }

    //@include media-breakpoint-down(lg) {
    //    .circ-block-wrapper .circ-block {
    //        width: 280px;
    //        height: 280px;
    //
    //    }
    //    .circ-text {
    //        font-size: 34pt;// (59pt * $scale-lg);
    //        line-height: 1.1em;
    //    }
    //}
    @include media-breakpoint-down(lg) {
        .circ-block-wrapper .circ-block {
            width: 210px;
            height: 210px;

            .circ-text {
                font-size: 22pt;
                line-height: 1.1em;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .circ-block-wrapper .circ-block {
            width: 340px;
            height: 340px;

            .circ-text {
                font-size: 35pt;
                line-height: 1.1em;

            }
        }

    }
    @include media-breakpoint-down(sm) {
        .circ-block-wrapper .circ-block {
            //width: 340px;
            //height: 340px;
            .circ-text {
                font-size: 34pt;
                line-height: 1.1em;
            }
        }

    }


}


.project-video-row {

    video {
        //width:100%;
        //height:100%;
    }

    .video-logo {
        position: relative;

        .video-circles {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .project-circle-text {
            font-size: 59pt;
        }
    }


    .project-circle-text {
        text-align: center;
        font-size: 59pt;
        line-height: 1.1em;
        @extend .fo-questal;
        color: $lime-green;
        font-weight: 400;
        z-index: 100;
    }

    .video-logo {
        width: 530px;
        height: 530px;

        .project-circle-text {
            font-size: 59pt;
        }
    }

    @include media-breakpoint-down(bfl) {
        //        @include projectCircle ( (1320/1830) );

        @include makeVideoCircles(mmToMult(80));
        .video-logo .project-circle-text {
            font-size: 47pt;
        }

        //        @include projectVideoRow ( 700px, 1320px);

    }
    @include media-breakpoint-down(xxl) {

        @include makeVideoCircles(mmToMult(70));
        .video-logo .project-circle-text {
            font-size: 40pt;
        }

        //        @include projectCircle ( (1140/1830) );
        //.project-video-row {
        //    height: (813px * (1140/1830));
        //}
        //        @include projectVideoRow ( 700px, 1140px);
        //        @include projectVideo(1140px);

        //.circ-block-wrapper .circ-block {
        //    width: 320px;
        //    height: 320px;
        //    //width: 260px;
        //    //height: 260px;
        //}
        //.circ-text {
        //    font-size: (59pt * $scale-xl);
        //    line-height: 1.1em;
        //}
    }

    @include media-breakpoint-down(xl) {

        @include makeVideoCircles(mmToMult(65));
        .video-logo .project-circle-text {
            font-size: 37pt;
        }
        .video-logo {
            top: 60px;
        }
        //@include makeVideoCircles ( 1140 );
        //.project-circle-text {
        //    font-size: 36pt;
        //}
        //
        //@include projectCircle ( (960/1830) );
        ////.project-video-row {
        ////    height: (813px * (1140/1830));
        ////}
        //@include projectVideoRow ( 450px, 960px);


        //$vw_xl : 960px;
        //$vs_xl : ($vw_xl/1830);
        //@include projectCircle ( $vs_xl );
        //.project-video-row {
        //    height: (813px * $vs_xl);
        //}
        //@include projectVideo($vw_xl);


        //.circ-block-wrapper .circ-block {
        //    width: 280px;
        //    height: 280px;
        //}
        //.circ-text {
        //    font-size: (59pt * $scale-lg);
        //    line-height: 1.1em;
        //}
    }

    @include media-breakpoint-down(lg) {

        @include makeVideoCircles(mmToMult(55));
        .video-logo .project-circle-text {
            font-size: 32pt;
        }
        .video-logo {
            top: 45px;
        }

        //@include projectCircle ( (720/1830) );
        //.project-video-row {
        //    height: (813px * (720/1830));
        //}
        //@include projectVideo(720px);

        //.circ-block-wrapper .circ-block {
        //    width: 280px;
        //    height: 280px;
        //}
        //.circ-text {
        //    font-size: (59pt * $scale-lg);
        //    line-height: 1.1em;
        //}
    }
    @include media-breakpoint-down(lg) {
        .circ-block-wrapper .circ-block {
            width: 210px;
            height: 210px;
        }
        .circ-text {
            font-size: 28pt;
            line-height: 1.1em;
        }
    }
    @include media-breakpoint-down(md) {
        .circ-block-wrapper .circ-block {
            width: 340px;
            height: 340px;
        }
        .circ-text {
            font-size: 46pt;
            line-height: 1.1em;
        }
    }


}

//.project-card-group {
//    padding: 0 20px;
//}

.project-card-group {
    font-size: 16pt;
    @include media-breakpoint-down(bfl) {
        font-size: 14pt;
    }

    @include media-breakpoint-up(bfl) {
        //    font-size: 14pt;
    }
}

.project-card-group {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;;
    background-color: $navy;
    padding: 40px 50px;
    //        padding-top: 90px;
    padding-bottom: 2em;


}


//.page-projects {


.project-circle-text {
    color: white;
    z-index: 100;
}


.project-card {
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin-bottom: 2em;

    .project-card-inner {

    }
}


.project-info-bar {
    margin: 2.6px 0;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(xxl) {
        flex-direction: column;
        align-items: center;
    }
    @include media-breakpoint-down(lg) {
        flex-direction: row;
        align-items: center;
        //flex-direction: column;
        //align-items:center;
    }

    @include media-breakpoint-down(md) {
        flex-direction: row;
        align-items: center;
    }


    div {
        @extend .fo-sans;
        color: $green;
        font-size: 13.4pt;
        font-weight: 600;
    }

    .customer {
        text-transform: uppercase;
    }

    .location {
        text-transform: uppercase;
        text-align: right;
    }
}

.project-title {
    color: $lime-green;
    @extend .fo-questal;
    text-align: center;
    margin: 20px 0 20px 0;

    font-size: fs(44pt);
    font-size: 2.045em;

    line-height: lh(48pt);
    line-height: 1.08em;

}

img.project-img {
    width: 100%;
    height: auto;
}

div.project-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.project-desc {
    color: white;
    padding: 0 0;
}


//}

@include media-breakpoint-down(bfl) {
    .project-card-group {
        padding: 20px 20px;
    }
    .project-title {
        margin: 0.6em 0;
    }
    .project-card {
        padding: 25px;
        margin-bottom: 1.8em;
    }

}

@include media-breakpoint-down(xxl) {
    .project-card-group {
        padding: 15px 15px;
    }
    .project-title {
        font-size: 1.745em;

    }
}

@include media-breakpoint-down(xl) {
    .project-card-group {


    }
    .project-title {

    }
    .project-card {
        flex: 0 0 50%;
    }
}

@include media-breakpoint-down(lg) {
    .project-card-group {
    }
    .project-title {
        margin: 0.3em 0;
    }
    .project-card {
        flex: 0 0 100%;
    }
}

@include media-breakpoint-down(md) {
    .project-card-group {
        padding: 10px 10px;
    }
    .project-card {
        flex: 0 0 100%;
    }
    .project-title {
        //        2.2em;

    }
}

@include media-breakpoint-down(sm) {
    .project-card-group {
        padding: 5px 5px;
    }
    .project-card {
        flex: 0 0 100%;
    }
    .project-title {
        //        2.2em;

    }
}


#project-map {
    //min-height: 700px;
    background-color: white;
    color: $green;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0px 0px;

}


.menu-bar-vertical {
    display: flex;
    flex-direction: column;
    justify-content: start;
    justify-content: flex-start;
    //justify-content: center;
    align-items: stretch;
    //background-color: $navy;
    //padding-top:90px;
    margin-top: 100px;
    flex: 0 0 300px;
}

.map-edge {
    //display:none;
    flex: 0 1 16%;
    @include media-breakpoint-down(xl) {
        flex: 0 1 40px;
    }
    @include media-breakpoint-down(lg) {
        flex: 0 1 10px;
    }

}

ul.map-location-list {
    list-style: none;
    padding: 0;
}

.map-menu-bar {
    display: flex;
    //  height:240px;
    align-items: stretch;
    justify-content: space-evenly;
    background-color: $navy;
    //grid-gap:5px;
    &.vertical {
        flex-direction: column;
    }

    //padding-bottom:1em;
    //padding-top:1em;


}

.map-menu {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    cursor: pointer;

    background-color: $navy;
    color: $lime-green;


    &:hover {
        color: white;
        background-color: $green;
        opacity: 0.95;
    }

    .vertical & {
        opacity: 1;
    }

    &.active {
        opacity: 1;

        color: white;
        background-color: $green;
        //opacity:0.95;
        //        color: navy;
        //        background-color: white;
    }

    &:first-child {
        padding-top: 1em;
    }

    &:last-child {
        padding-bottom: 1em;
    }
}


.map-inner {
    flex: 0 0 240px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    //  background-color: lightcoral;
    padding: 65px 0;

    .map-menu-bar.vertical & {
        flex-direction: row;
        flex-direction: row-reverse;
        padding: 20px 0;
        flex: 0 1 73%;
        justify-content: flex-end;
        padding-right: 20px;
    }
}


.map-menu-label {
    flex: 0 0 1.5em;
    font-family: "Questrial", sans-serif;
    font-size: fs(42pt);
    line-height: 1.1em;
    //  background-color: lightgoldenrodyellow;
    text-align: center;
    vertical-align: middle;
    text-align: center;
    text-align: left;

    .map-menu-bar.vertical & {
        flex-grow: 1;
        font-size: fs(35pt);

        br {
            display: none;
        }
    }

}


.map-menu-arrow {
    //flex:0 1 40px;
    background-image: url('../images/arrow-lime-left.png');

    &.idx0 {
        background-image: url('../images/markers/map-marker-1st-year.svg');
    }

    &.idx1 {
        background-image: url('../images/markers/map-marker-5-years.svg');
    }

    &.idx2 {
        background-image: url('../images/markers/map-marker-10-years.svg');
    }

    &.idx3 {
        background-image: url('../images/markers/map-marker-15-years.svg');
    }

    &.idx4 {
        background-image: url('../images/markers/map-marker-2020.svg');
    }

    &.idx5 {
        background-image: url('../images/markers/map-marker-only-2020-jobs.svg');
    }

    &.idx6 {
        background-image: url('../images/markers/map-marker-2021.svg');
    }

    transform-origin: center center;
    //    transform: rotateZ(270deg);


    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;

    .map-menu-bar.vertical & {
        //  transform: rotateZ(180deg);
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
}

.map-menu-arrow {
    opacity: 0.6;

    .active & {
        opacity: 1;
    }
}


#projectListingWrapper {
    position: relative;
    position: sticky;

    @media (orientation: landscape) and (max-width: 991.98px) {
        display: none;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }


    width: 100%;;
    height: 2px;
    left: 0px;
    top: 0px;

    //    margin-top:-40px;

    top: 92px;


    //    order:2;
    &.right {
        //left:unset;
        //right:0;
        //order:4;

        #projectListing {
            //left:unset;
            //right:10px;


        }
    }
}

#projectListingOuter {
    padding: 1em 0;
}

#projectListingBackground {
    pointer-events: none;
    width: 100%;
    background-color: transparentize($navy, 0.3);
    padding: 0px;
    //    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.53);

    display: flex;
    justify-content: center;

    .over_wa & {
        justify-content: flex-start;
        //    background-color: red;

    }

    .over_sa & {
        //   background-color: yellow;

    }

    .over_qld & {
        justify-content: flex-end;
        //  background-color: lightgoldenrodyellow;

    }

}

#projectListing {
    //    position: absolute;
    pointer-events: none;

    width: 90%;
    //flex: 0 1 90%;
    top: 30px;
    top: 0px;
    max-height: 450px;
    width: max-content;
    //margin:0 auto;

    //display: grid;
    //grid-template-areas: "a a";
    //gap: 10px;
    //grid-auto-columns: 200px;

    column-width: 220px;
    column-width: 160px;


    //    column-width:250px;

    font-size: 10pt;

    // column-count: 6;
    column-gap: 20px;
    column-count: 7;
    //    column-width:250px;


    .qty10 & {
        column-count: 2;
    }

    .qty20 & {
        column-count: 2;
    }

    .qty30 & {
        column-count: 3;
    }

    .qty40 & {
        column-count: 4;
        column-width: 170px;
    }

    .qty50 & {
        column-count: 4;
    }

    .qty60 & {
        column-count: 5;
    }

    .qty70 & {
        column-count: 5;
        column-width: 140px;
    }

    .qty80 & {
        column-count: 6;
        column-width: 140px;
    }

    .qty90 & {
        column-count: 6;
    }

    .qty100 & {
        column-count: 6;
    }


    div {
        @extend .fo-sans;
        font-size: 13pt;
        //width: max-content;
        line-height: 1.3;

    }


    @include media-breakpoint-down(bfl) {
        //   column-count:6;
        //      top: 30px;
    }
    @include media-breakpoint-down(xxl) {

        //   top: 30px;
    }
    @include media-breakpoint-down(xl) {
        //   column-count:5;
        column-gap: 10px;
        div {
            font-size: 12pt;
        }
        //   top: 30px;
    }
    @include media-breakpoint-down(lg) {
        //   column-count:5;
        column-gap: 8px;
        div {
            font-size: 11pt;
            line-height: 1.2;
        }

        //   top: 30px;
    }
    @include media-breakpoint-down(md) {
        //        column-count:4;
        column-gap: 5px;
        div {
            font-size: 9pt;
            line-height: 1.1;
        }

        //   top: 30px;
    }


    //left: 10px;

    .over_wa & {

        //      left: 760px;
        //@include media-breakpoint-down(bfl) {
        //    left: 540px;
        //}
        //@include media-breakpoint-down(xxl) {
        //    left: 460px;
        //}
        //@include media-breakpoint-down(lg) {
        //    left: 280px;
        //}
    }

    .over_sa & {
        //        left: 480px;
        //@include media-breakpoint-down(bfl) {
        //    left: 240px;
        //}
        //@include media-breakpoint-down(xxl) {
        //    left: 240px;
        //}
        //@include media-breakpoint-down(lg) {
        //    left: 99px;
        //}
    }

    .over_qld & {
        //left: 760px;
        //@include media-breakpoint-down(bfl) {
        //    left: 380px;
        //}
        //@include media-breakpoint-down(xxl) {
        //    left: 380px;
        //}
        //@include media-breakpoint-down(lg) {
        //    left: 140px;
        //}
    }

    //  background-color: transparentize($navy, 0.3);
    color: white;

    padding: 1em;
    display: none;
    padding-left: 3em;

    &.notEmpty {
        display: block;
    }

    //max-height: 27em;
    @include media-breakpoint-down(xl) {
        //  max-height: 37em;
    }

    //overflow-y:auto;

    //scrollbar-width: auto;
    //
    //scrollbar-width: thin;
    //&::-webkit-scrollbar {
    //    width: 1em;
    //
    //}
    //
    //&::-webkit-scrollbar-track {
    //    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //    width:0.8em;
    //
    //}
    //
    //&::-webkit-scrollbar-thumb {
    //    background-color: darkgrey;
    //    background-color: $navy;
    //    outline: 1px solid slategrey;
    //}


    div:first-child {
        //margin-top:0.2em;
    }

    div:last-child {
        //margin-bottom:0.2em;
    }
}

#projectListing.niceScroll {
    &::-webkit-scrollbar {
        width: 1em;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        //        width:0.8em;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        background-color: $navy;
        //        outline: 1px solid slategrey;
    }

}

#projectListing.test {

    scrollbar-width: auto;

    scrollbar-color: $navy transparentize($navy, 0.3);

    &::-webkit-scrollbar {
        //        width: 1em;
    }

    &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        width: 0.8em;
        //
    }

    &::-webkit-scrollbar-thumb {
        //background-color: darkgrey;
        //background-color: $navy;
        //outline: 1px solid slategrey;
    }
}

.mapMarker {
    //    width:30px;
    transform-origin: center center;
    height: 20px;
}

image.mapMarker.hilite {

    transform: scale(80%);
}

.debugging {
    background-color: rgba(213, 195, 96, 0.24);
}

#debugPanel {
    padding: 5px;
    font-size: 10pt;
    font-family: "Courier New";
    position: absolute;
    min-height: 140px;
    width: 1200px;
    background-color: lightgoldenrodyellow;
    box-shadow: 2px 2px 2px lightgray;
}

.marker-body {
    fill: #62dff4;;
}

.hilite .marker-body {
    fill: #ffd91f;
}

.marker-outline {
    fill: white;
}
