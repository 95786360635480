
.video-container {
    /*display:flex;*/
    /*justify-content: center;*/
    /*align-items: stretch;*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;


}


.video-static-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;

}

.video-iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.video-container {
    video,
    #video {
        position: relative;
        left: 0;
        //top: 20px;

    }
}

#video {
    //overflow: hidden;
    //padding-top: 56.25%; /* 16:9 */
    //position: relative;
}

.project-circle-text {
    text-align: center;
    font-size: 59pt;
    line-height: 1.1em;
    @extend .fo-questal;
    color: $lime-green;
    font-weight: 400;
    z-index: 100;
}


.video-bg {
    //background-image: url('../images/video-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.video-logo-wrapper {
    position: relative;;
    height: 0;
    z-index: 10;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.video-circle-alt {
    background-color: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .title {
        font-family: "Questrial", sans-serif;
        text-align: center;
        font-size: 37pt;
        line-height: 1.08em;
        margin-bottom: 0;
    }

    .alt-color {
        color: $lime-green;

    }

    @include media-breakpoint-up(md) {
        display: none !important;
    }
    @include media-breakpoint-down(md) {
        .title {
            font-size: 32pt;
        }
    }
    @include media-breakpoint-down(sm) {
        .title {
            font-size: 8.1vw;
        }
    }
}


/*.gwu-circle-wrapper{*/
/*    position:relative;*/
/*    left:0;*/
/*    top:0;*/
/*    height:0;*/
/*}*/
/*.gwu-circle {*/
/*    !*width:600150px;*!*/
/*    !*height:150px;*!*/
/*}*/


.video-h1 {
    font-family: 'Questrial', sans-serif;
    font-weight: normal;
    font-size: fs(80pt);
    line-height: lh(78pt);
    text-align: center;
}




//section.video {
//
//  .video-circles {
//    transform-origin: center center;
//    transition: all $jump-duration ease-in-out;
//  }
//
//  circle {
//    transition: all $jump-duration ease-in-out;
//  }
//
//  circle.inner-circle {
//    pointer-events: none;
//  }
//
//  &.flash {
//    .video-circles {
//      transform: scale($jump-grow);
//    }
//
//    circle {
//      -webkit-filter: $drop-shadow;
//      filter: $drop-shadow;
//    }
//  }
//
//}



