
.header-image {
    height: 820px;
}

.green-bar {
    background-color: $green;
    height: 15px;
    width: 100%;

    &.outside {
        margin-left: -15px;
        margin-right: -15px;
    }
}


.section-container {
    //    @include make-container(0);
    @extend .container-fluid;
    @extend .container;
    padding-left: 0;
    padding-right: 0;
    //    max-width:1920px;
}

.section-container.contained {
    //    @include make-container(0);
    @extend .container;
    padding-left: 0;
    padding-right: 0;
}


.contained .section-container {
    //@include make-container();
}


.section-row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 auto;
}

.row-image-block {
    display: flex;
    align-items: stretch;
}


.section-row-wrapper {
    //@extend .row;
    //    margin-left: -15px;
    //    margin-right: -15px;
}

/***

  Std Panel sizes

 */

.panel-medium {
    flex: 0 0 665px;

    @include media-breakpoint-down(xl) {
        flex: 0 0 443px;
    }

    @include media-breakpoint-down(md) {
        flex: 1 0 100%;
    }
}


.sub-image-lg {
    img {
        display: none;
    }

    @include media-breakpoint-down(xl) {
        background-image: none;
        img {
            display: block;
        }
    }
}

.sub-image-sm {
    img {
        display: none;
    }

    @include media-breakpoint-down(ms) {
        background-image: none;
        img {
            display: block;
        }
    }
}

.has-sub-image {
    img {
        display: none;

    }
}


.sub-image-md {
    img {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        background-image: none;
        img {
            display: block;
        }
    }
}

.section-image {
    background-repeat: no-repeat;
}


.is-block.section-image {
    display: none;
}

@include media-breakpoint-down(lg) {
    .is-flex.section-image {
        display: none;
    }
    .is-block.section-image {
        display: block;
    }
}

.panel-md-full {
    @include media-breakpoint-down(lg) {
        flex: 1 1 auto;
    }
}

.panel-fill {
    @extend .flex-fill;
}

.panel-380 {
    flex: 0 0 380px;
}

.panel-350 {
    flex: 0 0 350px;
}

.bgi-anc-left {
    background-position-x: left !important;
}

.bgi-anc-right {
    background-position-x: right !important;
}


.text-panel-margin, {
    padding: 60px 80px;
}

.text-panel-padding {

    padding: 60px 80px;

    p + p {
        margin: 1em 0;
    }

    @include media-breakpoint-only(xxl) {


        padding: (60px * $scale-2xl) (80px * $scale-2xl);

        .size-1 {
            font-size: (37.25pt * $scale-2xl) !important;
        }
        .size-3 {
            font-size: (29.8pt * $scale-2xl) !important;
        }
        .title {
            margin-bottom: 0.4em;
        }
        p {
            font-size: (18.625pt * $scale-2xl) !important;
            margin: 0.8em 0;
        }
        p + p {
            margin: 0.8em 0;
        }

        #employment #hfy-text {
            //     flex: 0 0 (588px * $scale-2xl) !important;
        }
    }
    @include media-breakpoint-only(xl) {
        padding: (60px * $scale-xl) (80px * $scale-xl);
    }
    @include media-breakpoint-only(lg) {
        padding: (60px * $scale-lg) (80px * $scale-lg);
    }
    @include media-breakpoint-only(md) {
        padding: 32px 36px;
    }
    @include media-breakpoint-only(sm) {
        padding: 24px 32px;
    }
    @include media-breakpoint-only(xs) {
        padding: 24px 32px;
    }

}


@include media-breakpoint-down(md) {
    .text-panel-margin,
    .text-panel {
        padding: 40px 60px;
    }
}


.image-row {
    height: 813px;

    @include media-breakpoint-down(bfl) {
        height: 813px * $scale-2xl;
        height: 587px;
    }
    @include media-breakpoint-down(xxl) {
        height: 813px * $scale-xl;
        height: 507px;
    }
    @include media-breakpoint-down(xl) {
        height: 813px * $scale-lg;
        height: 427px;
    }
    @include media-breakpoint-down(lg) {
        height: 813px * $scale-md;
        height: 400px;
    }
    @include media-breakpoint-down(md) {
        height: 813px * $scale-md;
        height: 390px;
    }
    @include media-breakpoint-down(sm) {
        height: 350px;
        height: 86.4vw;
    }

}


.banner-row {
    height: 813px;
    max-height: 72vw;
}

.page-video {
    width: 100%;
    height: auto !important;
}


.video-logo {


    position: relative;;
    height: 520px;
    width: 520px;


    left: 130px;
    top: 190px;

    //left:6.785vw;

    //background-image: url('../images/double-circle.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .video-circles {
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;

        //width: 530px;
        //height: 530px;
        width: 100%;
        height: 100%;

        //width: 41.534vw;
        //height: 41.534vw;


        svg {
            height: 100%;
            width: 100%;
        }


        circle.outer-circle {
            stroke: $green;
            fill: transparent;
            stroke-width: 3px;
        }

        circle.inner-circle {
            fill: $green;
        }

        .growing & {
            //      box-shadow: 4px 4px 4px black;
        }

    }


}


.video-row {
    height: 814px;
    position: relative;
}

@include media-breakpoint-down(bfl) {
    .video-row {
        height: 734px;
        height: 587px;
    }
}

@include media-breakpoint-down(xxl) {
    .video-row {
        height: 630px;
        height: 507px;
    }
    .page-video {
        width: 1400px;
    }
    .video-logo {
        //transform: scale(80%);
        //left: 30px;
        //top: 120px;
    }
}

@include media-breakpoint-down(xl) {
    .video-row {
        height: 600px;
        height: 675px;
        height: 536px;
        height: 427px;
    }

    .video-logo {
        //transform: scale(80%);
        //width:416px;
        //height:416px;
        //left: 30px;
        //top: 100px;
    }
}

@include media-breakpoint-down(lg) {
    .video-row {
        height: 558px;
        height: 400px;
    }
    .video-logo {
        //transform: scale(40%);
        //left: 15px;
        //top: 15px;
        //.project-circle-text {
        //    font-size:26pt;
        //}
    }
}

@include media-breakpoint-down(md) {
    .video-row {
        height: 400px;
        height: 432px;
        height: 390px;
    }
    .page-video {
        //        width:
    }
    .video-logo {
        /*
        transform: scale(80%);
        left: 15px;
        top: 15px;
        */

    }
}

@include media-breakpoint-down(sm) {
    .video-row {
        //width: 100%;
        //height: 42vw;
        height: 350px;
    }
    .video-logo {
        //
        //transform: scale(80%);
        //left: 10%;

    }
}


//.section-row {
//
//    .section-text {
//        position: relative;
//
//        flex: 0 0 30.6vw;
//
//        .text-panel-padding {
//            padding: 3.69vw 4.92vw !important;
//        }
//
//        .text {
//            .size-1 {
//                font-size: 37.25pt;
//                font-size: 2.29vw;
//            }
//
//            p {
//                font-size: 18.625pt;
//                font-size: 1.175vw !important;
//            }
//        }
//    }
//}


.text-panel {
    padding: 60px 80px;

    p {
        //        line-height: 1.22em;
    }
}

@include media-breakpoint-only(xxl) {

    #employment #hfy-text {
        //   flex: 0 0 (588px * $scale-2xl) !important;
    }
    .panel-medium {
        &.circ-block-panel {
            padding: (40px * $scale-2xl) (10px * $scale-2xl);
        }

        //  flex: 0 0 (665px * $scale-2xl) !important;

        .circ-block-wrapper .circ-block {
            width: (524px * $scale-2xl) !important;
            height: (524px * $scale-2xl) !important;
        }

        .circ-text {
            font-size: (37.25pt * $scale-2xl) !important;
        }
    }

    //    .text-panel-padding,
    .text-panel-padding {
        //.size-1 {
        //    font-size: (37.25pt * $scale-2xl) !important;
        //}
    }
    .text-panel {
        padding: 60px 80px;

        p + p {
            margin: 1em 0;
        }

        padding: (60px * $scale-2xl) (80px * $scale-2xl);

        .size-1 {
            font-size: (37.25pt * $scale-2xl) !important;
        }

        .title {
            margin-bottom: 0.4em;
        }

        p {
            //            font-size: (18.625pt * $scale-2xl) !important;
            font-size: 16pt !important;
            margin: 0.8em 0;
        }

        //        .text-panel-padding p + p,
        .text-panel p + p {
            margin: 0.8em 0;
        }

        #employment #hfy-text {
            //       flex: 0 0 (588px * $scale-2xl) !important;
        }
    }

}

@include media-breakpoint-only(xl) {

    //.panel-medium {
    //    &.circ-block-panel {
    //        padding: (40px * $scale-xl) (10px * $scale-xl);
    //    }
    //    .circ-block-wrapper .circ-block {
    //        width: (524px * $scale-xl) !important;
    //        height: (524px * $scale-xl) !important;
    //    }
    //    .circ-text {
    //        font-size: (37.25pt * $scale-xl) !important;
    //    }
    //}
    .text-panel-padding {
        .size-1 {
            font-size: (37.25pt * $scale-xl) !important;
        }
    }

    .text-panel {
        padding: 60px 80px;

        p + p {
            margin: 1em 0;
        }

        padding: (60px * $scale-xl) (80px * $scale-xl);

        .size-1 {
            font-size: (37.25pt * $scale-xl) !important;
        }

        .title {
            margin-bottom: 0.4em;
        }

        p {
            font-size: 16pt !important;
            //font-size: (18.625pt * $scale-xl) !important;
            margin: 0.8em 0;
        }

        p + p {
            margin: 0.8em 0;
        }

        #employment #hfy-text {
            //        flex: 0 0 (588px * $scale-xl) !important;
        }
    }

}

@include media-breakpoint-only(lg) {
    $adjust-lg: 1;
    #emp-1 {
        display: none;
    }
    #employment #hfy-text {
        //  flex: 0 0 (588px * $scale-lg) !important;
    }
    //.panel-medium {
    //    &.circ-block-panel {
    //        padding: (40px * $scale-lg) (10px * $scale-lg);
    //    }
    //    .circ-block-wrapper .circ-block {
    //        width: (524px * $scale-lg) !important;
    //        height: (524px * $scale-lg) !important;
    //    }
    //    .circ-text {
    //        font-size: (37.25pt * $scale-lg) !important;
    //    }
    //}
    .text-panel-padding {
        .size-1 {
            font-size: 20pt !important; //(37.25pt * $scale-lg * $adjust-lg) !important;
        }
    }
    .text-panel {
        p {
            //font-size: 12pt !important; //(18.625pt * $scale-lg * $adjust-lg) !important;
            margin: 0.8em 0;
        }

        p + p {
            margin: 1em 0;
        }

        a {
            //font-size: 12pt !important; //(18.625pt * $scale-lg * $adjust-lg) !important;
        }

        padding: (60px * $scale-lg) (80px * $scale-lg * $adjust-lg);

        .size-1 {
            font-size: 20pt !important; //(37.25pt * $scale-lg * $adjust-lg) !important;
        }

        .title {
            margin-bottom: 0.4em;
        }

        #employment #hfy-text {
            //         flex: 0 0 (588px * $scale-lg) !important;
        }
    }

}

//@include media-breakpoint-only(lg) {
//
//    #employment #hfy-text {
//        flex: 0 0 (588px * $scale-lg) !important;
//    }
//    .panel-medium {
//        &.circ-block-panel {
//            padding: (40px * $scale-lg) (10px * $scale-lg);
//        }
//
//        flex: 0 0 (665px * $scale-lg) !important;
//
//        .circ-block-wrapper .circ-block {
//            width: (524px * $scale-lg) !important;
//            height: (524px * $scale-lg) !important;
//        }
//
//        .circ-text {
//            font-size: (37.25pt * $scale-lg) !important;
//        }
//    }
//    .text-panel-padding {
//        p + p {
//            margin: 1em 0  !important;
//        }
//        padding: (60px * $scale-lg) (80px * $scale-lg);
//
//        .size-1 {
//            font-size: (37.25pt * $scale-lg) !important;
//        }
//
//        .title {
//            margin-bottom: 0.4em !important;
//        }
//
//        p {
//            font-size: (18.625pt * $scale-lg) !important;
//            margin: 0.8em 0 !important;
//        }
//
//        .text-panel-padding p + p {
//            margin: 0.8em 0 !important;
//        }
//
//        #employment #hfy-text {
//            flex: 0 0 (588px * $scale-lg) !important;
//        }
//    }
//
//}

@include media-breakpoint-only(md) {
    #emp-1 {
        display: none;
    }
    #emp-2 {
        display: block;
    }
    #employment #hfy-text {
        //        flex: 0 0 (588px * $scale-md) !important;
    }
    .panel-medium {
        &.circ-block-panel {
            padding: (40px * $scale-md) (10px * $scale-md);
        }

        //        flex: 0 0 (665px * $scale-md) !important;

        .circ-block-wrapper .circ-block {
            width: (524px * $scale-md) !important;
            height: (524px * $scale-md) !important;
        }

        .circ-text {
            font-size: (37.25pt * $scale-md) !important;
        }
    }

    .text-panel {

        padding: (60px * $scale-md) (80px * $scale-md);

        p {
            //font-size: 12pt !important; //(18.625pt * $scale-lg * $adjust-lg) !important;
            //font-size: 14pt !important; //(18.625pt * $scale-lg * $adjust-lg) !important;
            margin: 0.8em 0;
        }

        p + p {
            margin: 1em 0 !important;
        }


        .size-1 {
            font-size: 18pt !important;
            font-size: 20pt !important;
        }

        .title {
            margin-bottom: 0.4em !important;
        }


        .text-panel p + p {
            margin: 0.8em 0 !important;
        }

        #employment #hfy-text {
            //            flex: 0 0 (588px * $scale-md) !important;
        }
    }

}

@include media-breakpoint-down(md) {
    .panel-medium {
        &.circ-block-panel {
            //            padding: (40px * $scale-md) (10px * $scale-md);
        }

        .circ-block-wrapper .circ-block {
            //width: (524px * $scale-md) !important;
            //height: (524px * $scale-md) !important;
        }

        .circ-text {
            //            font-size: (37.25pt * $scale-md) !important;
        }
    }

    .text-panel {
        padding: (60px * $scale-lg) (80px * $scale-md);

        p {
            //font-size: 12pt !important; //(18.625pt * $scale-lg * $adjust-lg) !important;
            //margin: 0.8em 0;
        }

        p + p {
            //            margin: 1em 0 !important;
        }

        .size-1 {
            //            font-size: 18pt !important;
        }

        .title {
            //            margin-bottom: 0.4em !important;
        }

        .text-panel p + p {
            //            margin: 0.8em 0 !important;
        }

        #employment #hfy-text {
            //            flex: 0 0 (588px * $scale-md) !important;
        }
    }
}

@include media-breakpoint-only(xxl) {
    //@include media-breakpoint-only(xl) {
    //    padding: (60px * $scale-xl) (80px * $scale-xl);
    //}
    //@include media-breakpoint-only(lg) {
    //    padding: (60px * $scale-lg) (80px * $scale-lg);
    //}
    //@include media-breakpoint-only(md) {
    //    padding: 32px 36px;
    //}
    //@include media-breakpoint-only(sm) {
    //    padding: 24px 32px;
    //}
    //@include media-breakpoint-only(xs) {
    //    padding: 24px 32px;
    //}

}

