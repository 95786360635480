$menu-item-padding-w: 34px;
//$menu-item-padding-h: 28px;
$menubar-left: 220px - $menu-item-padding-w;
$menu-margin-bottom: 18px;
$menu-item-padding-h: $menu-margin-bottom;
$margin-pad-left: 194px;


.menu-font {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 600;
    font-size: fs(19pt);

}

body {
    overflow-y: auto;
}

//$mobile-bp-down : lg;
//$mobile-bp-up : xl;
$mobile-bp-down: md;
$mobile-bp-up: lg;

.navbar-expand-when {
    //    @extend .navbar-expand-xl;
    @extend .navbar-expand-lg;
}

.sticky-trans {
    transition: all;
    transition-duration: 200ms;
}

.inflatableHeader {
    display: none;
}

.stickittome {

    .inflatableHeader {
        display: block;
        height: 111px;
    }

    header {
        position: fixed !important;
        top: -2px;
        width: 100%;
    }
}

.shrinkingHeader.stickittome {
    header {
        .header-logo-spacer {
        }

        .logo-wrapper {
            @extend .sticky-trans;

            .logo {
                img {
                    @extend .sticky-trans;
                    width: 87px;
                }
            }

            .hdr-logo-text {
                @extend .sticky-trans;
                font-size: 12pt;
                font-size: 14px;
                margin-bottom: 1em;
                width: 50px;
            }
        }
    }
}

header {

    background-color: white;
    //    position: sticky;

    top: 0px;
    padding-top: 12px;

    z-index: 100;


    .container-fluidish {
        justify-content: center;
        align-items: flex-end;
        @include media-breakpoint-down($mobile-bp-down) {
            justify-content: space-between;
        }
    }

    .navbar .container {
        height: 112px;
        display: flex;
        align-items: flex-end !important;
        justify-content: flex-start;
    }

    .hdr-container {
        height: 112px;
        display: flex;
        align-items: flex-end !important;
        justify-content: flex-start;
    }

    .navbar {
        padding: 0;
    }

    .navbar-collapse {
        flex-grow: 0;

        &.show {

        }

    }

    .navbar-nav {
        .nav-item {
            @extend .menu-font;
            background-color: white;
            cursor: pointer;
            transition: background-color 50ms linear;

            .nav-item-box {
                display: flex;
                justify-content: space-between; //flex-start;
                align-items: baseline;

            }

            .nav-link {
                white-space: nowrap;
                color: lighten($green, 6);
                padding: $menu-margin-bottom $menu-item-padding-w;


                &:hover, &.hovered, &.show {
                    transition: background-color 50ms linear;
                    background-color: $navy;

                    a {
                        color: white;
                    }
                }


            }

            .dropdown-menu {
                background-color: $navy;

                @include media-breakpoint-down($mobile-bp-down) { // xl
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .dropdown-item {
                    height: 56px;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-weight: 300;
                    font-size: 18px;
                    color: white;
                    line-height: 1.9em;
                    background-color: transparent;;
                    padding: 0.25em 0.75em;

                    @include media-breakpoint-down($mobile-bp-down) { // xl
                        height: auto;
                        padding: 0.15em 0.45em;
                        color: $navy;
                        font-size: 18pt;
                        line-height: 1;
                        padding-left: 1.5em;
                    }
                }


                @include media-breakpoint-down(lg) {
                    position: relative;
                    width: 100% !important;
                }

                .dropdown-item:hover, .dropdown-item:focus {
                    color: $lime-green;
                    background-color: transparent;
                }
            }
        }
    }

    .header-left-spacer {
        display: none;
        //flex: 1 0 20px;
        //@include media-breakpoint-down(xl) {
        //    flex: 0 0 20px;
        //}
    }

    .header-logo-spacer {
        flex: 1 0 40px;
        flex: 0 0 40px;
        height: 20px;

        @include media-breakpoint-down(xl) {
            flex: 1 0 auto;
        }
    }

    .header-mid-spacer {
        flex: 0 0 120px;
        height: 20px;
    }

    .header-right-spacer {
        display: none;
        //flex: 1 0 20px;
        //
        //@include media-breakpoint-down(xl) {
        //    flex: 0 0 20px;
        //    display: none;
        //}
    }


}

.nav-link-expander-wrapper {
    position: relative;;
    top: 0;
    lefT: 0;
    height: 0;
    width: 0;
}

.nav-link-expander {
    position: relative;
    left: 0px;
    width: 14pt;
    height: 14pt;
    background-image: url(../images/chevron-down-solid.svg);
    background-repeat: no-repeat;
    display: inline-block;


    &[aria-expanded="true"] {
        background-image: url(../images/chevron-up-solid.svg);
        //transform-origin: 0.45em 0.5em;
        //transform: rotateZ(180deg);
    }

    @include media-breakpoint-up($mobile-bp-up) {
        display: none;
    }

}


#menu-container {
    align-items: flex-end;
    justify-content: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.logo-wrapper {
    @extend .sticky-trans;

    align-self: stretch;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;

    flex: 0 0 132px;
    flex: 0 0 263px;

    .logo {
        $logo-margin-bottom: ($menu-margin-bottom - 5px);
        margin-bottom: $logo-margin-bottom;

        img {
            @extend .sticky-trans;

            width: 132px;

        }
    }

    .hdr-logo-text {
        @extend .sticky-trans;

        font-family: 'Questrial', sans-serif;
        font-size: fs(17pt);
        //        line-height: lh(20pt);
        line-height: 1.1762em;
        margin-left: 25px;
        margin-bottom: 27px;
        margin-bottom: 1.7em;
        margin-left: 1.5em;
        @include media-breakpoint-down(xl) {
            //            display:none;
        }

    }
}


@include media-breakpoint-down(sm) {
    .logo-wrapper {
        flex: 0 0 132px;

        .logo {
            img {
                width: 82px;
            }
        }

        .hdr-logo-text {
            font-size: 12px;
            //            margin-bottom: 1.65em;
            margin-left: 1em;
        }
    }
}


.menu-bar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .menu-item {
        @extend .menu-font;
        background-color: white;
        padding: $menu-margin-bottom $menu-item-padding-w;
        color: $lime-green;
        cursor: pointer;


        transition: background-color 50ms linear;

        a {
            padding: 0;
        }

        &:hover, &.hovered, &.show {
            transition: background-color 50ms linear;
            background-color: #024f5a;
            background-color: $navy;

            a {
                color: white;
            }
        }

    }


    a {
        text-decoration: none;
        color: $green;
        color: lighten($green, 6);
        //    color: $lime-green;
        &:hover {
            //color: darken($green,5);
        }
    }

}

ul.dropdown-menu {
    min-width: 192px;
    //inset: -2px auto auto 0px !important;
    border: 0;
    background-color: $navy;

    .dropdown-item:hover, .dropdown-item:focus {
        color: $lime-green;
        background-color: transparent;
    }

    li {
        height: 56px;

        &:hover {
            //      background-color: red;
        }

        a {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: 18px;
            color: white;
            line-height: 1.9em;
            background-color: transparent;;

            padding: 0.25em 0.75em;


        }

    }

    border-radius: 0;
    padding-bottom: 0.5em;
    padding-top: 1em;
}

.nav-sub-wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: 0;
    min-width: 100%;
    background-color: $navy;

    .nav-sub {
        position: absolute;
        top: -10px;
        left: 0;
        width: 250px;

        background-color: $navy;
        //
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.39);
        color: white;
        z-index: 1000;

        //padding: 15px $menu-item-padding-w;
        padding-top: 8px;
        padding-bottom: 16px;

        .nav-sub-item {

            padding: 0.5em $menu-item-padding-w;
            //line-height: 2.2em;
            color: white;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;

            a {
                color: white;
                cursor: pointer;
            }
        }
    }
}


.offcanvas {
    background-color: $navy-trans;
    color: white;
    border-right: 2px inset $navy;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.44);

    .offcanvas-body {
        margin-top: 0;
    }

    h1 {
        font-family: 'Questrial', sans-serif;
        font-weight: normal;
        font-size: fs(50pt);
        line-height: lh(55pt);
        letter-spacing: ls(15);
        color: $lime-green;

    }

    .side-project {
        margin-top: 1.5em;

        .side-heading {
            font-family: 'Questrial', sans-serif;
            font-weight: normal;
            font-size: fs(25pt);
            line-height: lh(20pt);
            color: $lime-green;
        }

        .side-body {
            margin-top: 0.5em;

        }
    }

}

.navbar-toggler {

    margin-bottom: 1.5vw;
    margin-right: 20px;
    padding: 0.25rem 0.75rem;
    font-size: 2rem;
    border: 1px solid transparent;

    &:focus {
        //box-shadow: 0 0 0 0.15rem lightgrey;
    }


    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        color: black;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        //margin-right: 20px;
    }
}


@include media-breakpoint-down(bfl) {
    header .navbar-nav .nav-item .nav-link {
        padding-left: 18px;
        padding-right: 18px;
    }
}

@include media-breakpoint-down(xxl) {
    header .navbar-nav .nav-item .nav-link {
        padding-left: 18px;
        padding-right: 18px;
    }
    .menu-font {
        font-size: 12pt;
    }
}

@include media-breakpoint-down(xl) {
    //header .navbar-nav .nav-item .nav-link {
    //    font-size: 11pt;
    //    padding: 8px 10px;
    //}
}

@include media-breakpoint-down(xl) {
    .logo-wrapper {
        flex: 0 0 110px;

        .logo {
            img {
                width: 110px;
            }
        }

        .hdr-logo-text {
            font-size: 11px;
            font-size: 14px;
            //            margin-bottom: 1.65em;
            margin-left: 0.75em;
        }
    }
    header .header-left-spacer {
        flex: 0 1 10px;
        display: block;
    }

    header .navbar-nav .nav-item .nav-link {
        font-size: 12pt;
        padding: 14px 12px;
    }

    //header .navbar-nav .nav-item .nav-link {
    //    font-size: 11pt;
    //    padding: 8px 10px;
    //}

    header .navbar-nav .nav-item .dropdown-menu .dropdown-item {
        height: 38px;
        font-size: 12pt;
        color: white;
        line-height: 1.9;
        background-color: transparent;
        padding: 0.25em 0.75em;
    }
}

@include media-breakpoint-down(lg) {
    .navbar-toggler {
        margin-bottom: 1em;
        margin-right: 1em;
        font-size: 1em;

        .navbar-toggler-icon {
        }
    }
}

@include media-breakpoint-down($mobile-bp-down) {
    header .navbar-nav .nav-item {
        .nav-link:hover {
            background-color: transparent;
        }
    }

    header .navbar-nav .nav-item .nav-link {
        width: 200px;
        width: 300px;
        padding: 0.75em 0;
        font-size: 16pt;

        width: 100%;
        max-width: 90%;
        padding: 0.35em 0.5em;

        &:hover {
            //background-color: $green;
            //color: $lime-green;
        }

        &.show {
            background-color: white;
        }

        display: inline-block;
    }

    header .navbar-nav .nav-item .dropdown-menu {
        border-radius: 0;
        position: absolute;
        left: 200px;
        top: 0px;
        margin: 0 1px;
        border: none;


        position: relative;
        left: 0px;
        background-color: white;

        border-bottom: 1px solid transparentize($green, 0.4);

        .dropdown-item {
            color: $navy;
            font-size: 14pt;
            line-height: 1.3;
            font-weight: 400;

            border-top: 1px solid $green;

            &:hover {
                color: black;
            }
        }


    }

    header .navbar-nav .nav-item.dropdown.expanded {
        background-color: $navy;
        color: white;

        .dropdown-menu {
            background-color: $navy;

            .dropdown-item {
                color: white;

            }
        }
    }


    .navbar-toggler {
        margin-bottom: 1em;
        margin-right: 1em;
        font-size: 1.4rem;
        font-size: 2rem;

        .navbar-toggler-icon {
        }
    }
    .menu-font {
        font-size: 12pt;
    }
}


@include media-breakpoint-down(md) {

    .navbar-toggler {
        //margin-bottom: 10px;
        //margin-right: 20px;
        font-size: 1em !important;

        .navbar-toggler-icon {

        }
    }
}

@include media-breakpoint-down(md) {
    header .container-fluid {
        padding: 0;
    }
    .navbar-toggler {
        //margin-bottom: 32px;
        //margin-right: 10px;
        font-size: 1em;
    }
}

@include media-breakpoint-down(sm) {
    header .container-fluid {
        padding: 0;
    }
    .navbar-toggler {
        //margin-bottom: 13px;
        //margin-right: -4px;
        font-size: 1em;
    }
}

.mm-main-wrapper {
    display: flex;
    justify-content: space-between;

}


header {
    .accordion-item {
        border: none;
    }

    .accordion-button {
        background-color: white;

        padding: 10px 10px;

    }

    .accordion-body {
        padding: 0;
        padding-bottom: 5px;
        background-color: $navy;

        .dropdown-item {
            padding: 7px 14px;
            background-color: $navy;
            color: white;
            font-size: 11pt;

            border-top: 1px solid transparentize($green, 0.4);

        }

        border-bottom: 1px solid transparentize($green, 0.4);


    }

    .accordion-button:not(.collapsed) {
        color: $green;
        background-color: $navy;
        //        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    }

    .accordion-button:focus {
        z-index: 3;
        border: none;
        outline: 0;
        box-shadow: none;;
    }
}

.acc-link-wrapper {
    position: relative;
    height: 0;
    width: 0;
    left: 0;
    top: 0;

    .acc-link {
        @extend .fo-sans;
        position: relative;
        white-space: nowrap;
        font-weight: 400;
        color: $green;

        //height: 52px;
        width: min-content;
        top: 0px;
        //        background-color: red;
        padding: 5px;
        z-index: 20;

        width: 200px;
        display: block;

        font-size: 12pt;
        padding: 9px 12px;

    }
}

.acc-link-container {
    width: 100%;
    //background-color: $navy;
    color: $green;

    .acc-link {
        @extend .fo-sans;
        position: relative;
        white-space: nowrap;
        font-weight: 400;
        color: $green;

        //height: 52px;
        width: min-content;
        top: 0px;
        //        background-color: red;
        padding: 5px;
        z-index: 20;

        width: 200px;
        display: block;

        font-size: 12pt;
        padding: 9px 12px;

    }


}
