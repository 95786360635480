$subject-half-gap: 6px;
$subject-gap: 12px;


#joinus-2 {
    min-height: 470px;

    @include media-breakpoint-down(lg) {
        min-height: 360px;
    }


}

#joinus-2-text-a {

    br {
        @include media-breakpoint-down(bfl) {
            display: none;
        }
    }

}

#joinus-2-text-b {
    .read-more br {
        @include media-breakpoint-down(bfl) {
            display: none;
        }
    }
}

#joinus-2a {
    //    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: 0px;


}

@include media-breakpoint-down(lg) {
    #joinus-2a {
        order: 3;
        display: block;
        height: 300px;
    }
    #joinus-2-text-a {
        //        order:1;
    }
    #joinus-2-text-b {
        //        order:3;
    }
}


#joinus-img {
    //    height: 813px;
    //    height: 43vw;


}

#joinus-0.section-image {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -80px;
    background-position-x: 0px;

    width: 100%;
    background-size: cover;
    background-position-y: -110px;
    background-position-x: center;

    @include media-breakpoint-down(bfl) {
        background-size: 1390px;
        background-position-y: -100px;
        background-position-x: center;
    }

    @include media-breakpoint-down(xxl) {
        background-size: 1230px;
        background-position-y: -100px;
        background-position-x: center;
    }
    @include media-breakpoint-down(xl) {
        background-size: 1230px;
        background-position-y: -100px;
        background-position-x: center;
    }
    @include media-breakpoint-down(lg) {
        background-size: 126%;
        background-position-y: 0%;
        background-position-x: center;
    }
    @include media-breakpoint-down(md) {
        background-size: 162%;
        background-position-y: -16%;
        background-position-x: center;
    }
    @include media-breakpoint-down(sm) {
        background-size: 148%;
        background-position-y: 65%;
        background-position-x: center;
    }
}

#joinus-1 {
    height: 240px;
    background-color: $navy;

    .stat {
        svg {
            text {
                stroke: white;
                font-size: fs(24pt);
                line-height: lh(27pt);

                font-weight: 400;

            }
        }

        .std-circle {
            stroke-width: 4px;

        }
    }

}


#joinus-3 {
    height: 600px;
}

#joinus-3a {


    //flex: 1 0 50%;
    background-size: 560px;
    background-position-y: 0px;
    background-position-x: center;
    background-repeat: no-repeat;

    background-image: url("../images/joinus-3a.jpg");

    @include media-breakpoint-down(xl) {
    }

    @include media-breakpoint-down(bfl) {

    }
    @include media-breakpoint-down(xxl) {
        background-size: 610px;
        background-position-y: 0px;
        background-position-x: -190px;
    }
    @include media-breakpoint-down(xl) {
    }
    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-down(md) {
        height: 530px;
        background-size: cover;
        background-position-y: 0;
        background-position-x: 80%;

        height: 530px;
        background-size: cover;
        background-position-y: -30px;
        background-position-x: 0;
    }
    @include media-breakpoint-down(sm) {
        height: 530px;
        background-size: cover;
        background-position-y: 0;
        background-position-x: 80%;
    }

}


#joinus-3a-alt {
    //    width: 100%;
    background-size: 560px;
    background-position-y: 0px;
    background-position-x: center;
    background-repeat: no-repeat;

    background-image: url("../images/joinus-3a.jpg");


    display: none;

    @include media-breakpoint-down(xl) {
    }

    @include media-breakpoint-down(bfl) {

    }
    @include media-breakpoint-down(xxl) {
        background-size: 610px;
        background-position-y: 0px;
        background-position-x: -190px;
    }
    @include media-breakpoint-down(xl) {
        display: none;
        height: 370px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 0;
        background-position-x: center;
    }
    @include media-breakpoint-down(lg) {
        display: block;
        height: 315px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 0;
        background-position-x: center;
    }
    @include media-breakpoint-down(md) {
        display: none;
        height: 315px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 0;
        background-position-x: center;

    }
    @include media-breakpoint-down(sm) {
        display: none;
    }


}


#joinus-2-text-a {
    .big-panel-text {
        @include media-breakpoint-only(md) {
            font-size: 25px !important;
        }
    }
}

#joinus-6a {
    background-size: 971px;
    background-position-y: -23px;
    background-position-x: center;
    background-repeat: no-repeat;

    background-image: url("../images/joinus-4a.jpg");

    .webp & {
        background-image: url("../images/joinus-4a.webp");
    }

    @include media-breakpoint-down(bfl) {
        //min-height:380px;
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-only(lg) {
        background-size: 881px;
        background-position-y: -13px;
        background-position-x: center;

        background-size: 701px;
        background-position-y: -13px;
        background-position-x: center;
    }
    @include media-breakpoint-down(lg) {
        height: 470px;
    }
    @include media-breakpoint-down(md) {
        height: 400px;
    }


}

#joinus-6 {
    min-height: 360px;
    @include media-breakpoint-only(lg) {
        min-height: 281px;

    }
    @include media-breakpoint-down(lg) {

    }
}

@include media-breakpoint-only(lg) {
    #joinus-6-text {
        order: 2;

    }
    #joinus-6a {
        order: 1;
    }
}

@include media-breakpoint-down(lg) {
    #joinus-6-text {
        order: 1;

    }
    #joinus-6a {
        order: 2;
    }
}


#joinus-7a {
    flex: 0 0 calc(50% + 6px);

    @include media-breakpoint-down(lg) {
        flex: 0 0 auto;
        width: 100%;
        height: 520px;
    }

    background-size: 971px;
    background-position-y: -23px;
    background-position-x: 0px;
    background-repeat: no-repeat;

    min-height: 390px;

    @include media-breakpoint-down(xl) {
        background-size: 890px;
        background-position-y: 0;
        background-position-x: -130px;
        background-repeat: no-repeat;
    }

    @include media-breakpoint-down(bfl) {
        background-size: 881px;
        background-position-y: 0;
        background-position-x: -70px;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 902px;
        background-position-y: 0;
        background-position-x: -270px;
    }
    @include media-breakpoint-down(xl) {
        background-size: 902px;
        background-position-y: 0;
        background-position-x: -270px;
        background-size: 682px;
        background-position-y: 0;
        background-position-x: -180px;

        min-height: 280px;
        background-size: 662px;
        background-position-y: -40px;
        background-position-x: -170px;
    }
    @include media-breakpoint-down(lg) {
        background-size: 902px;
        background-position-y: 0;
        background-position-x: -70px;


    }
    @include media-breakpoint-down(md) {
        background-size: 902px;
        background-position-y: 0;
        background-position-x: -280px;
    }
    @include media-breakpoint-down(sm) {
        height: 106vw;
        background-size: 190%;
        background-position-y: 0;
        background-position-x: 80%;
    }


}

#joinus-7-right {
    flex: 0 0 calc(50% - 6px);

    @include media-breakpoint-down(lg) {
        flex: 0 0 auto;
        width: 100%;
    }

    @include media-breakpoint-down(lg) {
        flex: 0 0 auto;
        width: 100%;
        height: 520px;
    }

    display: flex;
}

#joinus-7-text {

    @include media-breakpoint-up(xl) {
        flex: 0 0 50%;
    }


    div.text {
        //padding-right:15px;
    }
}


#joinus-7b {
    flex: 0 0 50%;
    // flex: 1 0 350px;
    background-repeat: no-repeat;

    background-size: 737px;
    background-position-y: -12px;
    background-position-x: -136px;

    @include media-breakpoint-only(lg) {
        display: none;
    }
    @include media-breakpoint-down(bfl) {
        background-size: 497px;
        background-position-y: -12px;
        background-position-x: -106px;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 497px;
        background-position-y: -12px;
        background-position-x: -106px;


    }
    @include media-breakpoint-down(xl) {
        background-size: 497px;
        background-position-y: -12px;
        background-position-x: -106px;
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }
    @include media-breakpoint-down(md) {
    }


}

@include media-breakpoint-down(lg) {

    #joinus-7-right {
        height: auto;

    }
    #joinus-7b {
        flex: 0 0 auto;
        width: 100%;
        height: 580px;
        background-size: cover;
        background-position-y: 0;
        background-position-x: right;
    }

}

@media (max-width: 575.98px) {
    #joinus-7b {
        background-position-x: -121px;
    }
}


.section-row#joinus-env {
    //   background-color: $navy;
    height: 607px;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    position: relative;

    .env-info-panel {
        flex: 0 0 665px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;
        background-color: $navy;
    }

}

#joinus-info-panel {
    //    flex: 0 0 665px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background-color: $navy;
}

.joinus-text {
    font-family: 'Questrial', sans-serif;
    font-weight: normal;
    font-size: 53pt;
    line-height: 1.1em;
    letter-spacing: ls(15);
    color: white;
    text-align: center;
    color: $lime-green;
    padding-bottom: 30px;

    @include media-breakpoint-down(bfl) {
        font-size: 42pt;
    }
    @include media-breakpoint-down(xxl) {
        font-size: 35pt;
    }

    @include media-breakpoint-down(xl) {
        font-size: 31pt;
    }
    @include media-breakpoint-down(lg) {
        font-size: 22pt;
    }
    @include media-breakpoint-down(md) {
        font-size: 48pt;
    }
    @include media-breakpoint-down(sm) {
        font-size: 12vw;
    }
}


.joinus-info {


    position: relative;
    top: 0;
    left: 0;
    width: 524px;
    height: 524px;

    display: flex;
    justify-content: center;
    align-items: center;

    .joinus-circle {
        position: absolute;
        top: 0;
        left: 0;

        circle {
            stroke: $green;
        }
    }

    .joinus-text {
        font-family: 'Questrial', sans-serif;
        font-weight: normal;
        font-size: 53pt;
        line-height: 1.1em;
        letter-spacing: ls(15);
        color: white;
        text-align: center;
        color: $lime-green;
        padding-bottom: 30px;


    }

}

#joinus-env-a.section-image {
    position: relative;
    z-index: 0;
    flex: 1 0 auto;

    background-size: 1247px 650px;
    //  background-size: cover;
    background-position-y: -37px; //(611px - 650px);
    background-position-x: 0;

    background-image: url("../images/Sustainability.jpg");

    .webp & {
        background-image: url("../images/Sustainability.webp");
    }
}

.joinus-panel-wrapper {
    flex: 0 0 0;
    position: relative;
    left: 0;
    top: 0;
    width: 0;
    z-index: 1;

    .joinus-panel {
        width: 430px;
        height: 100%;
        left: -430px;
        transition: left 500ms ease-in-out;

        &.open {
            left: 0px;
        }

        position: relative;
        z-index: 1;

        background-color: transparentize($green, 0.5);
        padding-left: 62px;
        padding-right: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: 18.625pt;
            line-height: 22.35pt;
            color: white;
            margin-top: 1em;
            margin-bottom: 1em;
        }

        a {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-size: fs(25pt);
            line-height: lh(30pt);
            color: $lime-green;
            padding-top: 1.6em
        }


    }
}


#joinus-map {
    min-height: 700px;
    background-color: white;
    color: $green;
    display: flex;
    justify-content: center;
    align-items: center;


}


.subject-row {

    background-color: white;
    //margin-top:10px;
    margin-bottom: $subject-gap;
    margin-bottom: 0;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    // grid-gap: $subject-gap;

    .subject-col {
        flex: 1 0 calc(50% - 6px);


        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }


    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        .subject-col {
            flex: 1 0 100%;
        }
    }

    .subject-col-gap {
        flex: 0 0 $subject-gap;
    }

    .subject {
        display: flex;
        flex: 120px;
        @extend .fo-questal;
        margin-bottom: $subject-gap;
        position: relative;


        color: white;

        .subject-left {
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30pt;
            background-color: $navy;
            text-align: center;
            padding-right: 10px;

        }

        .subject-right {
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparentize($navy, 0.4);
            text-align: center;
            padding-left: 10px;
            font-size: 19pt;
            @include media-breakpoint-down(bfl) {

            }
        }

        @include media-breakpoint-down(bfl) {
            .subject-left {
                font-size: 25pt;
                line-height: 1.1em;
                padding-right: 20px;
            }
            .subject-right {
                font-size: 17pt;
                padding-left: 20px;
            }
            flex-wrap: wrap;
            .subject-col {
                flex: 1 0 100%;
            }
        }
        @include media-breakpoint-down(xl) {
            .subject-left {
                font-size: 25pt;
                line-height: 1.1em;
            }
            .subject-right {
                font-size: 1em;
            }
            flex-wrap: wrap;
            .subject-col {
                flex: 1 0 100%;
            }
        }
        @include media-breakpoint-down(lg) {
            .subject-left {
                font-size: 25pt;
                line-height: 1.1em;
                padding-right: 10px;
            }
            .subject-right {
                font-size: 14pt;
                line-height: 1.1em;
                padding-left: 10px;
            }
            flex-wrap: wrap;
            .subject-col {
                flex: 1 0 100%;
            }
        }
        @include media-breakpoint-down(sm) {
            .subject-arrow {
                width: 45px;
                height: 45px;
                left: calc(50% - 26px);
                top: calc(50% - 21px);
            }
            .subject-left {
                font-size: 22pt;
                line-height: 1.1em;
                padding-right: 10px;
            }
            .subject-right {
                font-size: 13pt;
                line-height: 1.1em;
                padding: 5px;
                padding-left: 10px;
            }
            flex-wrap: wrap;
            .subject-col {
                flex: 1 0 100%;
            }
        }


    }


    .subject.alt {
        color: white;

        .subject-left {
            background-color: $green;
        }

        .subject-right {
            background-color: transparentize($green, 0.4);
            color: $navy;
        }
    }


}

.subject-arrow {
    //flex:0 1 40px;
    background-image: url('../images/arrow-green-left.png');

    .alt & {
        background-image: url('../images/arrow-navy-left.png');
    }

    background-size: contain;
    background-repeat: no-repeat;
    transform-origin: center center;
    transform: rotateZ(180deg);
    width: 60px;
    height: 60px;

    position: absolute;
    left: calc(50% - 38px);
    top: calc(50% - 30px);


}

.subject:hover,
.subject.hovered {
    transition-duration: 150ms;

    & .subject-arrow {
        transition-duration: 150ms;
        transform-origin: center center;
        transform: rotateZ(180deg) scale(1.1);
    }

    //& .subject-arrow {
    //    transform-origin : center center;
    //    transition-delay: 50ms;
    //    :hover &{
    //        transition-delay: 50ms;
    //        transform:scale(1.01);
    //        box-shadow: 2px 2px 2px lightgrey;
    //    }
    //}

}

.section-row#joinus-8 {

    min-height: 570px;


    #joinus-8-text {
    }


}

#joinus-8a {
    background-size: 701px;
    background-position-y: -185px;
    background-position-x: 0px;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
        height: 400px;
    }

    @include media-breakpoint-down(bfl) {
        background-size: 531px;
        background-position-y: -95px;
        background-position-x: -13px;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 541px;
        background-position-y: -115px;
        background-position-x: -33px;
    }
    @include media-breakpoint-down(xl) {
        background-size: 541px;
        background-position-y: -115px;
        background-position-x: -33px;
    }
    @include media-breakpoint-down(lg) {
        background-size: 531px;
        background-position-y: -95px;
        background-position-x: -13px;
    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
    }

}


#joinus-0 {
    background-image: url("../images/joinus-banner.jpg");
}

.webp #joinus-0 {
    background-image: url("../images/joinus-banner.webp");
}

#joinus-2a {
    background-image: url("../images/joinus-2a.jpg");
}

.webp #joinus-2a {
    background-image: url("../images/joinus-2a.webp");
}

#joinus-3s {
    background-image: url("../images/joinus-3a.jpg");
}


#joinus-7a {
    background-image: url("../images/joinus-7a.jpg");
}

.webp #joinus-7a {
    background-image: url("../images/joinus-7a.webp");
}

#joinus-7b {
    background-image: url("../images/joinus-7b.jpg");
}

.webp #joinus-7b {
    background-image: url("../images/joinus-7b.webp");
}

#joinus-8a {
    background-image: url("../images/joinus-8a.jpg");
}

.webp #joinus-8a {
    background-image: url("../images/joinus-8a.webp");
}

#joinus-3b {
    background-image: url("../images/joinus-3b.jpg");
}

.webp #joinus-3b {
    background-image: url("../images/joinus-3b.webp");
}


#joinus-env-a {
    background-image: url("../images/joinus-3b.jpg");
}

.webp #joinus-env-a {
    background-image: url("../images/joinus-3b.webp");
}


#about-us-0 {
    background-image: url("../images/about-us-0.jpg");
}

.webp #about-us-0 {
    background-image: url("../images/about-us-0.jpg");
}

#about-us-4 {
    background-image: url("../images/about-us-4.jpg");
}

.webp #about-us-4 {
    background-image: url("../images/about-us-4.jpg");
}

@include media-breakpoint-down(lg) {
    #design-5-left {
        padding-top: 40px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    #design-5-right {
        padding-bottom: 60px;
        margin-top: 0;
        padding-top: 0;
    }

}

$subject-panel-perc: 40%;
#joinus-subjects-2 {
    .subject-col {
        max-width: 1500px;
    }

    .subject {
        flex-wrap: nowrap;

        font-size: 26pt;
        @include media-breakpoint-down(lg) {
            font-size: 19pt;
        }
        @include media-breakpoint-down(md) {
            font-size: 18pt;
        }

    }

    .subject-left {
        flex: 0 0 $subject-panel-perc;
        padding: 10px;
        padding-right: 20px;
        font-size: 25pt;
        font-size: 1em;

    }

    .subject-right {
        flex: 1 1 auto;
        padding: 10px;
        padding-left: 25px;
        font-size: 25pt;
        font-size: 1em;
    }

    .subject-arrow {
        top: calc(50% - 30px);
        left: calc($subject-panel-perc - 35px);
        @include media-breakpoint-down(lg) {
            left: calc($subject-panel-perc - 35px);

        }

    }

    .subject.subject-header {
        flex: auto;
        margin-bottom: 0px;

        font-size: 26pt;
        @include media-breakpoint-down(lg) {
            font-size: 21pt;
        }
        @include media-breakpoint-down(md) {
            font-size: 19pt;
        }

        .subject-left {
            background-color: white;
            color: $navy;
            font-size: 1em;
            justify-content: flex-start;
            padding-left: 1.5em;
            padding: 5px 0 5px 0.5em;
            padding: 0.5em 5px 6px 0.51em;
            text-align: left;
        }

        .subject-right {
            background-color: white;
            color: $navy;
            font-size: 1em;
            justify-content: flex-start;
            padding-left: 1.5em;
            padding: 5px 0 5px 0.5em;
            padding: 0.5em 5px 6px 0.51em;
            text-align: left;
        }

        .subject-arrow {
            display: none;
        }
    }

    @include media-breakpoint-down(lg) {
        .subject-left {
            //        font-size: 24pt;
        }
        .subject-right {
            //       font-size: 15pt;
        }
        .subject-arrow {
            left: calc($subject-panel-perc - 35px);
        }

    }
    @include media-breakpoint-down(md) {
        .subject-left {
            //    font-size: 21pt;
        }
        .subject-arrow {
            left: calc($subject-panel-perc - 35px);
        }

    }
    @include media-breakpoint-down(sm) {
        .subject-left {
            //       font-size: 18pt;
        }
        .subject-arrow {
            left: calc($subject-panel-perc - 25px);
        }

    }

}
