
#community-photo-a {
    //background-position-y: -96px;
    //background-position-y: 56%;

    background-repeat: no-repeat;
    background-size: 2234px;

    background-position-y: 56%;
    background-position-x: 43%;

    background-image: url("../images/community/our-community-group.jpg");

    .webp & {
        background-image: url("../images/community/our-community-group.jpg");
    }

    @include media-breakpoint-down(bfl) {
        background-repeat: no-repeat;
        background-size: 1558px;
        background-position-y: 56%;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 1380px;
        background-position-y: 70%;
    }
    @include media-breakpoint-down(xl) {
        background-size: 1144px;
        background-position-y: 47%;
    }
    @include media-breakpoint-down(lg) {
        //background-size: 854px;
        //background-position-y: 47%;
        //background-size: cover;
        //background-position-y: -95px;
        background-size: 1140px;
        background-position-y: -95px;
        background-position-x: -290px;
    }
    @include media-breakpoint-down(md) {
        background-size: 1040px;
        background-position-y: 47%;
        background-position-x: -399px;

    }
    @include media-breakpoint-down(sm) {
        background-size: 210%;
        background-position-y: 47%;
        background-position-x: 77%;

    }
}


#richard-trapp {
    //background-size: 800px;
    //background-position-y: -64px;
    //background-position-x: -140px;
    //background-position-x: -68px;
    background-repeat: no-repeat;

    //background-size: 1000px;
    //background-position-y: -84px;
    //background-position-x: -140px;
    //background-position-x: -188px;
    //
    //background-position-y: top;
    //background-position-x: center;

    background-size: 1050px;
    background-position-y: -145px;
    background-position-x: -230px;

    background-size: 1050px;
    background-position-y: 42%;
    background-position-x: 55%;

    background-size: 920px;
    background-position-y: 86%;
    background-position-x: 50%;

    background-image: url("../images/community/Richard Trapp.jpg");

    .webp & {
        //        background-image: url("../images/Richard Trapp.webp");
    }

    @include media-breakpoint-down(bfl) {
        background-size: 1050px;
        background-position-y: 42%;
        background-position-x: 55%;

        background-size: 840px;
        background-position-y: 89%;
        background-position-x: 50%;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 790px;
        background-size: 860px;
        background-position-y: 99%;
        background-position-x: 48%;
    }
    @include media-breakpoint-down(xl) {
        background-size: 780px;
        background-position-y: 99%;
        background-position-x: 48%;
    }
    @include media-breakpoint-down(lg) {
        height: 720px;
        //        background-position-x: 59%;

        height: 569px;
        background-size: 720px;
        background-position-y: 100%;
        background-position-x: 59%;

    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
    }

}


#community-img-3 {
    background-repeat: no-repeat;
    background-size: 794px;
    background-position-x: -74px;
    background-position-y: -25px;
    background-color: rgba(211, 211, 211, 0.52);

    @include media-breakpoint-down(bfl) {
        background-size: 644px;
        background-position-x: -84px;
        background-position-y: -25px;
        background-color: rgba(211, 211, 211, 0.52);
    }
    @include media-breakpoint-down(xxl) {
        background-size: 677px;
        background-position-x: -120px;
        background-position-y: bottom;
    }
    @include media-breakpoint-down(xl) {
        background-size: 557px;
        background-position-x: -110px;
        background-position-y: bottom;
    }
    @include media-breakpoint-down(lg) {
        height: 600px;
        background-size: 827px;
        background-position-x: -82px;
        background-position-y: -34px;
    }
    @include media-breakpoint-down(md) {
        height: 600px;
        background-size: 827px;
        background-position-x: -133px;
        background-position-y: -34px;
    }
    @include media-breakpoint-down(sm) {
        height: 75vw;
        background-size: 110%;
        background-position-x: 50%;
        background-position-y: 20%
    }

}

#community-img-3 {
    background-image: url("../images/community/THE SET UP GO WATER STAFF HEAD SHOTS 20140102_0609.jpg");
}

.webp #community-img-3 {
    background-image: url("../images/community/THE SET UP GO WATER STAFF HEAD SHOTS 20140102_0609.jpg");
}


#we-are-committed {
    //    min-height: 613px;

    .text {
        //margin-top: 80px;
        //margin-bottom: 80px;
    }
}


#we-are-committed-text {
    //flex: 0 0 875px;
    @include media-breakpoint-down(xl) {
        order: 1;
    }
}

#we-are-committed-a {
    background-repeat: no-repeat;
    background-size: 484px;
    background-position-x: -20px;
    background-position-y: -5px;
    background-position-y: bottom;

    //@media (max-width: 1800px) {
    //    background-size: cover;
    //    background-position-y: bottom;
    //}

    background-image: url("../images/community/THE SET UP GO HAPPY VALLEY  20140105_0098.jpg");

    .webp & {
        background-image: url("../images/community/THE SET UP GO HAPPY VALLEY  20140105_0098.jpg");
    }

    @include media-breakpoint-down(xl) {
    }


    @include media-breakpoint-down(bfl) {
        background-size: cover;
    }
    @include media-breakpoint-down(xxl) {
        background-position-x: -56px;
    }
    @include media-breakpoint-down(xl) {
        background-position-x: -20px;
        order: 2;

    }
    @include media-breakpoint-down(lg) {
        //height: 629px;
        //background-repeat: no-repeat;
        //background-size: cover;
        //background-position-x: 0px;
        //background-position-y: -5px;
        //background-position-y: -160px;
        //background-image: url("../images/community/THE SET UP GO HAPPY VALLEY  20140105_0098.jpg");

        height: 709px;
        background-size: cover;
        background-position-x: 0px;
        background-position-y: -5px;
        background-position-y: -160px;
        /*
        background-size: cover;
        background-position-y: bottom;
        background-position-x: -35px;
        */
    }
    @include media-breakpoint-down(md) {
        height: 510px;
        background-size: cover;
        background-position-x: 0px;
        background-position-y: -5px;
        background-position-y: -110px;

        height: 540px;
        background-size: cover;
        background-position-x: 0px;
        background-position-y: -5px;
        background-position-y: -90px
    }
    @include media-breakpoint-down(sm) {
        height: 112vw;
        background-size: 114%;
        background-position-x: 10%;
        background-position-y: 29%

    }

}

#we-are-committed-b {
    //flex: 0 1 665px;
    //flex: 1 1 auto;

    background-repeat: no-repeat;
    background-size: 1096px;
    background-position-x: -319px;
    background-position-x: 68%;
    background-position-y: bottom;

    background-image: url("../images/community/GO Myponga (h) IMG_9858_.jpg");

    .webp & {
        background-image: url("../images/community/GO Myponga (h) IMG_9858_.jpg");
    }

    @include media-breakpoint-down(bfl) {
        background-size: cover;
        background-position-y: bottom;
        background-position-x: 62%;

    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        display: block;
        height: 430px;
        background-size: 769px;
        background-position-y: bottom;
        background-position-x: right;
        //height:42vw;
        //background-size: 1100px;
        //background-position-y: -120px;
        //background-position-x: -50px;
    }
    @include media-breakpoint-down(md) {
        height: 390px;
        background-size: 699px;
        background-position-y: bottom;
        background-position-x: -110px;
    }
    @include media-breakpoint-down(sm) {
        height: 360px;
        background-size: 160%;
        background-position-y: bottom;
        background-position-x: 60%;
    }

}

@include media-breakpoint-down(xxl) {
    #we-are-committed-text {
        //    order:1;
    }
    #we-are-committed-a {
        // order:2;
    }
    #we-are-committed-b {
        // order:2;
    }
}

@include media-breakpoint-down(lg) {
    #we-are-committed-text {
        order: 2;
    }
    #we-are-committed-a {
        order: 3;
    }
    #we-are-committed-b {
        order: 1;
    }
}

@include media-breakpoint-down(xl) {
    #we-are-committed {
        //        flex-wrap:wrap;
    }
    #we-are-committed-text {
        //        flex:1 1 100%;
        //     order:2;
    }
    #we-are-committed-a {
        //     order:1;
        //        flex:1 1 100%;
    }
}


.page-community {

    #community-photo {
        //        min-height: 814px;
    }

    #we-employ {
        justify-content: flex-start;

        @include media-breakpoint-down(sm) {
            br {
                display: none;
            }
        }
        @include media-breakpoint-only(md) {
            br {
                display: none;
            }

        }

    }


    #management-team {

    }

    #chief-executive {
        .text {
            padding-top: 30px;

            p {
                font-weight: 400;
            }

        }


    }


    #community-circle-block {
        background-color: $navy;

        .circ-text {
            text-align: center;
            //position:relative;
            //left:20px;
        }

        .circ-svg {
            circle {
                stroke: $lime-green;
            }
        }

    }


    #board-intro {
        //font-size: 37.25pt;
        //line-height: 40.975pt;
        //letter-spacing: 0.415pt

    }

    #chief-executive {

        .title {
            //            @extend .size-1;
            @extend .fo-questal;
            margin-bottom: 0em;
        }

        .sub-title {
            @extend .fo-questal;
            font-size: 27pt;
            margin-top: 5px;
            margin-bottom: 1em;
            font-weight: 400;
        }

    }
}

#team-filler {
    flex: 1 0 66%;
    padding: 30px 60px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @include media-breakpoint-down(lg) {
        margin-top: 1.8em;
    }
    @include media-breakpoint-down(md) {
        padding: 20px 25px;
    }
}

#board-intro {
    @extend .fo-questal;
    font-size: 31pt;
    line-height: 1.15em;
    font-weight: 400;

    @include media-breakpoint-down(bfl) {
        font-size: 26pt;
        line-height: 1.2em;
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
    }
    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-down(md) {
    }
}


.team-member-group {
    display: flex;
    flex-wrap: wrap;

    padding: 40px 20px;
    @include media-breakpoint-down(sm) {
        padding: 10px 10px;
    }

    .team-member-card {
        flex: 0 0 33%;
    }

    @include media-breakpoint-down(lg) {
        .team-member-card {
            flex: 0 0 100%;
        }
    }
    @include media-breakpoint-down(lg) {
        .team-member-card {
            flex: 0 0 100%;
        }
    }


    .team-member-card-inner {
        padding: 30px 60px;
        @include media-breakpoint-down(md) {
            padding: 20px 25px;
        }
        @include media-breakpoint-down(sm) {
            //padding: 10px 10px;
        }
    }

    .tm-name {
        @extend .fo-questal;
        @extend .fw-600;
        color: $green;
        font-size: 24pt;
        line-height: 1.15em;
        margin-bottom: 0.2em;

        a {
            color: inherit;

            &:hover {
                //        text-decoration: underline;
            }

        }
    }

    .tm-title {
        @extend .fo-questal;
        @extend .fw-400;
        color: $green;
        font-size: 18pt;
        margin-bottom: 0.6em;
    }

    .tm-desc {
        color: $navy;
        //@extend .fw-400;
    }

}

.board-member-group {

    display: flex;
    flex-direction: column;
    margin-top: 0.1em;

    .board-member-card {
        margin-bottom: 1.8em;

        .board-member-card-inner {
            color: $navy;

            .bm-name {
                @extend .fo-questal;
                font-size: 28pt;
                line-height: 1.1em;
            }

            .bm-title {
                display: inline-block;
                @extend .fo-questal;
                font-size: 17pt;
                margin-left: 1.5em;
            }

            .bm-desc {
                margin-top: 0.4em;
            }
        }
    }
}

@include media-breakpoint-down(bfl) {
    .bm-title {
        display: block !important;
        font-size: 16pt !important;
        margin-left: 0 !important;
    }
    .bm-name {
        font-size: 26pt !important;
    }
}

@include media-breakpoint-down(xxl) {
    .bm-title {
        display: block !important;
        font-size: 15pt !important;
    }
    .bm-name {
        font-size: 24pt !important;
    }

}

@include media-breakpoint-down(xl) {
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(md) {

}



