
#contactus-img {

    #contactus-0 {
        width: 100%;
        width: 100%;
        background-size: 2280px;
        background-repeat: no-repeat;
        background-position-y: -52px;
        background-position-x: -200px;

        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;

        background-image: url("../images/contact/contact-us-banner.jpg");

        .webp & {
            background-image: url("../images/contact/contact-us-banner.webp");
        }

        @include media-breakpoint-down(bfl) {
            background-size: 1550px;
            background-position-y: -52px;
            background-position-x: -200px;

        }
        @include media-breakpoint-down(xxl) {
            background-size: 1430px;
            background-position-y: -52px;
            background-position-x: -200px;
        }
        @include media-breakpoint-down(xl) {
            background-size: 1430px;
            background-position-y: -52px;
            background-position-x: -200px;

            background-size: 1220px;
            background-position-y: -52px;
            background-position-x: -140px;
        }
        @include media-breakpoint-down(lg) {
            background-size: 1110px;
            background-position-y: -52px;
            background-position-x: -150px;
        }
        @include media-breakpoint-down(md) {
            background-size: 1080px;
            background-position-y: -62px;
            background-position-x: -160px;
        }

        @include media-breakpoint-down(sm) {
            //background-size: 1430px;
            //background-position-y: -52px;
            //background-position-x: -200px;

            background-size: 210%;
            background-position-y: bottom;
            background-position-x: -100px;
        }


    }

}


#contactus-1 {
    height: 240px;
    background-color: $navy;

    .stat {
        svg {
            text {
                stroke: white;
                font-size: fs(24pt);
                line-height: lh(27pt);

                font-weight: 400;

            }
        }

        .std-circle {
            stroke-width: 4px;

        }
    }

}

#office-list {
    .office {
        margin-bottom: 20px;
        //margin-bottom: 0px;

        div {
            font-size:1.1em;
            @include media-breakpoint-down(xl) {
                font-size:1.4em;

            }
        }

        .office-name {
            @extend .size-1;
            font-size: 37.25pt;
            //line-height: 40.975pt;
            line-height: 1.26em;
            @extend .fo-questal;
            color: $green;
            margin-bottom: 11px;
            margin-bottom: 5px;

            @include media-breakpoint-down(bfl) {
                font-size: 27.5pt;
                //margin-bottom: 5px;
            }
            @include media-breakpoint-down(xxl) {
                font-size: 24.5pt;
                //margin-bottom: 5px;
            }
            @include media-breakpoint-down(xl) {
                font-size: 34.5pt;
                //margin-bottom: 5px;
            }
            @include media-breakpoint-down(md) {
                font-size: 32pt;
                //margin-bottom: 5px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 28pt;
                //margin-bottom: 5px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 26pt;
                //margin-bottom: 5px;
            }
        }


    }

    .office + .office {
        //margin-top: 30px;
        margin-top: 2em;

    }
}


#contactus-1a {
    //background-size: 492px;
    //background-repeat: no-repeat;
    //background-position-y: -40px;
    //background-position-x: -40px;
    //
    //background-size: 622px;
    //background-repeat: no-repeat;
    //background-position-y: -83px;
    //background-position-x: center;

    background-size: 712px;
    background-repeat: no-repeat;
    background-position-y: -88px;
    background-position-x: -143px;

    background-image: url("../images/contact/contact-us-1a.jpg");

    .webp & {
        background-image: url("../images/contact/contact-us-1a.jpg");
    }

    @include media-breakpoint-down(bfl) {
        background-size: 552px;
        background-repeat: no-repeat;
        background-position-y: -73px;
        background-position-x: -125px;
    }
    @include media-breakpoint-down(xxl) {
        background-size: 562px;
        background-repeat: no-repeat;
        background-position-y: -65px;
        background-position-x: -160px;

    }
    @include media-breakpoint-down(xl) {
        //display: block;
    }
    @include media-breakpoint-down(lg) {
        //height: 630px;
        //background-size: 542px;
        //background-repeat: no-repeat;
        //background-position-y: -60px;
        //background-position-x: center;

        //height: 830px;
        //background-size: 722px;
        //background-repeat: no-repeat;
        //background-position-y: -70px;
        //background-position-x: center;

        height: 690px;
        background-size: 712px;
        background-repeat: no-repeat;
        background-position-y: -100px;
        background-position-x: center;
    }
    @include media-breakpoint-down(md) {
        //height: 590px;
        //background-size: 542px;
        //background-repeat: no-repeat;
        //background-position-y: -60px;
        //background-position-x: center;
    }

}

#contactus-1a-alt {
    //    width: 100%;
    //background-size: 492px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;

    background-image: url("../images/contact/contact-us-1a.jpg");

    .webp & {
        background-image: url("../images/contact/contact-us-1a.jpg");
    }

    display: none;
    @include media-breakpoint-down(xl) {
        display: block;
        height: 330px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: -49px;
        background-position-x: center;
    }
    @include media-breakpoint-down(lg) {
        height: 300px;
        background-size: 260px;
        background-position-y: -28px;
        background-position-x: 0px;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }

}

@include media-breakpoint-down(lg) {
    #contactus-1a {
        //        order: 2;
    }
    #contactus-info-panel {
        //        order: 1;
    }
    #office-list {
        order: 3;

        padding-top: 40px;
        //  padding:40px 40px;

    }
}

@include media-breakpoint-down(md) {


    #contactus-1a {
        //   order: 2;
    }
    #contactus-info-panel {
        //   order: 1;
    }
    #office-list {
        order: 3;
        padding-top: 40px;
        //  padding:40px 40px;
    }

}


#contactus-2a {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: 0px;

}

#contactus-3 {
    height: 600px;
}

#contactus-3a {
    //    flex: 1 0 50%;
    background-size: 560px;
    background-position-y: 0px;
    background-position-x: center;
    background-repeat: no-repeat;
}


#contactus-6a {
    background-size: 971px;
    background-position-y: -23px;
    background-position-x: 0px;
    background-repeat: no-repeat;
}


#contactus-7a {
    //    flex: 0 0 50%;
    background-size: 971px;
    background-position-y: -23px;
    background-position-x: 0px;
    background-repeat: no-repeat;
}

#contactus-7-text {
    //flex: 0 1 580px;
    //flex: 0 1 475px;

    div.text {
        //padding-right:15px;
    }
}


#contactus-7b {

    //    flex: 1 0 350px;
    background-size: 477px;
    background-position-y: -12px;
    background-position-x: 40px;
    background-repeat: no-repeat;

}


.section-row#contactus-env {
    //   background-color: $navy;
    height: 607px;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    position: relative;

    .env-info-panel {
        flex: 0 0 665px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;
        background-color: $navy;
    }

}


#contactus-info {


    circle {
        stroke: $lime-green;
    }

    .circ-text {
        padding-left: 0.7em;
    }

    .contactus-text {
        font-family: 'Questrial', sans-serif;
        font-weight: normal;
        letter-spacing: ls(15);
        color: white;
        text-align: left;
        padding-bottom: 30px;

        font-size: fs(50pt);
        line-height: lh(55pt);
        letter-spacing: ls(15);
        color: white;
        padding-left: 0.85em;


    }

}

#contactus-env-a.section-image {
    position: relative;
    z-index: 0;
    flex: 1 0 auto;

    background-size: 1247px 650px;
    //  background-size: cover;
    background-position-y: -37px; //(611px - 650px);
    background-position-x: 0;

    background-image: url("../images/Sustainability.jpg");

    .webp & {
        background-image: url("../images/Sustainability.webp");
    }
}

.contactus-panel-wrapper {
    flex: 0 0 0;
    position: relative;
    left: 0;
    top: 0;
    width: 0;
    z-index: 1;

    .contactus-panel {
        width: 430px;
        height: 100%;
        left: -430px;
        transition: left 500ms ease-in-out;

        &.open {
            left: 0px;
        }

        position: relative;
        z-index: 1;

        background-color: transparentize($green, 0.5);
        padding-left: 62px;
        padding-right: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 300;
            font-size: 18.625pt;
            line-height: 22.35pt;
            color: white;
            margin-top: 1em;
            margin-bottom: 1em;
        }

        a {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-size: fs(25pt);
            line-height: lh(30pt);
            color: $lime-green;
            padding-top: 1.6em
        }


    }
}


#contactus-map {
    min-height: 700px;
    background-color: white;
    color: $green;
    display: flex;
    justify-content: center;
    align-items: center;


}


.ssubject-row {

    $subject-gap: 12px;

    background-color: white;
    //margin-top:10px;
    margin-bottom: $subject-gap;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: $subject-gap;

    .subject-col {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }


    .subject {
        display: flex;
        flex: 120px;
        @extend .fo-questal;
        marign-bottom: $subject-gap;

        color: white;

        .subject-left {
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30pt;
            background-color: $navy;
        }

        .subject-right {
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparentize($navy, 0.4);

            font-size: 19pt;
        }
    }

    .subject.alt {
        color: white;

        .subject-left {
            background-color: $green;
        }

        .subject-right {
            background-color: transparentize($green, 0.4);
        }
    }


}


.section-row#contactus-8 {

    min-height: 570px;

    #contactus-8a {
        @extend .panel-medium;
        background-size: 701px;
        background-position-y: -185px;
        background-position-x: 0px;
        background-repeat: no-repeat;

    }

    #contactus-8-text {
        flex: 1 1 auto;
    }


}


#contactus-form {

    .mail-link {
        margin-bottom: 7px;
    }

    .contact-form-panel {
        .text {
            width: 100%;
        }

        form {
            width: 100%;
        }

        button[type="submit"] {
            .circled-text {
                height: 200px;
                width: 200px;


                svg {
                    height: 200px;
                    width: 200px;
                }

                .circled-text-content {
                    width: 200px !important;
                    height: 200px !important;
                }
            }

            .circled-text svg circle {
                stroke: $lime-green;
                stroke-width: 2px;
            }
        }

    }

    label {
        font-size: fs(22pt);
        margin-bottom: 2px;
        margin-left: 10px;
        margin-top: 5px;
    }

    .contact-field-group {
        margin-top: 15px;
        margin-bottom: 25px;
    }

    .contact-field-group + .contact-field-group {
        margin-top: 18px;
    }

    input,
    textarea {
        font-family: "Source Sans Pro", sans-serif;
        width: 100%;
        border-radius: 0;
        font-size: 22pt;
        padding: 10px;
        color: $navy;
    }
}

