@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

.fw-300 {
    font-weight: 300 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-400 {
    font-weight: 400 !important;
}


.fo-sans {
    font-family: 'Source Sans Pro', sans-serif;
}

.fo-questal {
    font-family: "Questrial", sans-serif;
}

.fo-sans {
    font-family: 'Source Sans Pro', sans-serif;
}

.fo-questal {
    font-family: "Questrial", sans-serif;
}

