
.title + ul.green-bulleted {
    margin-top: 1.5em;
}


ul.green-bulleted {
    list-style: none;
    list-style-position: outside;
    margin-left: 0;
    padding-left: $bullet-indent;
    margin-bottom: 1.3em;

    //font-weight:400;

    li {
        position: relative;
        font-family: 'Source Sans Pro', sans-serif;
        //font-size: fs(25pt);
        //line-height: lh(30pt);

        margin-top: 1em;
        padding-left: $bullet-indent;

        //font-size: 18.625pt;
        line-height: 1.2em;
    }

    li::before {
        content: "";
        position: absolute;
        display: inline-block;
        left: -$bullet-indent;
        top: 3px;
        width: 22px;
        height: 22px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABhmlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw1AUhU9TpSIVBTuIOGSogmBBVEScpIpFsFDaCq06mLz0D5o0JCkujoJrwcGfxaqDi7OuDq6CIPgD4ujkpOgiJd6XFFrEeOHxPs675/DefYBQLzPV7BgHVM0ykrGomMmuioFX+NCHIEYxKzFTj6cW0/Csr3vqprqL8Czvvj+rR8mZDPCJxHNMNyziDeLpTUvnvE8cYkVJIT4nHjPogsSPXJddfuNccFjgmSEjnZwnDhGLhTaW25gVDZV4ijisqBrlCxmXFc5bnNVylTXvyV8YzGkrKa7TGkIMS4gjAREyqiihDAsR2jVSTCTpPOrhH3T8CXLJ5CqBkWMBFaiQHD/4H/yerZmfnHCTglGg88W2P4aBwC7QqNn297FtN04A/zNwpbX8lTow80l6raWFj4DebeDiuqXJe8DlDjDwpEuG5Eh+WkI+D7yf0Tdlgf5boHvNnVvzHKcPQJpmtXwDHBwCIwXKXvd4d1f73P7tac7vB86ocsz8j54/AAAABmJLR0QADgCaAEYpSNydAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5gESASorCay7xAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAHOSURBVDjLtZVBa6RAEIVfta3tbAzJJUEIA3OUWQj5J7nvzw7skOMsYUByStAJtt1ae1i7bXczEyds6qjUR1U935PE/Q98RcmDbyKGzFuWuUZ0YUFpDwDgRqB7lbClgi0TQkfzwfJGsyr2HhYWLXrIRQuZt+BGsH48g90p+hCsvtccr5pZ61LaI72rYC4N658ZHQT/DeVGwGxT2GeFvo4IAETWsbzWiFeN32jomcBluH4INU8K7SYjttMt+yqitvoG82uBZF1zvNQe3r3E7M4inFCq2E+g+uH8H2hYbAn64ZzMkxo3LvZAxPBgmbccqt5uMpr7WbWbjLgR/uYybzkA63HabYpjk743udmmo2gDSwBAdGH9C/usTjZD2ONYwq3gxRnUP6XCHscS+KISTjD/IOv4ZEjQ41gCALrX0SfyWp8eOEGPYwkAsOV4/HjVgOT8oUkyQmM51gBOJt9isq5nk5N1PfGALZPAeR1BP56NUy811G3FxyYnyVC3lbc0gD+MIUb9ce1Okbk0Pi/ipYa8MjwnhJyxwvicpNuQTh5OaY+keENSvAHAwfHNNsXR2HTw7iU+GPQTOzcCs4PencWWyf//NTlB7U6R3alPOe83xTUCB8vZ1OoAAAAASUVORK5CYII=');
        background-repeat: no-repeat;
    }

}

ul.green-bulleted {
    list-style: none;
    list-style-position: outside;
    margin-left: 0;
    padding-left: $bullet-indent;
    margin-bottom: 1.3em;

    //font-weight:400;

    li {
        position: relative;
        font-family: 'Source Sans Pro', sans-serif;
        //font-size: fs(25pt);
        //line-height: lh(30pt);

        margin-top: 1em;
        padding-left: $bullet-indent;

        //font-size: 18.625pt;
        line-height: 1.2em;
    }

    li::before {
        content: "";
        position: absolute;
        display: inline-block;
        left: -$bullet-indent;
        top: 3px;
        width: 22px;
        height: 22px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABhmlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw1AUhU9TpSIVBTuIOGSogmBBVEScpIpFsFDaCq06mLz0D5o0JCkujoJrwcGfxaqDi7OuDq6CIPgD4ujkpOgiJd6XFFrEeOHxPs675/DefYBQLzPV7BgHVM0ykrGomMmuioFX+NCHIEYxKzFTj6cW0/Csr3vqprqL8Czvvj+rR8mZDPCJxHNMNyziDeLpTUvnvE8cYkVJIT4nHjPogsSPXJddfuNccFjgmSEjnZwnDhGLhTaW25gVDZV4ijisqBrlCxmXFc5bnNVylTXvyV8YzGkrKa7TGkIMS4gjAREyqiihDAsR2jVSTCTpPOrhH3T8CXLJ5CqBkWMBFaiQHD/4H/yerZmfnHCTglGg88W2P4aBwC7QqNn297FtN04A/zNwpbX8lTow80l6raWFj4DebeDiuqXJe8DlDjDwpEuG5Eh+WkI+D7yf0Tdlgf5boHvNnVvzHKcPQJpmtXwDHBwCIwXKXvd4d1f73P7tac7vB86ocsz8j54/AAAABmJLR0QADgCaAEYpSNydAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5gESASorCay7xAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAHOSURBVDjLtZVBa6RAEIVfta3tbAzJJUEIA3OUWQj5J7nvzw7skOMsYUByStAJtt1ae1i7bXczEyds6qjUR1U935PE/Q98RcmDbyKGzFuWuUZ0YUFpDwDgRqB7lbClgi0TQkfzwfJGsyr2HhYWLXrIRQuZt+BGsH48g90p+hCsvtccr5pZ61LaI72rYC4N658ZHQT/DeVGwGxT2GeFvo4IAETWsbzWiFeN32jomcBluH4INU8K7SYjttMt+yqitvoG82uBZF1zvNQe3r3E7M4inFCq2E+g+uH8H2hYbAn64ZzMkxo3LvZAxPBgmbccqt5uMpr7WbWbjLgR/uYybzkA63HabYpjk743udmmo2gDSwBAdGH9C/usTjZD2ONYwq3gxRnUP6XCHscS+KISTjD/IOv4ZEjQ41gCALrX0SfyWp8eOEGPYwkAsOV4/HjVgOT8oUkyQmM51gBOJt9isq5nk5N1PfGALZPAeR1BP56NUy811G3FxyYnyVC3lbc0gD+MIUb9ce1Okbk0Pi/ipYa8MjwnhJyxwvicpNuQTh5OaY+keENSvAHAwfHNNsXR2HTw7iU+GPQTOzcCs4PencWWyf//NTlB7U6R3alPOe83xTUCB8vZ1OoAAAAASUVORK5CYII=');
        background-repeat: no-repeat;
    }

}

ul.limegreen-bulleted {

    --bullet-indent: 12px;
    $bullet-indent: var(--bullet-indent);
    list-style: none;
    list-style-position: outside;
    margin-left: 0;
    padding-left: $bullet-indent;
    margin-bottom: 1.2rem;

    li {
        position: relative;
        //        font-family: 'Source Sans Pro', sans-serif;
        color: $navy;
        margin-bottom: 0.15em;
        padding-left: $bullet-indent;
        //font-size: 18.625pt;
        line-height: 1.1em;
    }

    li::before {
        content: "";
        position: absolute;
        display: inline-block;
        left: calc($bullet-indent * -1);
        top: 0.25em;
        width: 11px;
        height: 11px;
        background-color: $lime-green;
        border-radius: 50%;
    }

}
