
.quote-carousel-panel {
    display: flex;
    align-items: stretch;

    .quote-left {
        padding-left: 120px;
        padding-left: 180px;

        @include media-breakpoint-down(bfl) {
            padding-left: 150px;
        }
        @include media-breakpoint-down(xxl) {
            padding-left: 130px;
        }
        @include media-breakpoint-down(lg) {
            padding-left: 180px;
        }
        @include media-breakpoint-down(md) {
            padding-left: 180px;
        }
        @include media-breakpoint-down(md) {
            padding-left: 180px;
        }
        @include media-breakpoint-down(md) {
            padding-left: 180px;
        }


    }

    .quote-right {
        padding-right: 180px;
    }

    .quote-single {
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        justify-content: center;
        justify-content: flex-start;
        align-items: center;
    }

}

.quote-block {

    .quote-author-name {
        margin-top: 2em;
        color: $lime-green;
        text-transform: uppercase;
        font-weight: 600;
        font-size: fs(18pt);
        line-height: 1.22;
    }

    .quote-author-title {
        color: $lime-green;
        text-transform: uppercase;
        font-weight: 600;
        font-size: fs(18pt);
        line-height: lh(22pt);
    }
}


.page-about-us {
    //background-image: url('../images/go-about-bg.png');
    //background-repeat: no-repeat;
    //background-size: 100%;
    //background-position-y: 2px;
    //opacity: 1;

    //    #aboutCarousel.carousel {
    .carousel {
        .quote-carousel-panel {
            align-items: center;

            .two-up & {
                min-height: 611px;
            }
        }

        .quote-carousel-middler {
            display: flex;
            align-items: flex-start;
        }

        .quote-block-to-middle {
            display: flex;
            align-items: center;
        }

        .carousel-control-prev {
            align-items: center;
            width: 10%;
            opacity: 1;
        }

        .carousel-item {
            //display:flex;
            //align-items:center;
            //min-height:611px;
        }

        .carousel-control-next {
            align-items: center;
            width: 10%;
            opacity: 1;
        }

        .carousel-item {
            background-image: linear-gradient(90deg, $navy 50%, $white 50%)
        }

        .carousel-inner.split {
            .carousel-item {
                background-image: linear-gradient(90deg, $navy 50%, $navy 50%)
            }
        }
    }

}

$col-1-w: 544px;
$col-1-w-a: 450px;

#about1-col-1 {
    //    flex: 0 0 $col-1-w;
}

#about1-col-2 {
    //   @extend .col-7;
}

#about2-col-1 {
    //    flex: 0 0 $col-1-w;

    background-size: cover;
    background-position-y: top;
    background-position-x: left;
    background-image: url("../images/about-us-4.jpg");

    .webp & {
        background-image: url("../images/about-us-4.jpg");
    }
}


@include media-breakpoint-down(md) {
    #about-us-5 .size-1 {
        font-size: 30pt;
    }
}

@include media-breakpoint-down(sm) {
    #about-us-5 .size-1 {
        font-size: 25pt;
    }
}


#aboutCarousel {
    .quote-carousel-panel {
        min-height: 611px;
    }

}

#aboutCarouselMobile {
    .quote-carousel-panel {
        align-items: flex-start;
        min-height: 362px;

        @include media-breakpoint-down(lg) {
            min-height: 352px;
            min-height: 280px;
        }
        @include media-breakpoint-down(md) {
            min-height: 352px;
            min-height: 375px;
        }
        @include media-breakpoint-down(sm) {
            min-height: 352px;
            min-height: 425px;
        }
    }

    .quote-author-name.green,
    .quote-author-title.green {
        color: $lime-green !important;
    }
}


#aboutCarouselMobileButtons {
    padding: 0 64px;
    padding: 0 10%;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;

    button {
        outline: 0;
        border: 0;

    }
}

#about-us-img {
    //height: 813px;

    #about-us-0.section-image {
        //width: 100%;
        //background-size: cover;
        //background-repeat: no-repeat;
        //background-position-y: -20px;
        //background-position-y: 11.5%;
        //background-position-x: center;
    }

}


#about-us {
    //    min-height: 607px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    circle.std-circle {
        stroke: $lime-green;
        fill: transparent;
        sstroke-width: 4px;
    }

    // background-color:$green;

    .section-text {
        //      background-color: white;

        .text-panel-padding {
            flex: 0 1 830px;

            p + p {
                margin: 1em 0;
            }
        }
    }


    //.circle-block-panel {
    //    background-color: $green;
    //}


}

#about1-col-3,
#about1-col-3-alt {
    background-repeat: no-repeat;
    background-image: url("../images/about-us-1.jpg");

    .webp & {
        background-image: url("../images/about-us-1.jpg");
    }
}

#about1-col-3 {
    //background-size: cover;
    //background-position-y: 89%;
    //background-position-x: right;
    //
    //background-size: 512px;
    //background-position-y: bottom;
    //background-position-x: -114px;
    //
    //background-size: cover;
    //background-position-y: -44px;
    //background-position-x: -107px;
    //
    background-size: cover;
    background-position-y: top;
    background-position-x: center;


    @include media-breakpoint-down(bfl) {
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
    }
    @include media-breakpoint-down(lg) {
    }


}

#about1-col-3-alt {
    display: none;
    @include media-breakpoint-down(bfl) {
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(xl) {
    }
    @include media-breakpoint-down(lg) {
        display: block;
        height: 271px;
        background-size: 260px;
        background-position-y: -10px;
        background-position-x: center;

        height: 240px;
        background-size: 260px;
        background-position-y: -16px;
        background-position-x: center;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}


#about-us-5 {
    //min-height: 100px;
    //background-color: $green;
    //color: white;
    //display: flex;
    //justify-content: center;
    //align-items: center;
}

#about-us-2.section-row {
    //    min-height: 607px;
    background-color: $navy;

    .section-text {
        flex: 1 1 40%;
        //        margin: 60px;

        //ul {
        //
        //
        //    li {
        //        margin-top: 1.1em;
        //        font-family: 'Source Sans Pro', sans-serif;
        //        font-size: fs(25pt);
        //        line-height: lh(30pt);
        //    }
        //}

    }

    #about-us-4 {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: top;
        background-position-x: center;

    }


}

#about1-col-alt {
    display: none;
}

@include media-breakpoint-between(xs, xxl) {
    #about-us {

    }
    #about1-col-2 {
        //        order: 1;
    }
    #about1-col-1 {
        //        order: 2;
    }
    #about1-col-3 {
        //        display:none;
        //        order: 3;
    }
    #about1-col-alt {
        display: flex;
    }
}


@include media-breakpoint-down(xl) {

    #about1-col-3.section-image {
        //        flex: 1 1 100%;
        //display:none;
    }
    #about1-col-1.section-image {
        //        flex: 1 1 40%;
        //display:none;

    }
    #about1-col-2.section-image {
        flex: 1 1 40%;
        //display:none;
    }
    #about-us {
        flex-wrap: wrap;
    }
    #about1-col-3.section-image {
        display: block;

        //        flex: 1 0 110vw;
        //        width: 100%;
        background-position-y: top;
        //height: 50vw;
        //        display:none;
        //        order: 3;
    }

}


@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {


}

@media (max-width: 1500px) {
    #about1-col-3 {
        //display: none;
    }
}


@include media-breakpoint-down(xl) {
    #about-us-7 {
        flex-wrap: wrap;
        background-color: $navy;
    }
    #about2-col-1 {
        //        flex:0 1 $col-1-w-a;
    }
    #about2-col-1 {
        //        flex:1 1 40%;
    }
    #about-us {

        #about1-col-1 {
            //            @extend col-6;
        }


        .circ-block-panel {
            padding: 10px 0px;
        }

        .circ-block-wrapper {
            .circ-block {
                //      width: 380px;
                //      height: 380px;

                .circ-text {
                    //        font-size: 26pt;
                    //@include media-breakpoint-down(md) {
                    //
                    //    font-size: 24pt;
                    //
                    //}
                }
            }
        }
    }
}


@include media-breakpoint-down(lg) {


    #about2-col-1 {
        //flex: 0 0 350px;
        background-position-x: 23%;

    }

    #about-us-2 {
        flex-wrap: wrap;

    }


    #about-us {
        flex-wrap: wrap;

        #about1-col-1 {
            //            flex: 0 0 350px;
        }

        // flex-direction: column;
        .circ-block-panel {
            //            padding: 25px 8px;
        }

        .circ-block-wrapper {
            .circ-block {
                //width: 300px;
                //height: 300px;

                .circ-text {
                    //                    font-size: 15.8pt;
                    //@include media-breakpoint-down(md) {
                    //
                    //    font-size: 24pt;
                    //
                    //}
                }
            }
        }
    }

}


@include media-breakpoint-down(md) {

    #about2-col-1 {
        //        flex:1 1 100%;
        height: 72vw;
        order: 2;
    }
    #about2-col-2 {
        order: 1;
    }

    #about1-col-3 {
        height: 72vw;
        background-position-y: -70px;
    }

    #about1-col-3 {
        //        display: block;
    }

    #about-us {

        #about1-col-1 {
            //            flex:0 1 100%;
        }

        #about2-col-1 {
            //            height:102vw;
        }

        .circ-block-panel {
            //          padding: 25px 8px;
        }

        .circ-block-wrapper {
            .circ-block {
                //width: 380px;
                //height: 380px;

                .circ-text {
                    //                    font-size: 21.8pt;
                    //                    font-size: 26pt;
                    //@include media-breakpoint-down(md) {
                    //
                    //    font-size: 24pt;
                    //
                    //}
                }
            }
        }
    }
}


#about-us-0 {
    //height: 813px;
    width: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom;
    //        background-position-y: 11.5%;
    background-position-x: center;


}

@include media-breakpoint-down(bfl) {

    .image-row {

    }

    #about-us-0 {
        //        background-size: 1820px;
        //    background-position-y: -20px;
        //        background-position-y: 11.5%;
    }

}

@include media-breakpoint-down(xxl) {
    .image-row {

    }
    #about-us-0 {
    }
}

@include media-breakpoint-down(xl) {
    .image-row {

    }
    #about-us-0 {
    }
}

@include media-breakpoint-down(lg) {
    .image-row {
        //      height: 813px * $scale-lg;
    }
    #about-us-0 {
    }
}

@include media-breakpoint-down(md) {
    .image-row {
        //   height: 813px * $scale-md;
    }
    #about-us-0 {
        background-size: 152%;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: -131px;
    }
}

@include media-breakpoint-down(sm) {
    .image-row {
    }
    #about-us-0 {
        background-size: 190%;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: -200px;
    }
}

//.image-row {
//    height:813px;
//
//    @include media-breakpoint-down(bfl) {
//        height:813px;
//    }
//    @include media-breakpoint-down(xxl) {
//        height:793px;
//    }
//    @include media-breakpoint-down(xl) {
//        height:813px;
//    }
//    @include media-breakpoint-down(lg) {
//        height:813px;
//    }
//    @include media-breakpoint-down(md) {
//        height:813px;
//    }
//    @include media-breakpoint-down(sml) {
//        height:813px;
//    }
//
//}

.bg-1 {
    background-image: linear-gradient(90deg, white 50%, $green 50%);
}

.sq-80 {
    height: 80px;
    width: 80px;
}

.navy-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $navy;
    border-radius: 50%;
}

.principals {
    @extend .flex-fill;
    //display:flex;
    //flex:0 1 400px;
    // margin-right:200px;
}

.principals-panel {
    //padding-right: 0;
    //padding-left: 55px;

}

.principal {
    flex: 0 0 800px;
    margin-bottom: 15px;
    display: flex;
    @extend .flex-fill;
    position: relative;
    min-height: 80px;
    margin-left: -25px;

    @extend .d-flex;
    @extend .flex-fill;
}

//.principal-number-col {
//    flex:0 0 40px;
//    position: relative;
//}
.principal-number {
    @extend .bg-1;
    font-size: 30pt;
    color: white;
    font-family: "Questrial", sans-serif;
    flex: 0 0 80px;
}

.principal-left {
    font-family: "Questrial", sans-serif;
    color: $white;
    background-color: $green;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 1em 0 0.4em;

    @extend .size-4;

    flex: 0 0 293px;
}

.principal-center {
    font-family: "Questrial", sans-serif;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    @extend .size-4;
    font-size: 30pt;

    flex: 0 0 270px;
}

.principal-right {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 16pt;
    color: $navy;
    background-color: $greenish;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 30px;

    @extend .flex-fill;


}

@include media-breakpoint-up(bfl) {
    .principal-left {
    }
    .principal-center {
        font-size: 35pt;
    }
    .principal-right {
        br {
            display: none;
        }
    }
}

@include media-breakpoint-down(xl) {
    .principal-left {
        padding: 26px 1em 0 0.4em;
        flex: 0 0 216px;
    }
    .principal-number {
        font-size: 24pt;
    }
    .principal-right {
        font-size: 14pt;

        br {
            display: none;
        }
    }
    .principals-panel {
        padding-bottom: 15px;
    }
    .principal-center {
        font-size: 27pt;
    }
}

@include media-breakpoint-up(lg) {
    .principals-panel {
        padding-right: 0;
    }
}

@include media-breakpoint-down(lg) {
    .principal-left {
        padding: 24px 1em 0 0.4em;
        //        flex: 0 0 216px;
    }
    .principal-number {
        font-size: 22pt;
    }
    .principal-right {
        br {
            display: none;
        }
    }
    .principals-panel {
        padding-top: 0;
    }

    .principal-center {
        font-size: 25pt;
    }

}


@include media-breakpoint-down(md) {
    .principal {
        flex-wrap: wrap;
        margin-left: 0;
    }
    .principal-left {
        flex: 1 0 auto;
    }
    .principal-number {
        flex: 0 0 80px;
    }
    .principal-center {
        justify-content: flex-start;
        padding-left: 30px;
        flex: 1 0 100%;
        min-height: 75px;

    }
    .principal-center {
        font-size: 30pt;
    }


    .principal-right {
        flex: 1 0 100%;
        //        margin-left: 40px;//
        justify-content: flex-start;
        padding: 1em 1em;
        padding-left: 30px;

        br {
            display: inline;
        }
    }
    .principals-panel {
    }

}

